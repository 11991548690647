import React, {useEffect, setState, useState} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';

const Stepper = ({themeMode, step}) => {
  return (
    <section
      className={css.stepper}
      style={{
        '--color': themeMode === 'dark' ? 'rgba(136, 136, 136, 1)' : 'rgba(221, 221, 221, 1)',
        '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
      }}
    >
      <ul className={css.list}>
        {[
          'Настройки',
          'Домен',
          'Приложение',
          // 'Аналитика'
        ].map((item, index) => {
          return (
            <li className={css.step} key={item}>
              <span
                className={classNames(
                  css.circle,
                  step === index + 1 && css._current,
                  index + 1 < step && css._prev
                )}
              ></span>
              <span className={css.title}>{item}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Stepper;
