/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {formateImage} from '../../../../functions/formatImage';
import Card from '../../../../shared/Card';

const Settings = ({data, updateData, type}) => {
  const store = useStore();
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
  } = useForm();

  const [image, setImage] = useState('');

  useEffect(() => {
    formateImage(data?.avatar || '', setImage);
    setValue('bayer_id', data?.bayer?.id);
    setValue('id', data?.id);
    setValue('departament_id', data?.departament_id);
    setValue('name', data?.name);
    !!data.hasOwnProperty('source_link') && setValue('source_link', data?.source_link);
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === 'avatar') {
        if (!!value.length) {
          formData.append(`${key}`, value[0]);
          continue;
        }
        continue;
      }
      formData.append(`${key}`, value);
    }

    // for (var value of formData.values()) {
    //   console.log(value);
    // }

    const result = await store.trafficsUpdateTraffic(formData);

    !!result && toast.success(`Успешно обновлено!`, {});
    !!result && updateData(result);
    !result &&
      toast.error(`Произошла ошибка в обновлении!`, {
        theme: 'colored',
      });
  };

  return (
    <Card className='card'>
      <div className='card-header'>
        <div className='card-title fs-3 fw-bold'>Настройки</div>
      </div>

      <form id='kt_project_settings_form' className='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='card-body p-9'>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Аватар</div>
            </div>

            <div className='col-lg-8'>
              <div className='image-input image-input-outline' data-kt-image-input='true'>
                <div
                  className='image-input-wrapper w-125px h-125px'
                  style={{
                    backgroundImage: image,
                  }}
                ></div>

                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Change avatar'
                >
                  <i className='ki-duotone ki-pencil fs-7'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>

                  <input
                    type='file'
                    name='avatar'
                    accept='.png, .jpg, .jpeg'
                    {...register('avatar')}
                  />
                  <input type='hidden' name='avatar_remove' />
                </label>

                <span
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  title='Cancel avatar'
                >
                  <i className='ki-duotone ki-cross fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </span>
              </div>

              <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>{type === 'company' ? 'Название' : 'Название блогера'}</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                {...register('name', {required: true})}
              />
              {errors?.name?.type === 'required' && (
                <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
              )}
            </div>
          </div>
            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Ссылка на источник</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  {...register('source_link')}
                />
              </div>
            </div>
          {/*{type !== 'company' && (*/}
          {/*  <div className='row mb-8'>*/}
          {/*    <div className='col-xl-3'>*/}
          {/*      <div className='fs-6 fw-semibold mt-2 mb-3'>Байер</div>*/}
          {/*    </div>*/}

          {/*    <div className='col-xl-9 fv-row'>*/}
          {/*      <input*/}
          {/*        type='text'*/}
          {/*        className='form-control form-control-solid'*/}
          {/*        name='type'*/}
          {/*        defaultValue={`${data?.bayer?.first_name} ${data?.bayer?.last_name}`}*/}
          {/*        disabled*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <input
            type='submit'
            className={`btn fw-bold  btn-primary cursor-pointer `}
            value={'Сохранить'}
          />
        </div>
      </form>
    </Card>
  );
};

export default observer(Settings);
