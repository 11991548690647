/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, createContext, useContext} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import moment from 'moment';
import {Button, ButtonView, ButtonUpdate} from '../../../shared/Buttons';
import InputSearch from '../../../shared/InputSearch';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import {CreateAppModal} from '../../../../_metronic/partials';
import AddAdvertiser from './AddAdvertiser';

export const AdvertisersContext = createContext(null);

const Advertisers = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Рекламодатели', path: 'advertisers/list', isActive: true},
      ]}
      title={'Рекламодатели'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [advertisers, setAdvertisers] = useState([]);

  const [searchAdvertisers, setSearchAdvertisers] = useState([]);
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const result = await store.getAdvertisersList();
    setAdvertisers(result);
    setSearchAdvertisers(result);
    setPreloader(false);
  };

  return (
    <AdvertisersContext.Provider value={{getData}}>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            <InputSearch
              title={'Поиск...'}
              array={advertisers}
              newArray={setSearchAdvertisers}
              searchParameters={['id', 'name']}
            />
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {store.roles.includes(13) || store.roles.includes(32) ? (
                <></>
              ) : (
                <Button
                  action={() => {
                    setShowCreateAppModal(true);
                    setSave(false);
                  }}
                >
                  <>
                    <i className='ki-duotone ki-plus fs-2'></i>Добавить
                  </>
                </Button>
              )}
              <CreateAppModal
                customWidth='mw-550px'
                show={showCreateAppModal}
                handleClose={() => {
                  if (save) {
                    setShowCreateAppModal(false);
                  } else {
                    setWarning(true);
                  }
                }}
                title={'Добавление рекламодателя'}
              >
                <AddAdvertiser
                  show={setShowCreateAppModal}
                  warning={warning}
                  setWarning={setWarning}
                />
              </CreateAppModal>
            </div>
          </div>
        </div>
        <Table advertisers={searchAdvertisers} />
      </Card>
    </AdvertisersContext.Provider>
  );
});

const Table = ({advertisers}) => {
  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Рекламодатель</th>
                <th className='min-w-125px'>Создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {advertisers.map((item, i) => {
                return <Item key={item.id} data={item} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Item = observer(({data}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <Link to={`/advertisers/list/${data.id}`}>
              <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            </Link>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>{moment(data.created_at * 1000).format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link to={`/advertisers/list/${data.id}`}>
            <ButtonView />
          </Link>
          {store.roles.includes(13) || store.roles.includes(32) ? (
            <></>
          ) : (
            <ButtonUpdate
              actions={() => {
                setShowCreateAppModal(true);
                setSave(false);
              }}
            />
          )}
          <CreateAppModal
            customWidth='mw-550px'
            show={showCreateAppModal}
            handleClose={() => {
              if (save) {
                setShowCreateAppModal(false);
              } else {
                setWarning(true);
              }
            }}
            title={'Редактирование рекламодателя'}
          >
            <AddAdvertiser
              show={setShowCreateAppModal}
              warning={warning}
              setWarning={setWarning}
              id={data.id}
              isUpdate
            />
          </CreateAppModal>
        </div>
      </td>
    </tr>
  );
});

export default observer(Advertisers);
