import React from 'react';
import Card from '../../shared/Card';
import moment from 'moment';

const Info = ({data}) => {
  const formateDate = (num) => {
    if (!num) return 'Не определено';
    return moment(num * 1000).format('DD MMM YYYY, HH:mm');
  };
  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto col-lg-7'>
        <Card className='card mb-5 mb-xl-10 h-100'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Данные лида</h3>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Оффер</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.offer?.name ? data?.offer?.name : 'Не определено'}
                </span>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.offer?.id && `#${data?.offer?.id}`}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Менеджер LTC</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.manager?.first_name || !!data?.manager?.last_name || !!data?.manager?.id
                    ? `${data?.manager?.first_name} ${data?.manager?.last_name} #${data?.manager?.id}`
                    : 'Не определено'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Менеджер RD</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.rd_manager?.first_name ||
                  !!data?.rd_manager?.last_name ||
                  !!data?.rd_manager?.id
                    ? `${data?.rd_manager?.first_name} ${data?.rd_manager?.last_name} #${data?.rd_manager?.id}`
                    : 'Не определено'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Telegram ID</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.telegram_id ? data?.telegram_id : 'Не определено'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Бот</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.bot?.name || !!data?.bot?.id
                    ? `${data?.bot?.name} #${data?.bot?.id}`
                    : 'Не определено'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Страна</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.country?.name ? data?.country?.name : 'Не определено'}
                </span>
                <span className='symbol symbol-circle symbol-20px me-4'>
                  {!!data?.country?.flag && (
                    <img className='' src={data?.country?.flag} alt='metronic' />
                  )}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-6 fw-semibold text-muted'>Канал</label>

              <div className='col-6 d-flex align-items-center justify-content-start'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {!!data?.channel?.name || !!data?.channel?.id
                    ? `${data?.channel?.name} #${data?.channel?.id}`
                    : 'Не определено'}
                </span>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className='flex-lg-row-fluid col-lg-5 ms-lg-10 '>
        <Card className='card mb-6 mb-xl-9 h-100'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bold m-0'>Активность лида</h3>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer'>
                  <tbody className='fw-semibold text-gray-600'>
                    <tr>
                      <td className='text-start'>Вступил в VIP</td>
                      <td className='text-end'>{formateDate(data?.subvip_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Квалификация</td>
                      <td className='text-end'>{formateDate(data?.cpa_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Крайний депозит</td>
                      <td className='text-end'>{formateDate(data?.last_dep_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Первый депозит</td>
                      <td className='text-end'>{formateDate(data?.first_dep_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Регистрация</td>
                      <td className='text-end'>{formateDate(data?.reg_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Регистрация Soft</td>
                      <td className='text-end'>{formateDate(data?.soft_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Подписался на канал</td>
                      <td className='text-end'>
                        {formateDate(data?.channel?.sub_channel_created)}
                      </td>
                    </tr>
                    <tr>
                      <td className='text-start'>Запустил бота</td>
                      <td className='text-end'>{formateDate(data?.bot?.start_bot_created)}</td>
                    </tr>
                    <tr>
                      <td className='text-start'>Создан</td>
                      <td className='text-end'>{formateDate(data?.created_at)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Info;
