/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {KTIcon} from '../../_metronic/helpers';
import css from '../../styles/Inputs.module.scss';
import classNames from 'classnames';
import {Button} from './Buttons';
import {toast} from 'react-toastify';

const Input = ({
  placeholder,
  handleChange,
  value = '',
  text = '',
  classText = '',
  name,
  style = {},
}) => {
  const [textSize, setTextSize] = useState({width: 0, height: 0});

  useEffect(() => {
    if (text?.length > 0) {
      const element = document.querySelector(`.${classText}`);

      setTextSize({width: element?.offsetWidth, height: element?.offsetHeight});
    }
  }, [text]);

  return (
    <>
      <label className={css.label}>
        {text?.length > 0 && (
          <div
            className={css.signature}
            style={{width: `${textSize?.width}px`, height: `${textSize?.height}px`}}
          >
            <span className={`${classText}`}>{text}</span>
          </div>
        )}
        <input
          type='text'
          name={name}
          style={style}
          className={classNames(css.baseInput, 'form-control')}
          placeholder={placeholder}
          onChange={handleChange}
          value={value || ''}
        />
      </label>
    </>
  );
};

const InputWithButton = ({
  textBtn = (
    <>
      <KTIcon iconName='copy' className='fs-2' />
    </>
  ),
  action,
  handleChange,
  value,
  placeholder,
  text = '',
  classText,
  name,
}) => {
  return (
    <div htmlFor='' style={{width: '100%', position: 'relative'}}>
      <Input
        handleChange={handleChange}
        value={value}
        placeholder={placeholder}
        text={text}
        classText={classText}
        name={name}
      />
      <Button
        className='btn btn-light-primary'
        style={{
          position: 'absolute',
          top: '1px',
          right: '1px',
          margin: '0 !important',
          height: 'calc(100% - 2px)',
          lineHeight: '100%',
          borderRadius: '0px 5px 5px 0px',
        }}
        action={action}
      >
        {textBtn}
      </Button>
    </div>
  );
};

const Select = ({handleChange, text = '', classText = '', options = [], name, value}) => {
  const [textSize, setTextSize] = useState({width: 0, height: 0});

  useEffect(() => {
    if (text?.length > 0) {
      const element = document.querySelector(`.${classText}`);

      setTextSize({width: element?.offsetWidth, height: element?.offsetHeight});
    }
  }, [text]);

  return (
    <>
      <label className={css.label}>
        {text?.length > 0 && (
          <div
            className={css.signature}
            style={{width: `${textSize?.width}px`, height: `${textSize?.height}px`}}
          >
            <span className={`${classText}`}>{text}</span>
          </div>
        )}
        <select
          name={name}
          className={classNames('form-select form-select-solid', css.customSelect)}
          onChange={handleChange}
          value={value}
        >
          {options.map((option, i) => {
            return <React.Fragment key={`classText-${i}`}>{option}</React.Fragment>;
          })}
        </select>
      </label>
    </>
  );
};

const InputCopy = ({text, style}) => {
  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div
      className='border border-2 rounded-1 fw-bold fs-6 text-gray-800'
      style={{
        height: '42px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '0 63px 0 calc(1.5rem + 1px)',
        ...style,
      }}
    >
      <p
        style={{margin: '0px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
      >
        {text}
      </p>
      <Button
        className=''
        style={{
          borderRadius: '0px 6px 6px 0px',
          position: 'absolute',
          right: '0px',
          top: '-2px',
          height: '42px',
        }}
        color='btn-dark'
        action={() => copyText(text)}
      >
        <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
      </Button>
    </div>
  );
};

export {Input, InputWithButton, Select, InputCopy};
