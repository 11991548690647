/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {CreateAppModal} from '../../../../../_metronic/partials';
import {toast} from 'react-toastify';
import {Button} from '../../../../shared/Buttons';
import Warning from '../../../../modules/Warning';
import {Selector} from '../../../../shared/Selectors';

const AddTeam = ({setWarning, warning, setSave, show, getTeams, updateData, isUpdate, type}) => {
  const store = useStore();
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm();

  const [selectOptions, setSelectOptions] = useState([]);
  const [oldTeamleader, setOldTeamleader] = useState('');
  const [teamleader, setTeamleader] = useState('');

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'teamleader_id' && type === 'change') {
        setTeamleader(value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getOptions = async () => {
    let result;
    if (type === 'LTC') {
      result = await store.salesGetLtcManagersList();
    } else if (type === 'RD') {
      result = await store.getRdManagersList();
    }

    const createOptions = result.map((el) => {
      return {
        value: el.id,
        label: `${el.first_name} ${el.last_name}`,
      };
    });

    if (isUpdate) {
      setOldTeamleader(`${updateData.teamleader.first_name} ${updateData.teamleader.last_name}`);
    }
    setSelectOptions(createOptions);
  };

  const saveTeam = async (data) => {
    if (!isUpdate) {
      const newData = {...data, type: type === 'LTC' ? 'ltc' : 'rd'};
      const result = await store.salesTeamsAddTeam(newData);
      notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
    } else {
      const newData = {...data, type: type === 'LTC' ? 'ltc' : 'rd', id: updateData.id};
      const result = await store.salesTeamsUpdateTeam(newData);
      notify(!!result, !!result ? 'Успешно обновлено!' : 'Произошла ошибка!');
    }
    setSave(true);
    show(false);
  };

  const onSubmit = (data) => {
    setShowCreateAppModal(data);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
      getTeams();
      return;
    }
  };

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Выберите тимлида</label>

        <Controller
          name='teamleader_id'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector
              placeholder={oldTeamleader}
              options={selectOptions}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors?.teamleader_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={isUpdate ? 'Сохранить изменения' : 'Сохранить'}
        />
        <CreateAppModal
          customWidth='mw-400px'
          show={showCreateAppModal}
          handleClose={() => {}}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            text={
              <>
                {!isUpdate && (
                  <>
                    Вы действительно хотите создать {type} команду с тимлидом{' '}
                    <span className='text-primary'>
                      {!!selectOptions.length &&
                        !!teamleader &&
                        selectOptions.find((item) => item.value === teamleader)?.label}
                    </span>
                    ?
                  </>
                )}
                {isUpdate && (
                  <>
                    Вы действительно хотите изменить тимлида на{' '}
                    <span className='text-primary'>
                      {!!selectOptions.length &&
                        !!teamleader &&
                        selectOptions.find((item) => item.value === teamleader)?.label}{' '}
                    </span>
                    в {type} команде
                    <br />
                    <span className='text-primary'>{oldTeamleader}</span>?
                  </>
                )}
              </>
            }
            buttons={[
              {
                action: () => {
                  setShowCreateAppModal(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },

              {
                action: async () => {
                  saveTeam(showCreateAppModal);
                  setShowCreateAppModal(false);
                },

                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

const Save = ({saveTeam, teamleader, isUpdate, oldTeamleader, data}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
        }}
      >
        Сохранить
      </Button>
      <CreateAppModal
        customWidth='mw-400px'
        show={showCreateAppModal}
        handleClose={() => {}}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              {!isUpdate && (
                <>
                  Вы действительно хотите создать LTC команду с тимлидом{' '}
                  <span className='text-primary'>{teamleader}</span>?
                </>
              )}
              {isUpdate && (
                <>
                  Вы действительно хотите изменить тимлида на{' '}
                  <span className='text-primary'>{teamleader}</span> в LTC команде
                  <br />
                  <span className='text-primary'>{oldTeamleader}</span>?
                </>
              )}
            </>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                saveTeam(data);
                setShowCreateAppModal(false);
              },

              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
};

export default observer(AddTeam);
