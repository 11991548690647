/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../store/store';
import {toast} from 'react-toastify';
import {Button} from '../../shared/Buttons';
import Stepper from '../../modules/Stepper';
import Card from '../../shared/Card';
import {SelectorWithSearch} from '../../shared/Selectors';
import {CreateAppModal} from '../../../_metronic/partials';
import Warning from '../../modules/Warning';

const links = [
  {
    link: 'common',
    title: 'Тип трансфера',
    component: (permission, type, setType) => (
      <Type permission={permission} type={type} setType={setType} />
    ),
  },
  {
    link: 'settings',
    title: 'Выбор менеджеров',
    component: (permission, type, setType, lead, manager, setManager) => (
      <Select
        permission={permission}
        type={type}
        lead={lead}
        manager={manager}
        setManager={setManager}
      />
    ),
  },
];

const Appoint = ({lead, getData}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  const [page, setPage] = useState('common');

  const [type, setType] = useState('');
  const [permission, setPermission] = useState('');

  const [manager, setManager] = useState({});

  useEffect(() => {
    if (store.roles.includes(4) || store.roles.includes(8) || store.roles.includes(30)) {
      setType('ltc');
      return setPermission('all');
    }
    if (store.roles.includes(16)) {
      setType('ltc');
      return setPermission('ltc');
    }
    if (store.roles.includes(17)) {
      setType('rd');
      return setPermission('rd');
    }
  }, [store.roles]);

  const saveChanges = async () => {
    if (!manager.value)
      return toast.error(`Измените менеджера`, {
        theme: 'colored',
      });

    let result;

    if (type === 'ltc') {
      const newData = {lead_id: lead?.lead_id, new_manager_id: manager?.value};
      if (!!lead?.manager?.id) newData.old_manager_id = lead?.manager?.id;

      result = await store.leadsTranfersAddLtcTransfer(newData);
    } else {
      const newData = {lead_id: lead?.lead_id, new_rd_manager_id: manager?.value};
      if (!!lead?.rd_manager?.id) newData.old_rd_manager_id = lead?.rd_manager?.id;

      result = await store.leadsTranfersAddRdTransfer(newData);
    }

    if (!!result) {
      toast.success(`Успешно обновлено!`, {});
      getData();
      setPage('common');
      setSave(true);
      setShowCreateAppModal(false);
    } else {
      toast.error(`Произошла ошибка в обновлении!`, {
        theme: 'colored',
      });
    }
  };

  const nextPage = () => {
    switch (page) {
      case 'common':
        setPage('settings');
        break;
    }
  };
  const prevPage = () => {
    switch (page) {
      case 'settings':
        setPage('common');
        break;
    }
  };

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>Назначить / Перенести</>
      </Button>
      <CreateAppModal
        customWidth='mw-900px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Трансфер лида'}
      >
        <Stepper links={links} page={page} />
        <div className='mx-auto mw-600px w-100 pb-10'>
          {links.map((el, i) => {
            return (
              <div className='w-100' key={i}>
                {el.link === page &&
                  el.component(permission, type, setType, lead, manager, setManager)}
              </div>
            );
          })}
          <div className='d-flex flex-stack pt-10'>
            <div className='me-2'>
              {page === 'settings' && (
                <Button
                  action={() => {
                    prevPage();
                  }}
                  color='btn-light-primary'
                >
                  <i className='ki-duotone ki-arrow-left fs-3 me-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  Назад
                </Button>
              )}
            </div>
            <div>
              {page === 'common' && (
                <Button
                  action={(e) => {
                    nextPage();
                  }}
                >
                  Далее
                  <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </Button>
              )}
              {page === 'settings' && (
                <Button
                  action={(e) => {
                    saveChanges();
                  }}
                >
                  Сохранить
                  <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </Button>
              )}
            </div>
          </div>
        </div>

        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={warning}
          handleClose={() => setWarning(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            buttons={[
              {
                action: () => {
                  setWarning(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: () => {
                  setWarning(false);
                  setShowCreateAppModal(false);
                  setPage('common');
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </CreateAppModal>
    </>
  );
};

const Type = observer(({permission, type, setType}) => {
  const store = useStore();

  return (
    <>
      <Title title={'Выберете тип трансфера'} />
      <div className='fv-row mb-15 d-flex' data-kt-buttons='true' data-kt-initialized='1'>
        <label
          className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 me-3 col-6 h-100 ${
            type === 'ltc' && 'active'
          }`}
          onClick={() => {
            if (permission === 'all' || permission === 'ltc') {
              setType('ltc');
            }
          }}
        >
          <span className='d-flex align-items-center'>
            <i className='ki-duotone ki-briefcase fs-3hx me-1'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <span className='ms-4'>
              <span className='fs-3 fw-bold text-gray-900 mb-2 d-block'>LTC</span>

              <span className='fw-semibold fs-4 text-muted'>Менеджера которые имеют роль LTC</span>
            </span>
          </span>
        </label>

        <label
          className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ms-3 col-6 h-100 ${
            type === 'rd' && 'active'
          }`}
          onClick={() => {
            if (permission === 'all' || permission === 'rd') {
              setType('rd');
            }
          }}
        >
          <span className='d-flex align-items-center'>
            <i className='ki-duotone ki-briefcase fs-3hx me-1'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <span className='ms-4'>
              <span className='fs-3 fw-bold text-gray-900 mb-2 d-block'>RD</span>

              <span className='fw-semibold fs-4 text-muted'>Менеджера которые имеют роль RD</span>
            </span>
          </span>
        </label>
        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
      </div>
    </>
  );
});

const Select = observer(({data, type, lead, manager, setManager}) => {
  const store = useStore();
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    if (type === 'ltc') {
      const currentManager = !!lead?.manager?.id
        ? {
            label: `${lead?.manager?.first_name} ${lead?.manager?.last_name} #${lead?.manager?.id}`,
            value: lead?.manager?.id,
          }
        : {label: 'Не определен', value: null};
      getLtcData(currentManager);
    } else {
      const currentManager = !!lead?.rd_manager?.id
        ? {
            label: `${lead?.rd_manager?.first_name} ${lead?.rd_manager?.last_name} #${lead?.rd_manager?.id}`,
            value: lead?.rd_manager?.id,
          }
        : {label: 'Не определен', value: null};
      getRdData(currentManager);
    }
  }, [type]);

  const getLtcData = async (currentManager) => {
    setManager(currentManager);
    const getLtcManagersList = await store.salesGetLtcManagersList();

    const newManagersList = getLtcManagersList.map((el) => {
      return {
        value: el.id,
        label: `${el?.first_name} ${el?.last_name} #${el?.id}`,
      };
    });

    newManagersList.unshift(currentManager);
    setManagers(newManagersList);
  };

  const getRdData = async (currentManager) => {
    setManager(currentManager);
    const getRdManagersList = await store.getRdManagersList();

    const newManagersList = getRdManagersList.map((el) => {
      return {
        value: el.id,
        label: `${el?.first_name} ${el?.last_name} #${el?.id}`,
      };
    });

    newManagersList.unshift(currentManager);
    setManagers(newManagersList);
  };

  return (
    <>
      <Title title={`Назначение менеджера ${type.toUpperCase()}`} />
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Текущий менеджер</label>
        <input
          className='form-control form-control-lg form-control-solid'
          disabled
          defaultValue={managers[0]?.label}
        />
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Выберите менеджера</label>

        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '45px',
            }),
          }}
          options={managers}
          value={manager}
          setValue={setManager}
          actions={() => {}}
        />
      </div>
    </>
  );
});

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      {!!subTitle && <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>}
    </div>
  );
};

export default observer(Appoint);
