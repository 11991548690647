/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Finances from '../../../widgets/Finances';
import Carousel from '../../../shared/Carousel';
import posterImg from '../../../../_metronic/assets/media/17-dark.png';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import {Link} from 'react-router-dom';
import {findKPIRd} from '../../../functions/findKPI';
import {formatNumberToCash, formatNumber, formatNumber2} from '../../../functions/formatNumber';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [salesStats, setSalesStats] = useState({});
  const [rdStats, setRdStats] = useState();
  const [forecast, setForecast] = useState();

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const getSalesStats = await store.getStatsManagerRd(timeConfig);
    const getRdStats = await store.getRdStatsManagerRd(timeConfig);
    const getForecast = await store.getForecastPayoutManagerRd();

    const rdList = await store.salesGetKpiRdList();

    setForecast(getForecast);
    setRdStats({
      ...getRdStats,
      indicator: findKPIRd(
        rdList,
        getRdStats?.count_qualifications,
        getRdStats?.activity,
        getRdStats?.rd,
        'manager_kpi'
      ),
    });
    setSalesStats(getSalesStats);
    setPreloader(false);
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-xl-10 mt-0'>
          <Finances
            background={'rd'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Лиды',
                number: formatNumber(salesStats?.count_leads) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Депозиты',
                number: formatNumber(salesStats?.count_deposits) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Сумма депозитов',
                number: `${formatNumber(salesStats?.amounts_deposits)}$` || 0,
                icon: (
                  <i className='ki-duotone ki-dollar fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.count_qualifications) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-primary'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mb-xl-10 mt-xl-0'>
          <div className='row g-5 g-xl-10'>
            <div className='col-xl-6 mb-xl-10'>
              <Carousel title={'RD'} subTitle={'Tекущие KPI'}>
                <div className={`carousel-item`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span
                        className={`symbol-label bg-light-${rdStats?.indicator?.indicator} w-70`}
                      >
                        <i
                          className={`ki-duotone ki-abstract-24 fs-3x text-${rdStats?.indicator?.indicator}`}
                        >
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {rdStats?.rd}$
                        <span
                          className={`badge badge-light-${rdStats?.indicator?.indicator} fs-5 fw-bold`}
                        >
                          {rdStats?.indicator?.indicator === 'danger' && 'Плохой результат'}
                          {rdStats?.indicator?.indicator === 'warning' && 'Средний результат'}
                          {rdStats?.indicator?.indicator === 'success' && 'Хороший результат'}
                        </span>
                      </h4>
                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Активность: {formatNumber2(rdStats?.activity)}%
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Сумма депозитов: {formatNumberToCash(rdStats?.amounts_deposits)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to={`/sales/rd/kpi`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>

            <div className='col-xl-6 mb-5 mb-xl-10'>
              <Carousel title={'Прогноз'} subTitle={'Прогноз выплаты за текущий период'}>
                <div className={`carousel-item active`}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='w-80px flex-shrink-0 me-2 symbol symbol-70px symbol-circle me-5'>
                      <span className={`symbol-label bg-light-primary w-70`}>
                        <i className={`ki-duotone ki-abstract-24 fs-3x text-primary`}>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>

                    <div className='m-0'>
                      <h4 className='fw-bold text-gray-800 mb-3'>
                        {formatNumberToCash(forecast?.payout)}
                      </h4>

                      <div className='d-flex d-grid gap-5'>
                        <div className='d-flex flex-column flex-shrink-0 me-4'>
                          <span className='d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Сумма депозитов: {formatNumberToCash(forecast?.amounts_deposits)}
                          </span>

                          <span className='d-flex align-items-center text-gray-400 fw-bold fs-7'>
                            <i className='ki-duotone ki-right-square fs-6 text-gray-600 me-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            Активность: {formatNumber2(rdStats?.activity)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link to={`/sales/rd/top/managers`}>
                    <Button color={'btn-primary'}>Подробнее</Button>
                  </Link>
                </div>
              </Carousel>
            </div>
          </div>

          <Card className='card' data-bs-theme='light' style={{backgroundColor: '#1C325E'}}>
            <div className='card-body d-flex ps-xl-15'>
              <div className='m-0'>
                <div className='position-relative fs-2x z-index-2 fw-bold text-white mb-7'>
                  <span className='me-2'>
                    Приведи менеджера и получи{' '}
                    <span className='position-relative d-inline-block text-danger'>
                      <span className='text-danger opacity-75-hover'>100$</span>

                      <span className='position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
                    </span>
                  </span>
                  за каждого.
                  <br /> Условия по кнопке ниже!
                </div>
                <a
                  href='https://t.me/hr_hant'
                  target='_blank'
                  className='btn fw-bold  btn-danger cursor-pointer'
                  rel='noreferrer'
                >
                  Подробнее
                </a>
              </div>

              <img
                src={posterImg}
                className='position-absolute me-3 bottom-0 end-0 h-200px'
                alt=''
              />
            </div>
          </Card>
        </div>
        {/* <div className='col-xl-12 mb-5 mb-xl-10 mt-0'>
          <Chart data={{graph: qualificationSchedule}} />
        </div> */}
      </div>
    </>
  );
});

const ManagerRD = () => {
  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      mode='sales'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Панель', path: '/dashboard/manager/rd', isActive: true},
      ]}
      title={'Панель менеджера RD'}
    >
      <DashboardPage />
    </PageWrapper>
  );
};

export default ManagerRD;
