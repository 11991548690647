/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {useForm, Controller} from 'react-hook-form';
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';
import Card from '../../../shared/Card';
import {formateImage} from '../../../functions/formatImage';
import {Selector} from '../../../shared/Selectors';

const UpdateUser = observer(({updateData, getNewData}) => {
  const store = useStore();
  const {
    register,
    control,
    watch,
    setValue,
    formState: {errors},
    handleSubmit,
    resetField,
  } = useForm();

  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  const [image, setImage] = useState('');

  const [teamsLtc, setTeamsLtc] = useState([]);
  const [teamsRd, setTeamsRd] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [manager, setManger] = useState('');
  const [teamsInfluence, setTeamsInfluence] = useState([]);
  const [bayer, setBayer] = useState('');

  useEffect(() => {
    getOptions();
    if (!updateData) return;
    for (const [key, value] of Object.entries(updateData)) {
      if (!value) continue;
      if (key === 'date_birth') {
        setValue(key, formatDateToDisplay(value));
        continue;
      }
      if (key === 'avatar') {
        formateImage(value, setImage);
      }
      if (key === 'role_id' && !!value) {
        if (value === 9) {
          setManger('ltc');
          getTeamsLtc();
        }
        if (value === 15) {
          setManger('rd');
          getTeamsLtc();
        }
        if (value === 19) {
          setBayer('cost');
          getTeamsLtc();
        }
        getWorkspaces();
      }
      setValue(key, value);
    }
  }, [updateData]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
      if (name === 'phone' && type === 'change') {
        setValue(name, value[name].replace(/\D/g, ''));
      }

      if (name === 'role_id' && type === 'change') {
        if (value[name] === 15 || value[name] === 9 || value[name] === 19) {
          resetField('sales_team_id');
          resetField('workspace_id');
          resetField('influence_team_id');

          value[name] === 15 && setManger('rd');
          value[name] === 15 && getTeamsRd();
          value[name] === 9 && setManger('ltc');
          value[name] === 9 && getTeamsLtc();
          value[name] === 19 && getTeamsInfluence();
          value[name] === 19 && setBayer('cost');
        } else {
          setManger('');
          setBayer('');
          resetField('sales_team_id');
          resetField('workspace_id');
          resetField('influence_team_id');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getOptions = async () => {
    const [getCountries, getDepartments, getRoles] = await Promise.all([
      store.getCountries(),
      store.departamentsGetList(),
      store.usersRolesGetList(),
      getTeamsRd(),
      getTeamsLtc(),
      getTeamsInfluence(),
    ]);

    setCountries(
      getCountries.map((el) => {
        return {label: el.name, value: el.id};
      })
    );
    setDepartments(
      getDepartments.map((el) => {
        return {label: el.name, value: el.id};
      })
    );
    setRoles(
      getRoles.map((el) => {
        return {label: el.name, value: el.id};
      })
    );
  };

  const getTeamsRd = async () => {
    if (!!teamsRd.length) return;

    const result = await store.salesTeamsGetList('rd');
    const optionsTeams = result.map((el) => {
      return {label: `${el.teamleader.first_name} ${el.teamleader.last_name}`, value: el.id};
    });
    setTeamsRd(optionsTeams);
  };

  const getTeamsLtc = async () => {
    if (!!teamsLtc.length) return;

    const result = await store.salesTeamsGetList('ltc');
    const optionsTeams = result.map((el) => {
      return {label: `${el.teamleader.first_name} ${el.teamleader.last_name}`, value: el.id};
    });
    setTeamsLtc(optionsTeams);
  };

  const getTeamsInfluence = async () => {
    if (!!teamsInfluence.length) return;

    const result = await store.usersGetInfluenceTeamLeadList();
    const optionsTeams = result.map((el) => {
      return {label: `${el.first_name} ${el.last_name}`, value: el.id};
    });
    setTeamsInfluence(optionsTeams);
  }

  const getWorkspaces = async () => {
    const worksp = await store.CRMWorkspaces("sales");
    const optionsWorkspaces = worksp.map((el) => {
      return {label: `#${el.id} ${el.name}`, value: el.id};
    });
    setWorkspaces(optionsWorkspaces);
  };

  const formatDateToDisplay = (inputDate) => {
    const parts = inputDate?.split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    return `${day}.${month}.${year}`;
  };

  const formatDateToISO = (inputDate) => {
    const parts = inputDate.split('');
    const day = parts[0] + parts[1];
    const month = parts[2] + parts[3];
    const year = parts[4] + parts[5] + parts[6] + parts[7];

    return `${year}-${month}-${day}`;
  };

  const onSubmit = async (params) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        continue;
      }

      if (key === 'date_birth') {
        formData.append(`${key}`, formatDateToISO(value));
        continue;
      }
      if (key === 'role_name') {
        continue;
      }
      if (key === 'avatar') {
        if (typeof value === 'string') {
          continue;
        } else {
          formData.append(`${key}`, value[0]);
          continue;
        }
      }

      formData.append(`${key}`, value);
    }

    // for (var value of formData.values()) {
    //   console.log(value);
    // }

    const result = await store.usersUpdateUser(formData);
    !!result && toast.success(`Успешно сохранено!`, {});
    !!result && getNewData();
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  return (
    <>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Изменение данных</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='mx-auto mw-600px w-100'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Title
                title={'Общие данные'}
                subTitle={'Вводите данные на кирилице и внимательно!'}
              />
              <div className='fv-row mb-10 d-flex flex-column flex-start'>
                <label className='form-label'>Аватар</label>
                <div className='image-input image-input-outline' data-kt-image-input='true'>
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: image,
                    }}
                  ></div>

                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    aria-label='Change avatar'
                    data-bs-original-title='Change avatar'
                    data-kt-initialized='1'
                  >
                    <i className='ki-duotone ki-pencil fs-7'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>

                    <input type='file' accept='.png, .jpg, .jpeg' {...register('avatar')} />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                </div>
                {/* {errors.avatar?.type && <div className='text-danger mt-2'>Вставте аватарку</div>} */}
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Имя</span>
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Иван'
                    {...register('first_name', {required: true})}
                  />
                  {errors.first_name?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Фамилия</span>
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Иванов'
                    {...register('last_name', {required: true})}
                  />
                  {errors.last_name?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Email</span>
                  </label>

                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='email@example.com'
                    {...register('email', {pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, required: true})}
                  />
                  {errors.email?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                  {errors.email?.type === 'pattern' && (
                    <div className='text-danger mt-2'>Не верный формат email</div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Номер телефона</span>
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='380960000000'
                    {...register('phone', {required: true})}
                  />
                  {errors.phone?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Telegram ID</span>
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='123456789'
                    {...register('telegram_id', {required: true})}
                  />
                  {errors.telegram_id?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Telegram UserName</span>
                  </label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder='username'
                    {...register('telegram_username', {required: true})}
                  />
                  {errors.telegram_username?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Дата рождения</span>
                  </label>
                  <Controller
                    control={control}
                    rules={{required: true}}
                    name='date_birth'
                    render={({field: {value, onChange, ...fieldProps}}) => {
                      return (
                        <IMaskInput
                          // {...fieldProps}
                          onAccept={onChange}
                          value={value}
                          className='form-control form-control-lg form-control-solid'
                          mask={Date}
                          pattern={'d.m.Y'}
                          unmask={true}
                          placeholder='01.02.1999'
                        />
                      );
                    }}
                  />
                  {errors.date_birth?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Страна проживания</span>
                  </label>
                  <Controller
                    name='countries_id'
                    control={control}
                    rules={{required: true}}
                    render={({field: {onChange, value, name, ref}}) => (
                      <Selector
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: '45px',
                          }),
                        }}
                        options={countries}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.countries_id?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Департамент</span>
                  </label>
                  <Controller
                    name='departament_id'
                    control={control}
                    rules={{required: true}}
                    render={({field: {onChange, value, name, ref}}) => (
                      <Selector
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: '45px',
                          }),
                        }}
                        options={departments}
                        value={value}
                        onChange={onChange}
                        placeholder={'Выберете департамент'}
                      />
                    )}
                  />
                  {errors.departament_id?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <label className='form-label'>
                    <span className='required'>Роль</span>
                  </label>
                  <Controller
                    name='role_id'
                    control={control}
                    rules={{required: true}}
                    render={({field: {onChange, value, name, ref}}) => (
                      <Selector
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: '45px',
                          }),
                        }}
                        options={roles}
                        value={value}
                        onChange={onChange}
                        placeholder={'Выберете департамент'}
                      />
                    )}
                  />
                  {errors.role_id?.type === 'required' && (
                    <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
                  )}
                </div>
              </div>
              {bayer === 'cost' && (
                <div className='row'>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                    <label className='form-label'>
                      <span>Команда</span>
                    </label>
                    <Controller
                      name='influence_team_id'
                      control={control}
                      render={({field: {onChange, value, name, ref}}) => (
                        <Selector
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '45px',
                            }),
                          }}
                          options={teamsInfluence}
                          value={value}
                          onChange={onChange}
                          placeholder={'Выберете команду'}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {manager === 'ltc' && (
                <div className='row'>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                    <label className='form-label'>
                      <span>Команда</span>
                    </label>
                    <Controller
                      name='sales_team_id'
                      control={control}
                      render={({field: {onChange, value, name, ref}}) => (
                        <Selector
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '45px',
                            }),
                          }}
                          options={teamsLtc}
                          value={value}
                          onChange={onChange}
                          placeholder={'Выберете команду'}
                        />
                      )}
                    />
                  </div>

                  <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                    <label className='form-label'>
                      <span>Workspace</span>
                    </label>
                    <Controller
                      name='workspace_id'
                      control={control}
                      render={({field: {onChange, value, name, ref}}) => (
                        <Selector
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '45px',
                            }),
                          }}
                          options={workspaces}
                          value={value}
                          onChange={onChange}
                          placeholder={'Выберете workspace'}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {manager === 'rd' && (
                <div className='row'>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                    <label className='form-label'>
                      <span>Команда</span>
                    </label>
                    <Controller
                      name='sales_team_id'
                      control={control}
                      render={({field: {onChange, value, name, ref}}) => (
                        <Selector
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '45px',
                            }),
                          }}
                          options={teamsRd}
                          value={value}
                          onChange={onChange}
                          placeholder={'Выберете команду'}
                        />
                      )}
                    />
                  </div>

                  <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                    <label className='form-label'>
                      <span>Workspace</span>
                    </label>
                    <Controller
                      name='workspace_id'
                      control={control}
                      render={({field: {onChange, value, name, ref}}) => (
                        <Selector
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: '45px',
                            }),
                          }}
                          options={workspaces}
                          value={value}
                          onChange={onChange}
                          placeholder={'Выберете workspace'}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className='d-flex flex-stack justify-content-end pt-10'>
                <div>
                  <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
                    Сохранить изменения
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </>
  );
});

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      {!!subTitle && <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>}
    </div>
  );
};

export default UpdateUser;
