/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import Calendar from './calendar/Calendar';

const momentTz = require('moment-timezone');

const buttons = [
  {title: 'Неделя продаж', value: 'week_salles'},
  {title: 'Сегодня', value: 'today'},
  {title: 'Вчера', value: 'yesterday'},
  {title: 'За последние 7 дней', value: '7day'},
  {title: 'За последние 30 дней', value: '30day'},
  {title: 'Текущий месяц', value: 'current_month'},
  {title: 'Предыдущий месяц', value: 'last_month'},
  {title: 'Текущий год', value: 'year'},
  {title: 'За все время', value: 'all_time'},
];

const PageWrapper = ({
  children,
  mode = '',
  firstTimeConfig = '',
  breadcrumbs = [],
  title = '',
  button = null,
  buttonKPI = null,
  allowAllTimes = false,
}) => {
  const store = useStore();
  const location = useLocation();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [label, setLabel] = useState('');
  const [arrButtons, setArrButtons] = useState([]);

  const [timeConfig, setTimeConfig] = useState({time_config: '', time_start: '', time_end: ''});
  const [calendarTime, setCalendarTime] = useState({
    time_config: '',
    time_start: '',
    time_end: '',
  });
  const [isTimeConfig, setIsTimeConfig] = useState(false);

  const [isVerify, setIsVerify] = useState(false);

  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (firstTimeConfig === 'week_salles') {
      const newButtons = [...buttons];
      newButtons.pop();
      return setArrButtons(newButtons);
    } else if (firstTimeConfig === 'week_sales_periods') {
      return setArrButtons(getWeekSallesPeriods());
    } else if (!allowAllTimes) {
      const newButtons = [...buttons];
      newButtons.pop();
      return setArrButtons(newButtons);
    } else {
      const newButtons = [...buttons];
      newButtons.splice(0, 1);
      return setArrButtons(newButtons);
    }
  }, [firstTimeConfig]);

  useEffect(() => {
    if (!arrButtons.length) return;
    if (!firstTimeConfig) return setIsTimeConfig(true);
    const currentLabel = arrButtons.find((item) => item.value === firstTimeConfig);
    setLabel(currentLabel?.title);
    if(firstTimeConfig === 'all') {
      setTimeConfig({time_config: 'custom', time_start: '0', time_end: Math.floor(new Date().setUTCHours(23, 59, 59) / 1000).toString()});
    } else {
      setTimeConfig({time_config: currentLabel.value, time_start: '', time_end: ''});
    }
    setIsTimeConfig(true);
  }, [arrButtons]);

  const newTimeConfig = (el) => {
      setTimeConfig({
        time_config: el.value,
        time_start: !!el.time_start ? el.time_start : '',
        time_end: !!el.time_end ? el.time_end : '',
      });

    setLabel(el?.title);
  };

  const handleCustom = (obj, forCalendar) => {
    const {time_start, time_end} = obj;

    let start = moment.utc(time_start).format('DD.MM.YYYY');
    let end = !!time_end ? moment.utc(time_end).format('DD.MM.YYYY') : '';

    if (start !== end) {
      start = moment.utc(time_start).format('DD.MM.YYYY');
      setLabel(`${start} - ${end}`);
    } else {
      setLabel(start);
    }

    setCalendarTime({
      time_start: forCalendar.startDate,
      time_end: forCalendar.endDate,
      sendStart: obj.time_start,
      sendEnd: obj.time_end,
    });

    setTimeConfig({
      time_config: 'custom',
      time_start: Math.floor(obj.time_start / 1000) || '',
      time_end: Math.floor(obj.time_end / 1000),
    });
  };

  useEffect(() => {
    if (!isTimeConfig) return;

    store.setCurrentLocation(location.pathname);
  }, [isTimeConfig]);

  useEffect(() => {
    if (!store.isVerify) return;
    setIsVerify(store.isVerify);
    store.setIsVerify(false);
  }, [store.isVerify]);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      timeConfig,
      isVerify,
      setPreloader,
    })
  );

  return (
    <>
      <div className='app-toolbar py-3 pb-lg-6'>
        <div
          className={`d-flex flex-colum container-xxl d-flex container-fluid ${
            button
              ? 'justify-content-between flex-column flex-sm-row align-content-xs--start flex-wrap flex-sm-nowrap'
              : 'flex-stack'
          }`}
        >
          {/* flex-column align-items-start flex-sm-row */}
          <div className='page-title d-flex flex-column justify-content-start align-items-start flex-wrap me-3 mb-3 mb-sm-0'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              {title}
            </h1>
            <ul className='breadcrumb breadcrumb-separatorless justify-content-start fw-semibold fs-7 my-0'>
              {breadcrumbs.map((item, index) => {
                return (
                  <React.Fragment key={`${item.link}-${index}`}>
                    {item.path && index !== breadcrumbs.length - 1 && (
                      <li className='breadcrumb-item text-muted'>
                        <Link to={item.path}>
                          <span className='text-muted text-hover-primary cursor-pointer'>
                            {item.title}
                          </span>
                        </Link>
                      </li>
                    )}
                    {!item.path && index !== breadcrumbs.length - 1 && (
                      <li className='breadcrumb-item text-muted'>
                        <span className='text-muted text-hover-primary cursor-pointer'>
                          {item.title}
                        </span>
                      </li>
                    )}
                    {item.isSeparator && (
                      <li className='breadcrumb-item'>
                        <span className='bullet bg-gray-400 w-5px h-2px' />
                      </li>
                    )}
                    {item.path && index === breadcrumbs.length - 1 && (
                      <li className='breadcrumb-item text-dark'>
                        <span className='text-muted'>{item.title}</span>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
          <div className={'d-flex'}>
            {!!button && <div>{button}</div>}
            {!!buttonKPI && buttonKPI}
            {firstTimeConfig && (
              <div className=''>
                <div
                  data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement={'bottom-end'}
                  className='btn btn-sm bg-body shadow-xs d-flex align-items-center px-4'
                >
                  <div className='text-gray-600 fw-bold text-nowrap'>
                    {label || 'Loading date range...'}
                  </div>
                  <i className='ki-duotone ki-calendar-8 fs-1 ms-2 me-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                  </i>
                </div>
                <div
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
                  data-kt-menu='true'
                >
                  {arrButtons.map((el, i) => {
                    return (
                      <div
                        className='menu-item px-3'
                        onClick={() => {
                          newTimeConfig(el);
                        }}
                        key={`${el.value}-${i}`}
                      >
                        <span className='menu-link px-3' data-kt-users-table-filter='delete_row'>
                          {el.title}
                        </span>
                      </div>
                    );
                  })}
                  {firstTimeConfig !== 'week_sales_periods' && (
                    <div
                      className='menu-item px-3'
                      onClick={() => {
                        setShowCreateAppModal(!showCreateAppModal);
                      }}
                    >
                      <span className='menu-link px-3' data-kt-users-table-filter='delete_row'>
                        Выбрать период
                      </span>
                    </div>
                  )}
                </div>

                {showCreateAppModal && (
                  <Calendar
                    showCreateAppModal={showCreateAppModal}
                    setShowCreateAppModal={setShowCreateAppModal}
                    handleCustom={handleCustom}
                    timeConfig={calendarTime}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: !!store.preloaderQ.length || preloader ? '80vh' : '100%',
          overflow: !!store.preloaderQ.length || preloader ? 'hidden' : 'visible',
        }}
      >
        {childrenWithProps}
        {(!!store.preloaderQ.length || preloader) && (
          <div
            className={'card'}
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: '20',
            }}
          >
            <div
              className='app-page-loader'
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.625rem',
                zIndex: '10',
              }}
            >
              <span className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const getWeekSallesPeriods = () => {
  const weeks = [{title: 'Текущий период', value: 'week_sales_periods'}];
  const oneDay = 86400000;
  const today = new Date();
  const todayStart = today.setUTCHours(0, 0, 0);
  const weekday = today.getUTCDay();

  let currentStartDate = 0; //поточна субота (субота яка була або зараз)
  let currentEndDate = 0; //поточна пятниця (пятниця яка буде або зараз)

  for (let i = 0; i < 7; i++) {
    if (weekday === 6 && i === 6) {
      currentStartDate = todayStart;
      continue;
    }
    if (weekday === 0 && i === 0) {
      currentStartDate = todayStart - oneDay * (i + 1);
      continue;
    }
    if (weekday === i && i !== 0 && i !== 6) {
      currentStartDate = todayStart - oneDay * (i + 1);
    }
  }

  currentEndDate = currentStartDate + oneDay * 6;

  for (let i = 1; i <= 4; i++) {
    const millisecondsStart = currentStartDate - oneDay * 7 * i;
    const millisecondsEnd = currentEndDate - oneDay * 7 * i;
    const kyivTimeStart = moment.tz(millisecondsStart, 'Europe/Kyiv').format('DD.MM');
    const kyivTimeEnd = moment.tz(millisecondsEnd, 'Europe/Kyiv').format('DD.MM');

    weeks.push({
      title: `${kyivTimeStart}-${kyivTimeEnd}`,
      value: 'past_sales',
      time_start: Math.floor(millisecondsStart / 1000),
      time_end: Math.floor(new Date(millisecondsEnd).setUTCHours(23, 59, 59) / 1000),
    });
  }

  return weeks;
};

export default observer(PageWrapper);
