import {api} from './api';

const devAPI = {
  async getServers(method, token, status, server_id, ip) {
    try {
      let response;
      const strStatus = !!status ? `&status=${status}` : '';

      if (method === 'getServers') {
        response = await api.get(`Resource/${method}?token=${token}${strStatus}`);
      } else {
        const strServer_id = !!server_id ? `&server_id=${server_id}` : '';
        const strIp = !!ip ? `&ip=${ip}` : '';
        response = await api.get(
          `Resource/${method}?token=${token}${strStatus}${strServer_id}${strIp}`
        );
      }

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async addServer(method, data) {
    try {
      const response = await api.post(`Resource/${method}?`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async updateServer(method, data) {
    try {
      const response = await api.post(`Resource/${method}?`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourceGetDomain(token, id) {
    try {
      const response = await api.get(`Resource/getDomain/?token=${token}&term=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourceGetDomainsList(token) {
    try {
      const response = await api.get(`Resource/getDomainsList/?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async resourceGetServer(token, id) {
    try {
      const response = await api.get(`Resource/getServer/?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default devAPI;
