import React, {useEffect, useState} from 'react';
import {useThemeMode} from '../../../../../../_metronic/partials';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../../../../styles/BADManagers.module.scss';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';

const Footer = ({data, length}) => {
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const store = useStore();

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <div>
      <div className='card-body pt-9 pb-0'>
        <div className={css.container}>
          {data.map((item, index) => {
            return (
              <div
                className={classNames(css.cardContainer)}
                style={{
                  backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
                  border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
                }}
                key={`${item.id}`}
              >
                <div className={css.number}>{length + index}st</div>
                <div
                  className={css.image}
                  style={{
                    backgroundImage: !!item?.avatar
                      ? `url(${process.env.REACT_APP_API_HOST}/${item?.avatar})`
                      : `url(${imagePlug})`,
                  }}
                >
                  <span className={css.crown} />
                </div>
                <span className={classNames(css.id, 'fw-semibold text-gray-400 fs-4')}>
                  #{item?.id}
                </span>
                <Link to={store.roles.includes(9) || store.roles.includes(33) ? '' : `/sales/ltc/managers/${item?.id}`}>
                  <span className={classNames(css.name, 'text-gray-800 fs-2 fw-bold')}>
                    {item?.first_name} {item?.last_name}
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default observer(Footer);
