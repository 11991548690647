/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {useLocation, useParams} from 'react-router-dom';
import TeamHeader from './TeamHeader';
import Employees from './Employees';
import PageWrapper from '../../../../modules/PageWrapper';
import {findKPILtc} from '../../../../functions/findKPI';
import Traffic from './Traffic';
import Activity from './Activity';
import Stages from "./Stages";

const Component = observer(({timeConfig, isVerify, setPreloader, page, setPage}) => {
  const {id} = useParams();
  const store = useStore();

  const [mainStats, setMainStats] = useState({});
  const [managersStats, setManagerStats] = useState([]);

  const [usersCount, setUsersCount] = useState('');

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const [getMainStats, ltcList, LTCList, getManagersStats] = await Promise.all([
      store.salesTeamsLtcMainStats(tc, id),
      store.financeCategoryGetLtcList(),
      store.salesGetKpiLtcList(),
      store.salesTeamsLtcGetManagersStats(tc, id),
    ]);

    const ltc = +getMainStats?.statistics?.ltc;
    const indicator = ltcList.find((el) => +ltc >= +el.ltc_min && +ltc < +el.ltc_max);

    const managersStats = getManagersStats.managers.map((item) => {
      return {...item, indicator: findKPILtc(LTCList, item?.count_appeal, item?.ltc)};
    });

    setMainStats({...getMainStats, indicator});
    setManagerStats(managersStats);
    setUsersCount(getManagersStats.users_count);
    setPreloader(false);
  };

  return (
    <>
      <TeamHeader page={page} setPage={setPage} data={mainStats} getData={getData} />
      {page === 'employees' && <Employees data={managersStats} usersCount={usersCount} />}
      {page === 'traffic' && <Traffic id={id} />}
      {page === 'activity' && <Activity id={id} type={'ltc'} />}
      {page === 'stages' && <Stages id={id}/>}
    </>
  );
});

const Team = ({timeConfig, timeStart, timeEnd}) => {
  const [page, setPage] = useState('employees');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    switch (page) {
      case 'employees': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {
              title: `Команда LTC #${params.id}`,
              path: `/sales/ltc/teams/${params.id}`,
              isActive: true,
            },
          ],
          title: `Команда LTC #${params.id}`,
        });
        break;
      }
    }
  }, [page]);

  return (
    <>
      <PageWrapper
        breadcrumbs={breadcrumbs.br}
        title={breadcrumbs.title}
        firstTimeConfig='week_salles'
      >
        <Component
          page={page}
          setPage={setPage}
          timeConfig={timeConfig}
          timeStart={timeStart}
          timeEnd={timeEnd}
        />
      </PageWrapper>
    </>
  );
};

export default observer(Team);
