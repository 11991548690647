/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import { formatNumberToCash } from '../../../functions/formatNumber';

const KpiLtcWrapper = () => {
  const [buttons, setButtons] = useState([{title: 'Менеджер', name: 'manager_kpi'}]);
  const [active, setActive] = useState('manager_kpi');
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'KPI LTC', path: '/sales/kpi_ltc', isActive: true},
      ]}
      title={'KPI LTC'}
      buttonKPI={
        <div>
          <button
            type='button'
            className='btn fw-bold btn-light btn-color-muted btn-active-light-primary text-nowrap cursor-pointer'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            {buttons.find((item) => item.name === active)?.title}{' '}
            <i className='ki-duotone ki-down fs-5 ms-1'></i>
          </button>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            {buttons.map((item, index) => {
              return (
                <div className='menu-item px-3' key={`${item.name}-${index}`}>
                  <span
                    className='menu-link px-3'
                    onClick={() => {
                      setActive(item.name);
                    }}
                  >
                    {item.title}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      }
    >
      <KpiLtc setButtons={setButtons} active={active} />
    </PageWrapper>
  );
};

const KpiLtc = observer(({isVerify, setPreloader, setButtons, active}) => {
  const store = useStore();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const getKpiLtcList = await store.salesGetKpiLtcList();

      const buttons = [];

      let headofsales = null;
      let manager = null;
      let teamleader = null;

      for (let i = 0; i < getKpiLtcList.length; i++) {
        if (!!headofsales && !!manager && !!teamleader) break;

        if (!!getKpiLtcList[i].headofsales_kpi.length && !headofsales) {
          headofsales = {title: 'Head of Sales', name: 'headofsales_kpi'};
        }
        if (!!getKpiLtcList[i].manager_kpi.length && !manager) {
          manager = {title: 'Менеджер', name: 'manager_kpi'};
        }
        if (!!getKpiLtcList[i].teamlead_kpi.length && !teamleader) {
          teamleader = {title: 'Тимлид', name: 'teamlead_kpi'};
        }
      }

      setButtons([manager, teamleader, headofsales].filter((el) => el !== null));
      setData(getKpiLtcList);
      setPreloader(false);
    })();
  }, [isVerify]);


  return (
    <>
      <div className='row g-5 g-xl-8'>
        {data.map((el) => {
          return (
            <React.Fragment key={el.id}>
              {el[active].length ? (
                <div className='col-xxl-4 col-xl-6'>
                  <Card className='card card-xl-stretch mb-5 mb-xl-8'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold text-gray-800'>KPI LTC #{el.id}</span>
                        <span className='text-gray-400 pt-2 fw-semibold fs-6'>
                          {el.count_lead_min}-{el.count_lead_max} обращений
                        </span>
                      </h3>
                    </div>

                    <div className='card-body'>
                      {!!el[active] &&
                        !!el[active].length &&
                        el[active]
                          .sort((a, b) => {
                            const indicatorOrder = {success: 1, warning: 2, danger: 3};
                            return indicatorOrder[a.indicator] - indicatorOrder[b.indicator];
                          })
                          .sort((a, b) => b.LTC - a.LTC)
                          .map((item, i) => {
                            return (
                              <div key={`${el.id}-${i}`}>
                                {item.indicator === 'success' && (
                                  <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
                                    <i className='ki-duotone ki-chart-line-up-2 text-success fs-1 me-5'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>

                                    <div className='flex-grow-1 me-2'>
                                      <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                                        LTC {item.LTC}%+
                                      </span>
                                    </div>

                                    <span className='fw-bold text-success py-1'>
                                      {formatNumberToCash(item.pay)}
                                    </span>
                                  </div>
                                )}
                                {item.indicator === 'warning' && (
                                  <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
                                    <i className='ki-duotone ki-graph-4 text-warning fs-1 me-5'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>

                                    <div className='flex-grow-1 me-2'>
                                      <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                                        LTC {item.LTC}%+
                                      </span>
                                    </div>

                                    <span className='fw-bold text-warning py-1'>
                                      {formatNumberToCash(item.pay)}
                                    </span>
                                  </div>
                                )}
                                {item.indicator === 'danger' && (
                                  <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
                                    <i className='ki-duotone ki-chart-line-up text-danger fs-1 me-5'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>

                                    <div className='flex-grow-1 me-2'>
                                      <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                                        LTC {item.LTC}%+
                                      </span>
                                    </div>

                                    <span className='fw-bold text-danger py-1'>
                                      {formatNumberToCash(item.pay)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </Card>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
});

export default KpiLtcWrapper;
