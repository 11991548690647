import React from 'react';
import css from '../../../../styles/PwaConstructor.module.scss';

const BlockTitles = ({title = '', description = ''}) => {
  return (
    <div className={css.blockTitles}>
      <span className={css.title}>{title}</span>
      {!!description && <p className={css.text}>{description}</p>}
    </div>
  );
};

export default BlockTitles;
