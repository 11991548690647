import React, {useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import Navigation from '../../../../modules/NavigationBloggers';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import Card from '../../../../shared/Card';
import {CreateAppModal} from '../../../../../_metronic/partials';
import AddTeam from '../addTeam/AddTeam';
import {Link} from 'react-router-dom';
import {Button} from '../../../../shared/Buttons';

const TeamHeader = observer(
  ({
    setPage,
    page,
    isManager = false,
    links = [
      {link: 'employees', title: 'Сотрудники'},
      {link: 'traffic', title: 'Трафик'},
      {link: 'activity', title: 'Активность'},
      {link: 'stages', title: 'Этапы'},
    ],
    data,
    getData,
  }) => {
    const store = useStore();
    return (
      <>
        <Card className='card mb-8'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  {!isManager && (
                    <img
                      src={
                        !!data?.teamleader?.avatar
                          ? `${process.env.REACT_APP_API_HOST}/${data?.teamleader?.avatar}`
                          : imagePlug
                      }
                      alt=''
                    />
                  )}
                  {!!isManager && (
                    <img
                      src={
                        !!data?.avatar
                          ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}`
                          : imagePlug
                      }
                      alt=''
                    />
                  )}
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                        {!isManager && (
                          <>
                            {data?.teamleader?.first_name} {data?.teamleader?.last_name}
                          </>
                        )}
                        {!!isManager && (
                          <>
                            {data?.first_name} {data?.last_name}
                          </>
                        )}
                      </span>
                      <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                        {data?.statistics?.ltc}%
                      </span>
                    </div>

                    <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                      <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                        #{data?.id}
                      </span>
                      {isManager && (
                        <Link
                          to={
                            store.roles.includes(16)
                              ? ''
                              : `/sales/ltc/teams/${data?.sales_team_id}`
                          }
                        >
                          <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 cursor-pointer'>
                            <i className='ki-duotone ki-profile-circle fs-4 me-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>{' '}
                            Команда #{data?.sales_team_id}
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className='d-flex mb-4'>
                    <div className='me-0'>
                      {!isManager && (
                        <>
                          {store.roles.includes(8) ||
                          store.roles.includes(13) ||
                          store.roles.includes(30) ||
                          store.roles.includes(32) ? (
                            <></>
                          ) : (
                            <>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                              >
                                <i className='ki-solid ki-dots-horizontal fs-2x'></i>
                              </button>

                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                                data-kt-menu='true'
                              >
                                <UpdateTeam updateData={data} getData={getData} />
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {!!isManager && !store.roles.includes(16) && (
                        <Link to={`/users/list/user/${data.id}/info`}>
                          <Button>Карточка сотрудника</Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'>
                          {moment(new Date(data?.created_at * 1000)).format('ll')}
                        </div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                    </div>
                    <Stats
                      title={'Обращения'}
                      count={data?.statistics?.count_appeal}
                      arrow={true}
                    />
                    <Stats
                      title={'Регистрации'}
                      count={data?.statistics?.count_registrations}
                      arrow={false}
                    />
                    {/* <Stats title={'Депозиты'} count={0} arrow={true} /> */}
                    <Stats
                      title={'Квалификации'}
                      count={data?.statistics?.count_qualifications}
                      arrow={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='separator'></div>
            <Navigation page={page} setPage={setPage} links={links} />
          </div>
        </Card>
      </>
    );
  }
);

const Stats = ({title, count, arrow}) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
      <div className='d-flex align-items-center'>
        {!!arrow && (
          <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        {!arrow && (
          <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <div className='fs-4 fw-bold'>{count}</div>
      </div>

      <div className='fw-semibold fs-6 text-gray-400'>{title}</div>
    </div>
  );
};

const UpdateTeam = ({getTeams, updateData, getData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <div
        className='menu-item px-3'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase cursor-pointer'>
          Изменить
        </div>
      </div>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Изменить LTC команду'}
      >
        <AddTeam
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getTeams={getData}
          isUpdate={true}
          updateData={updateData}
          refresh={false}
          type={'LTC'}
        />
      </CreateAppModal>
    </>
  );
};

export default TeamHeader;
