import React from 'react';
import GoogleplayApp from './templates/googleplay-app/googleplay-app';
import GoogleplaySite from './templates/googleplay_site/googleplay-site';

const Previews = ({data, currentLang, typeApp}) => {
  return (
    <>
      {typeApp === 'Google-Play-Store' && <GoogleplayApp data={data} currentLang={currentLang} />}
      {typeApp === 'Google-Play-Site' && <GoogleplaySite data={data} currentLang={currentLang} />}
    </>
  );
};

export default Previews;
