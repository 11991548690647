/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import {Link} from 'react-router-dom';
import {Button} from '../../../shared/Buttons';
import Card from '../../../shared/Card';
import AddTeam from '../../sales/team/addTeam/AddTeam';
import {CreateAppModal} from '../../../../_metronic/partials';
import {formatNumber, formatNumber2} from '../../../functions/formatNumber';

const SalesTeam = ({title, subTitle, headers, arrayList, teams, getTeams}) => {
  const store = useStore();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (!teams.teams) return;

    const newTeams = teams.teams.map((el) => {
      let rd = +el.statistics.rd;
      let status = arrayList.find((item) => rd >= +item.rd_min && rd <= +item.rd_max);

      return {...el, status: !status ?  "success" : status.indicator};
    });

    setList(newTeams);
  }, [teams]);

  return (
    <Card className='card mb-0'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted mt-1 fw-semibold fs-7'>
            {subTitle}
            {teams?.users_count || 0}
          </span>
        </h3>
        {store.roles.includes(8) ||
        store.roles.includes(13) ||
        store.roles.includes(30) ||
        store.roles.includes(32) ? (
          <></>
        ) : (
          <div className='card-toolbar'>
            <NewTeam getTeams={getTeams} />
          </div>
        )}
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px rounded-start'>{headers[0]}</th>
                <th className='min-w-150px'>{headers[1]}</th>
                <th className='min-w-150px'>{headers[2]}</th>
                {/* <th className='min-w-150px'>{headers[3]}</th> */}
                <th className='min-w-150px'>{headers[3]}</th>
                <th className='min-w-150px'>{headers[4]}</th>
                <th className='min-w-100px text-end rounded-end'></th>
              </tr>
            </thead>

            <tbody>
              {list.map((el) => {
                return (
                  <tr key={el.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img
                            src={
                              el?.teamleader?.avatar
                                ? `${process.env.REACT_APP_API_HOST}/${el?.teamleader?.avatar}`
                                : imagePlug
                            }
                            alt=''
                          />
                        </div>

                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                            {el.teamleader.first_name} {el.teamleader.last_name}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            #{el.id}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {el.statistics.count_users}
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatNumber(el.statistics.count_leads)}
                      </span>
                    </td>

                    <td>
                      <span className={`badge badge-light-${el.status} fs-7 fw-bold`}>
                        {el?.statistics?.rd}$
                      </span>
                    </td>

                    <td>
                      <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatNumber2(el?.statistics?.activity)}%
                      </span>
                    </td>

                    <td className='text-end'>
                      <Link to={`/sales/rd/teams/${el.id}`}>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <i className='ki-duotone ki-switch fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </span>
                      </Link>
                      {store.roles.includes(8) ||
                      store.roles.includes(13) ||
                      store.roles.includes(30) ||
                      store.roles.includes(32) ? (
                        <></>
                      ) : (
                        <UpdateTeam getTeams={getTeams} updateData={el} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

const NewTeam = ({getTeams}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        color={'btn-light-primary'}
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-plus fs-2'></i>Новая команда
      </Button>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Создать RD команду'}
      >
        <AddTeam
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getTeams={getTeams}
          isUpdate={false}
          type={'RD'}
        />
      </CreateAppModal>
    </>
  );
};

const UpdateTeam = ({getTeams, updateData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <i className='ki-duotone ki-pencil fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>

      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Изменить RD команду'}
      >
        <AddTeam
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getTeams={getTeams}
          isUpdate={true}
          updateData={updateData}
          type={'RD'}
        />
      </CreateAppModal>
    </>
  );
};

export default observer(SalesTeam);
