/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import classNames from 'classnames';
import AddCard from './billingFunc/AddCard';
import AddCrypto from './billingFunc/AddCrypto';
import {Button} from '../../../shared/Buttons';
import Warning from '../../../modules/Warning';
import {CreateAppModal} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';
import moment from 'moment';
import Card from '../../../shared/Card';

const Billing = ({isUser, getWallets, deleteWallet, getTransactions}) => {
  const store = useStore();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const [arrLength, setArrLength] = useState(0);

  const [cards, setCards] = useState([]);
  const [cryptos, setCryptos] = useState([]);

  const [editCard, setEditCard] = useState(null);
  const [editCrypto, setEditCrypto] = useState(null);

  const [transactionsList, setTransactionsList] = useState([]);

  const [tab, setTab] = useState('card');

  useEffect(() => {
    (async () => {
      const [wallets, transactions] =
        await Promise.all([
          getWallets(isUser),
          getTransactions(isUser),
        ]);

      setTransactionsList(transactions);
      addData(wallets);
      return;
    })();
  }, []);

  const addData = (wallets) => {
    setArrLength(wallets?.length);

    const newCards = [];
    const newCryptos = [];

    wallets.forEach((el) => {
      el.type === 'bank' ? newCards.push(el) : newCryptos.push(el);
    });

    setCards([...newCards]);
    setCryptos([...newCryptos]);
  };

  const removeWallet = async (id) => {
    const result = await deleteWallet(id, isUser);
    notify(!!result, !!result ? 'Успешно удалено!' : 'Произошла ошибка!');
    return addData(result);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  const formatNumber = (string) => {
    const arr = string.split(' ');
    arr.splice(0, 1, '****');
    const arr2 = arr[1].split('');
    arr2.splice(0, 2, '*', '*');
    arr[1] = arr2.join('');
    arr.splice(3, 1, '****');

    return arr.join(' ');
  };

  return (
    <>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-header card-header-stretch pb-0'>
          <div className='card-title'>
            <h3 className='m-0'>Платежные методы</h3>
          </div>

          <div className='card-toolbar m-0'>
            <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
              <li
                className='nav-item'
                role='presentation'
                onClick={() => {
                  setTab('card');
                }}
                style={{cursor: 'pointer'}}
              >
                <span
                  className={classNames('nav-link fs-5 fw-bold me-5', tab === 'card' && 'active')}
                >
                  Банковская карта
                </span>
              </li>

              <li
                className='nav-item'
                role='presentation'
                onClick={() => {
                  setTab('cryptocurrency');
                }}
                style={{cursor: 'pointer'}}
              >
                <span
                  className={classNames(
                    'nav-link fs-5 fw-bold me-5',
                    tab === 'cryptocurrency' && 'active'
                  )}
                >
                  Криптовалюта
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className='card-body tab-content'>
          {tab === 'card' && (
            <div className='tab-pane fade show active' role='tabpanel'>
              {!isUser && <h3 className='mb-5'>Мои карты</h3>}
              {isUser && <h3 className='mb-5'>Банковские карты</h3>}

              <div className='row gx-9 gy-6'>
                {cards.map((card, i) => {
                  return (
                    <div
                      className='col-xl-6'
                      key={`${card?.currency}${card?.bank_name}${card?.details}${card?.id}`}
                    >
                      <Card className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                        <div className='d-flex flex-column py-2'>
                          <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                            {card?.receiver_name}
                            {card?.bank_name && (
                              <span className='badge badge-light-success fs-7 ms-2'>
                                {card?.bank_name}
                              </span>
                            )}
                            {card?.is_active && (
                              <span className='badge badge-light-primary fs-7 ms-2'>Активная</span>
                            )}
                          </div>

                          <div className='d-flex align-items-center'>
                            <img
                              src='assets/media/svg/card-logos/visa.svg'
                              alt=''
                              className='me-4'
                            />

                            <div>
                              <div className='fs-4 fw-bold'>{formatNumber(card?.details)}</div>
                              {/* <div className='fs-6 fw-semibold text-gray-400'>Ukraine</div> */}
                            </div>
                          </div>
                        </div>

                        {!isUser && (
                          <div className='d-flex align-items-center py-2'>
                            <Button
                              className={'btn-sm btn-light btn-active-light-primary me-3'}
                              color={'btn-light'}
                              action={() => {
                                if (card.is_active) {
                                  return setShowCreateAppModal(true);
                                }
                                return removeWallet(card.id);
                              }}
                            >
                              Удалить
                            </Button>
                            <Button
                              className={'btn-sm btn-light btn-active-light-primary'}
                              color={'btn-light'}
                              action={() => setEditCard(card)}
                            >
                              Изменить
                            </Button>
                          </div>
                        )}
                      </Card>
                    </div>
                  );
                })}

                {!isUser && (
                  <div className={classNames(!!cards.length ? 'col-xl-6' : 'col-xl-12')}>
                    <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                        <div className='mb-3 mb-md-0 fw-semibold'>
                          <h4 className='text-gray-900 fw-bold'>Добавить банковскую карту</h4>
                          <div className='fs-6 text-gray-700 pe-7'>
                            Очень внимательно подходите к
                            <br />
                            добавлению платежных методов
                          </div>
                        </div>

                        <AddCard
                          setCryptos={setCryptos}
                          setArrLength={setArrLength}
                          arrLength={arrLength}
                          setCards={setCards}
                          cards={cards}
                          editCard={editCard}
                          setEditCard={setEditCard}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isUser && !cards.length && (
                  <div className={classNames(!!cards.length ? 'col-xl-6' : 'col-xl-12')}>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed h-lg-100 p-6'>
                      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                        <div className='mb-3 mb-md-0 fw-semibold'>
                          <h4 className='text-gray-900 fw-bold'>
                            Сотрудник не добавил банковской карты
                          </h4>
                        </div>

                        <Button color={'btn-warning'}>Связатся</Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {tab === 'cryptocurrency' && (
            <div className='tab-pane fade show active' role='tabpanel'>
              {!isUser && <h3 className='mb-5'>Мои крипто кошельки</h3>}
              {isUser && <h3 className='mb-5'>Крипто кошельки</h3>}

              <div className='row gx-9 gy-6'>
                {cryptos.map((crypto, i) => {
                  return (
                    <div className='col-xl-6' key={`${crypto.details}${crypto.currency}`}>
                      <Card className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                        <div className='d-flex flex-column py-2'>
                          <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                            {crypto.currency}
                            {crypto?.is_active && (
                              <span className='badge badge-light-primary fs-7 ms-2'>Активный</span>
                            )}
                          </div>

                          <div className='d-flex align-items-center'>
                            <div>
                              <div
                                className='fs-4 fw-bold'
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {crypto.details}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isUser && (
                          <div className='d-flex align-items-center py-2'>
                            <Button
                              className={'btn-sm btn-light btn-active-light-primary me-3'}
                              color={'btn-light'}
                              action={() => {
                                if (crypto.is_active) {
                                  return setShowCreateAppModal(true);
                                }
                                return removeWallet(crypto.id);
                              }}
                            >
                              Удалить
                            </Button>
                            <Button
                              className={'btn-sm btn-light btn-active-light-primary'}
                              color={'btn-light'}
                              action={() => setEditCrypto(crypto)}
                            >
                              Изменить
                            </Button>
                          </div>
                        )}
                      </Card>
                    </div>
                  );
                })}

                {!isUser && (
                  <div className={classNames(!!cryptos.length ? 'col-xl-6' : 'col-xl-12')}>
                    <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
                      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                        <div className='mb-3 mb-md-0 fw-semibold'>
                          <h4 className='text-gray-900 fw-bold'>Добавить крипто кошелек</h4>
                          <div className='fs-6 text-gray-700 pe-7'>
                            Очень внимательно подходите к
                            <br />
                            добавлению платежных методов
                          </div>
                        </div>

                        <AddCrypto
                          setCards={setCards}
                          setArrLength={setArrLength}
                          arrLength={arrLength}
                          setCryptos={setCryptos}
                          cryptos={cryptos}
                          editCrypto={editCrypto}
                          setEditCrypto={setEditCrypto}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isUser && !cryptos.length && (
                  <div className={classNames(!!cards.length ? 'col-xl-6' : 'col-xl-12')}>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed h-lg-100 p-6'>
                      <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                        <div className='mb-3 mb-md-0 fw-semibold'>
                          <h4 className='text-gray-900 fw-bold'>
                            Сотрудник не добавил крипто кошелек
                          </h4>
                        </div>

                        <Button color={'btn-warning'}>Связатся</Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>

      <Card className='card'>
        <div className='card-header card-header-stretch border-bottom border-gray-200'>
          <div className='card-title'>
            <h3 className='fw-bold m-0'>История выплат</h3>
          </div>
        </div>

        <div className='tab-content'>
          <div id='kt_billing_months' className='card-body p-0 tab-pane fade show active'>
            <div className='table-responsive'>
              <table className='table table-row-bordered align-middle gy-4 gs-9'>
                <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                  <tr>
                    <td className='min-w-150px'>#</td>
                    <td className='min-w-250px'>Дата</td>
                    <td className='min-w-150px'>Сума</td>
                    <td className='min-w-150px'>Метод</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {transactionsList.map((el) => {
                    return (
                      <tr>
                        <td>{el.id}</td>
                        <td>{moment(el.created_at).format('DD.MM.YYYY HH:mm')}</td>
                        <td>${el.amount}</td>
                        <td>
                          <span className='btn btn-sm btn-light btn-active-light-primary'>
                            Банковская карта
                          </span>
                        </td>
                        <td className='text-right'>
                          <span className='btn btn-sm btn-light btn-active-light-primary'>
                            Квитанция
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
      <CreateAppModal
        customWidth='mw-400px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
          text='Вы можете удалить только не активный платежный метод!'
          icon={
            <span className='symbol-label'>
              <i className='bi bi-exclamation-circle fs-5x text-warning'></i>
            </span>
          }
        />
      </CreateAppModal>
    </>
  );
};

export default observer(Billing);
