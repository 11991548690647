import React, {useEffect, useState} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import classNames from 'classnames';

const Tabs = ({
  setCurrentShowTable,
  currentShowTable,
  checkedCompanies,
  checkedAdsets,
  cleanCheckedElements,
  // setCheckedCompanies,
  // setCheckedAdsets,
}) => {
  
  return (
    <div className={css.tabs}>
      <div
        className={classNames(css.tab, currentShowTable === 1 && css._active)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setCurrentShowTable(1);
        }}
      >
        <div className={css.name}>
          <svg
            className={classNames(css.svg, currentShowTable === 1 && css._active)}
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M16.5 8.3025V12.4875C16.5 14.7 14.7 16.5 12.4875 16.5H5.5125C3.3 16.5 1.5 14.7 1.5 12.4875V7.08H16.305C16.4175 7.4175 16.4775 7.7625 16.4925 8.13C16.5 8.1825 16.5 8.25 16.5 8.3025Z' />
            <path
              opacity='0.4'
              d='M16.305 7.08H1.5V4.815C1.5 2.985 2.985 1.5 4.815 1.5H6.5625C7.785 1.5 8.1675 1.8975 8.655 2.55L9.705 3.945C9.9375 4.2525 9.9675 4.2975 10.4025 4.2975H12.495C14.2725 4.29 15.7875 5.46 16.305 7.08Z'
            />
          </svg>

          <span>Campaigns</span>
        </div>
        {!!checkedCompanies.length && (
          <div className={css.elements} onClick={(e) => e.stopPropagation()}>
            <span>{checkedCompanies.length} выбрано</span>
            <svg
              onClick={() => cleanCheckedElements(1)}
              width='8'
              height='8'
              viewBox='0 0 8 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 7C6.90452 7.09548 6.77503 7.14912 6.64 7.14912C6.50497 7.14912 6.37548 7.09548 6.28 7L4 4.72L1.72 7C1.62452 7.09548 1.49503 7.14912 1.36 7.14912C1.22497 7.14912 1.09548 7.09548 1 7C0.904522 6.90452 0.850883 6.77503 0.850883 6.64C0.850883 6.50497 0.904522 6.37548 1 6.28L3.28 4L1 1.72C0.904522 1.62452 0.850883 1.49503 0.850883 1.36C0.850883 1.22497 0.904522 1.09548 1 1C1.09548 0.904522 1.22497 0.850883 1.36 0.850883C1.49503 0.850883 1.62452 0.904522 1.72 1L4 3.28L6.28 1C6.37548 0.904522 6.50497 0.850883 6.64 0.850883C6.77503 0.850883 6.90452 0.904522 7 1C7.09548 1.09548 7.14912 1.22497 7.14912 1.36C7.14912 1.49503 7.09548 1.62452 7 1.72L4.72 4L7 6.28C7.09548 6.37548 7.14912 6.50497 7.14912 6.64C7.14912 6.77503 7.09548 6.90452 7 7Z'
                fill='white'
              />
            </svg>
          </div>
        )}
      </div>
      <div
        className={classNames(css.tab, currentShowTable === 2 && css._active)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setCurrentShowTable(2);
        }}
      >
        <div className={css.name}>
          <svg
            className={classNames(css.svg, currentShowTable === 2 && css._active)}
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.4'
              d='M16.5 6.39V2.985C16.5 1.9275 16.02 1.5 14.8275 1.5H11.7975C10.605 1.5 10.125 1.9275 10.125 2.985V6.3825C10.125 7.4475 10.605 7.8675 11.7975 7.8675H14.8275C16.02 7.875 16.5 7.4475 16.5 6.39Z'
            />
            <path d='M16.5 14.8275V11.7975C16.5 10.605 16.02 10.125 14.8275 10.125H11.7975C10.605 10.125 10.125 10.605 10.125 11.7975V14.8275C10.125 16.02 10.605 16.5 11.7975 16.5H14.8275C16.02 16.5 16.5 16.02 16.5 14.8275Z' />
            <path d='M7.875 6.39V2.985C7.875 1.9275 7.395 1.5 6.2025 1.5H3.1725C1.98 1.5 1.5 1.9275 1.5 2.985V6.3825C1.5 7.4475 1.98 7.8675 3.1725 7.8675H6.2025C7.395 7.875 7.875 7.4475 7.875 6.39Z' />
            <path
              opacity='0.4'
              d='M7.875 14.8275V11.7975C7.875 10.605 7.395 10.125 6.2025 10.125H3.1725C1.98 10.125 1.5 10.605 1.5 11.7975V14.8275C1.5 16.02 1.98 16.5 3.1725 16.5H6.2025C7.395 16.5 7.875 16.02 7.875 14.8275Z'
            />
          </svg>

          <span>Ad sets</span>
        </div>
        {!!checkedAdsets.length && (
          <div className={css.elements} onClick={(e) => e.stopPropagation()}>
            <span>{checkedAdsets.length} выбрано</span>
            <svg
              onClick={() => cleanCheckedElements(2)}
              width='8'
              height='8'
              viewBox='0 0 8 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 7C6.90452 7.09548 6.77503 7.14912 6.64 7.14912C6.50497 7.14912 6.37548 7.09548 6.28 7L4 4.72L1.72 7C1.62452 7.09548 1.49503 7.14912 1.36 7.14912C1.22497 7.14912 1.09548 7.09548 1 7C0.904522 6.90452 0.850883 6.77503 0.850883 6.64C0.850883 6.50497 0.904522 6.37548 1 6.28L3.28 4L1 1.72C0.904522 1.62452 0.850883 1.49503 0.850883 1.36C0.850883 1.22497 0.904522 1.09548 1 1C1.09548 0.904522 1.22497 0.850883 1.36 0.850883C1.49503 0.850883 1.62452 0.904522 1.72 1L4 3.28L6.28 1C6.37548 0.904522 6.50497 0.850883 6.64 0.850883C6.77503 0.850883 6.90452 0.904522 7 1C7.09548 1.09548 7.14912 1.22497 7.14912 1.36C7.14912 1.49503 7.09548 1.62452 7 1.72L4.72 4L7 6.28C7.09548 6.37548 7.14912 6.50497 7.14912 6.64C7.14912 6.77503 7.09548 6.90452 7 7Z'
                fill='white'
              />
            </svg>
          </div>
        )}
      </div>
      <div
        className={classNames(css.tab, currentShowTable === 3 && css._active)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setCurrentShowTable(3);
        }}
      >
        <div className={css.name}>
          <svg
            className={classNames(css.svg, currentShowTable === 3 && css._active)}
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M14.25 6C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75C12 4.99264 13.0074 6 14.25 6Z' />
            <path
              opacity='0.4'
              d='M14.25 7.125C12.39 7.125 10.875 5.61 10.875 3.75C10.875 3.21 11.0175 2.7075 11.2425 2.25H5.64C3.0525 2.25 1.5 3.795 1.5 6.39V12.3525C1.5 14.955 3.0525 16.5 5.64 16.5H11.6025C14.1975 16.5 15.7425 14.955 15.7425 12.36V6.7575C15.2925 6.9825 14.79 7.125 14.25 7.125Z'
            />
            <path d='M8.8125 10.5H5.0625C4.755 10.5 4.5 10.245 4.5 9.9375C4.5 9.63 4.755 9.375 5.0625 9.375H8.8125C9.12 9.375 9.375 9.63 9.375 9.9375C9.375 10.245 9.12 10.5 8.8125 10.5Z' />
            <path d='M11.8125 13.5H5.0625C4.755 13.5 4.5 13.245 4.5 12.9375C4.5 12.63 4.755 12.375 5.0625 12.375H11.8125C12.12 12.375 12.375 12.63 12.375 12.9375C12.375 13.245 12.12 13.5 11.8125 13.5Z' />
          </svg>

          <span>Ads</span>
        </div>
        {/* <div className={css.elements} onClick={(e) => e.stopPropagation()}>
          <span>2 выбрано</span>
          <svg
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7 7C6.90452 7.09548 6.77503 7.14912 6.64 7.14912C6.50497 7.14912 6.37548 7.09548 6.28 7L4 4.72L1.72 7C1.62452 7.09548 1.49503 7.14912 1.36 7.14912C1.22497 7.14912 1.09548 7.09548 1 7C0.904522 6.90452 0.850883 6.77503 0.850883 6.64C0.850883 6.50497 0.904522 6.37548 1 6.28L3.28 4L1 1.72C0.904522 1.62452 0.850883 1.49503 0.850883 1.36C0.850883 1.22497 0.904522 1.09548 1 1C1.09548 0.904522 1.22497 0.850883 1.36 0.850883C1.49503 0.850883 1.62452 0.904522 1.72 1L4 3.28L6.28 1C6.37548 0.904522 6.50497 0.850883 6.64 0.850883C6.77503 0.850883 6.90452 0.904522 7 1C7.09548 1.09548 7.14912 1.22497 7.14912 1.36C7.14912 1.49503 7.09548 1.62452 7 1.72L4.72 4L7 6.28C7.09548 6.37548 7.14912 6.50497 7.14912 6.64C7.14912 6.77503 7.09548 6.90452 7 7Z'
              fill='white'
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default Tabs;
