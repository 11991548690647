/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useForm, Controller} from 'react-hook-form';
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';
import Card from '../../../../../shared/Card';

const Settings = ({data, bayer, updateData}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('bayer_id', bayer?.id);
    setValue('id', data?.id);
    setValue('departament_id', data?.departament_id);
    setValue('name', data?.name);
    setValue('source_link', data?.source_link);
    setValue('cost', data?.cost);
  }, [data]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries({
      ...data,
      cost: Number(data.cost.split(' ').join('')),
    })) {
      formData.append(`${key}`, value);
    }

    const result = await store.trafficsInfluencelinksUpdateLink(formData);

    !!result && toast.success(`Успешно обновлено!`, {});
    !!result && updateData(result);
    !result &&
      toast.error(`Произошла ошибка в обновлении!`, {
        theme: 'colored',
      });
  };

  return (
    <Card className='card'>
      <div className='card-header'>
        <div className='card-title fs-3 fw-bold'>Настройки</div>
      </div>

      <form id='kt_project_settings_form' className='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='card-body p-9'>
          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Название</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                {...register('name', {required: true})}
              />
              {errors?.name?.type === 'required' && (
                <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
              )}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Расходы</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <Controller
                control={control}
                rules={{required: true}}
                name='cost'
                render={({field: {value, ...fieldProps}}) => {
                  return (
                    <IMaskInput
                      {...fieldProps}
                      className='form-control form-control-lg form-control-solid'
                      mask={Number}
                      unmask={true}
                      radix={'.'}
                      defaultValue={data?.cost || '0.00'}
                      // value={value}
                    />
                  );
                }}
              />
              {errors?.cost?.type === 'required' && (
                <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
              )}
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Ссылка на источник</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                {...register('source_link')}
              />
            </div>
          </div>

          <div className='row mb-8'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>Байер</div>
            </div>

            <div className='col-xl-9 fv-row'>
              <input
                type='text'
                className='form-control form-control-solid'
                name='type'
                defaultValue={`${bayer?.first_name || ''} ${bayer?.last_name || ''}`}
                disabled
              />
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <input
            type='submit'
            className={`btn fw-bold  btn-primary cursor-pointer `}
            value={'Сохранить'}
          />
        </div>
      </form>
    </Card>
  );
};

export default observer(Settings);
