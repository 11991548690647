import {api} from './api';

const channelsAPI = {
  async getPixels(token, status = true) {
    try {
      const response = await api.get(`Pixels/getPixels?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getPixel(token, id) {
    try {
      const response = await api.get(`Pixels/getPixel?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getPixelLogs(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(
        `Pixels/getPixelLogs${queryStr}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getFacebookEvents(token) {
    try {
      const response = await api.get(`Pixels/getFacebookEvents?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async pixelsGetFacebookEvents(token, status = true) {
    try {
      const response = await api.get(`Pixels/getFacebookEvents?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async addPixel(data) {
    try {
      const response = await api.post(`Pixels/addPixel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async updatePixel(data) {
    try {
      const response = await api.post(`Pixels/updatePixel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default channelsAPI;
