/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import css from '../../../../styles/PagePwa.module.scss';
import classNames from 'classnames';
import moment from 'moment';
import PageWrapper from '../../../modules/PageWrapper';
import {useParams} from 'react-router-dom';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import {formatNumber, formatNumber2, formatNumberToCash} from '../../../functions/formatNumber';
import {Chart} from './ChartForPage';

const PagePush = () => {
  const params = useParams();

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: `PWA #${params.pwaId}`, path: `/resources/pwa/${params.pwaId}`},
        {isSeparator: true},
        {
          title: `PUSH #${params?.id}`,
          path: `/resources/pwa/${params.pwaId}/push/${params.id}`,
          isActive: true,
        },
      ]}
      title={`PUSH #${params?.id}`}
    >
      <Component id={params?.id} />
    </PageWrapper>
  );
};

const Component = observer(({isVerify, timeConfig, setPreloader, id}) => {
  const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const [getMainStats, getActivity] = await Promise.all([
      store.PWAPushGetMainStats({timeConfig, id}),
      store.PWAPushGetActivity({timeConfig, id}),
    ]);

    setData({...getMainStats, ...getActivity});
    setPreloader(false);
  };

  return (
    <>
      <div className={css.pagePush}>
        <section className={classNames(css.section_1, mode === 'dark' && css._dark)}>
          <div className={css.header}>
            <div className={css.id}>
              <span>#{id}</span>
            </div>
            <div className={css.params}>
              <div className={css.create}>
                <span className={classNames('badge badge-light-success', css.badge)}>
                  {moment(data?.created_at * 1000).format('DD.MM.YYYY')}
                </span>
              </div>
              <div className={css.status}>
                {!data?.is_reg && !data?.is_fd && !data?.is_rd && (
                  <span className={classNames('badge badge-light-primary', css.badge)}>
                    Установка
                  </span>
                )}
                {!!data?.is_reg && !data?.is_fd && !data?.is_rd && (
                  <span className={classNames('badge badge-light-success', css.badge)}>
                    Регистрация
                  </span>
                )}
                {!!data?.is_reg && !!data?.is_fd && !data?.is_rd && (
                  <span className={classNames('badge badge-light-info', css.badge)}>
                    Первый депозит
                  </span>
                )}
                {!!data?.is_reg && !!data?.is_fd && !!data?.is_rd && (
                  <span className={classNames('badge badge-light-danger', css.badge)}>
                    Повторный депозит
                  </span>
                )}
              </div>
              <div className={css.day}>
                <span className={classNames('badge badge-light-warning', css.badge)}>
                  {days[data?.week_day]} ({data?.time})
                </span>
              </div>
              {!!data?.is_delete && (
                <div
                  className={css.create}
                  style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}
                >
                  <span className={classNames('badge badge-light-secondary', css.badge)}>
                    {moment(data?.delete_created * 1000).format('DD.MM.YYYY')}
                  </span>
                </div>
              )}
              {/* <div className={css.isDel}>
                  {!!data?.is_delete && (
                    <span className={classNames('badge badge-light-secondary', css.badge)}>
                      Архив
                    </span>
                  )}
                  {!data?.is_delete && (
                    <span className={classNames('badge badge-light-success', css.badge)}>
                      Активно
                    </span>
                  )}
                </div> */}
            </div>
          </div>
          <div className={css.params}></div>
          <div className={classNames(css.titles, mode === 'dark' && css._dark)}>
            <div className={classNames(css.title, css._translate)}>{data?.title}</div>
            <div className={classNames(css.title, 'text-gray-600')}>{data?.title}</div>
          </div>
          <div className={css.texts}>
            <div className={classNames(css.text, css._translate)}>{data?.text}</div>
            <div className={classNames(css.text, 'text-gray-600')}>{data?.text}</div>
          </div>
        </section>
        <section className={css.section_2}>
          <div className={css.head}>
            <Stat
              title={'DR'}
              num={`${formatNumber2(data?.dr)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='23'
                  fill='none'
                  viewBox='0 0 24 23'
                >
                  <path
                    fill='#9A71F7'
                    d='M21 4.667h-3.5V2.333A2.336 2.336 0 0 0 15.167 0h-7a2.336 2.336 0 0 0-2.334 2.333v2.334h-3.5A2.336 2.336 0 0 0 0 7v3.5h23.333V7A2.336 2.336 0 0 0 21 4.667ZM8.167 2.333h7v2.334h-7V2.333ZM14 14H9.333v-2.333H0v8.166a2.336 2.336 0 0 0 2.333 2.334H21a2.336 2.336 0 0 0 2.333-2.334v-8.166H14V14Z'
                  />
                </svg>
              }
            />
            <Stat
              title={'VTR'}
              num={`${formatNumber2(data?.vtr)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='18'
                  fill='none'
                  viewBox='0 0 25 18'
                >
                  <path
                    fill='#58A0F5'
                    d='M16.25 7.5H25V10h-8.75V7.5Zm1.25 5H25V15h-7.5v-2.5ZM15 2.5h10V5H15V2.5Zm-12.5 15H15v-1.25A6.257 6.257 0 0 0 8.75 10h-2.5A6.257 6.257 0 0 0 0 16.25v1.25h2.5Zm5-8.75c2.494 0 4.375-1.881 4.375-4.375C11.875 1.881 9.994 0 7.5 0 5.006 0 3.125 1.881 3.125 4.375c0 2.494 1.881 4.375 4.375 4.375Z'
                  />
                </svg>
              }
            />
            <Stat
              title={'CTR'}
              num={`${formatNumber2(data?.ctr)}%`}
              mode={mode}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='28'
                  fill='none'
                  viewBox='0 0 28 28'
                >
                  <g fill='#FF6D04'>
                    <path d='M21 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z' />
                    <path d='M15.167 7c0-.83.178-1.618.492-2.333H7A2.336 2.336 0 0 0 4.667 7v14A2.336 2.336 0 0 0 7 23.333h14A2.336 2.336 0 0 0 23.333 21v-8.659c-.735.323-1.53.49-2.333.492A5.833 5.833 0 0 1 15.167 7Z' />
                  </g>
                </svg>
              }
            />
          </div>
          <div className={css.bottom}>
            <Stat2 num={`${formatNumber(data?.sented)}`} title={'Отправлено'} mode={mode} />
            <Stat2 num={`${formatNumber(data?.delivered)}`} title={'Доставлено'} mode={mode} />
            <Stat2 num={`${formatNumber(data?.show)}`} title={'Показано'} mode={mode} />
            <Stat2 num={`${formatNumber(data?.click)}`} title={'Кликнули'} mode={mode} />
          </div>
        </section>
      </div>
      <div className='mt-7'>
        <Chart title={'Активность'} subTitle={''} data={data?.schedule || []} />
      </div>
    </>
  );
});

const Stat = ({title, svg, num, mode}) => {
  return (
    <div className={classNames(css.stat, mode === 'dark' && css._dark)}>
      <span className={classNames(css.title, 'text-gray-500')}>{title}</span>
      <div className={css.paramContainer}>
        <span className={css.icon}>{svg}</span>
        <span className={css.param}>{num}</span>
      </div>
    </div>
  );
};

const Stat2 = ({num, title, mode, color}) => {
  return (
    <div className={classNames(css.stat, css.stat_2, mode === 'dark' && css._dark)}>
      <span className={css.num} style={{color}}>
        {num}
      </span>
      <span className={classNames(css.title, 'text-gray-500')}>{title}</span>
    </div>
  );
};

export default PagePush;
