/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import css from '../../../../styles/CustomPicker.module.scss';
import styles from '../../../../styles/Activity.module.scss';
import classNames from 'classnames';
import {useThemeMode} from '../../../../_metronic/partials';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import {toast} from 'react-toastify';
import Preloader from '../../../modules/Preloader';
import imagePlug from '../../../../_metronic/assets/media/user.png';
import DatePicker from 'react-datepicker';
import {formatNumber} from '../../../functions/formatNumber';
import {SelectorWithSearch} from '../../../shared/Selectors';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';

const momentTZ = require('moment-timezone');

const Activity = ({isVerify}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [prel, setPrel] = useState(false);
  const [mode, setMode] = useState('');

  const [activity, setActivity] = useState([]);
  const [activityContainerWidth, setActivityContainerWidth] = useState(0);

  const [date, setDate] = useState('');
  const [startTimeForRequest, setStartTimeForRequest] = useState('');
  const [saveTimeForRequest, setSaveTimeForRequest] = useState('');

  const [managers, setManagers] = useState({label: 'Заместители LTC', value: 33});

  useEffect(() => {
    initTime();
  }, []);

  useEffect(() => {
    if (!startTimeForRequest) return;

    const container = document.getElementById('activity-line-0');
    setActivityContainerWidth(container.offsetWidth);
    setSaveTimeForRequest(moment(startTimeForRequest).format('YYYY-MM-DD'));
    isVerify && getActivity(moment(startTimeForRequest).format('YYYY-MM-DD'), managers.value);
  }, [startTimeForRequest, isVerify]);

  const getActivity = async (date, managersIdRoles) => {
    setPrel(true);
    const activity = await store.dashboardSalesAssistantGetActives({
      role_id: managersIdRoles,
      date,
    });
    setActivity(activity);
    setPrel(false);
  };

  const getActivity2 = async (date, managersIdRoles) => {
    setActivity([]);
    setPrel(true);
    const activity = await store.dashboardSalesAssistantGetActives2({
      role_id: managersIdRoles,
      date,
    });
    setActivity(activity);
    setPrel(false);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className='form-control form-control-solid mb-lg-0 w-100 h-100'
      onClick={onClick}
      ref={ref}
      value={value}
    >
      {value}
    </div>
  ));

  const getKyivOffset = () => {
    return (moment.tz('Europe/Kiev').utcOffset() / 60) * 60 * 60 * 1000;
  };

  const getTimezoneOffset = (date) => {
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    const hoursOffset = (Math.abs(timezoneOffsetInMinutes) / 60) * 60 * 60 * 1000;

    return {timezoneOffsetInMinutes, hoursOffset};
  };

  const initTime = () => {
    const kievOffset = getKyivOffset();
    const {timezoneOffsetInMinutes, hoursOffset} = getTimezoneOffset(new Date());

    if (timezoneOffsetInMinutes > 0) {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setDate(currentTime);
      setStartTimeForRequest(currentTime - hoursOffset);
    } else if (timezoneOffsetInMinutes < 0) {
      if (kievOffset > hoursOffset) {
        const diff = kievOffset - hoursOffset;
        const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else if (kievOffset < hoursOffset) {
        const diff = hoursOffset - kievOffset;
        const currentTime = new Date(new Date().getTime() - diff).setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      } else {
        const currentTime = new Date().setHours(0, 0, 0);
        setDate(currentTime);
        setStartTimeForRequest(currentTime + hoursOffset);
      }
    } else {
      const diff = hoursOffset + kievOffset;
      const currentTime = new Date(new Date().getTime() + diff).setHours(0, 0, 0);
      setDate(currentTime);
      setStartTimeForRequest(currentTime);
    }
  };

  const changeDate = async (start, e) => {
    function getMillisecondsForMidnightInKievFromGMT(h, m, s, date) {
      const kievTime = momentTZ(date).tz('Europe/Kiev');
      const utcTime = kievTime.clone().utc();
      utcTime.hours(h);
      utcTime.minutes(m);
      utcTime.seconds(s);
      const milliseconds = utcTime.valueOf();
      return milliseconds;
    }

    setDate(start);

    const yearStart = start.getFullYear();
    const monthStart = start.getMonth();
    const dayStart = Number(e.target.innerText);
    const hoursStart = 0;
    const minutesStart = 0;
    const secondsStart = 0;

    const selectDateStart = new Date();
    selectDateStart.setUTCFullYear(yearStart);
    selectDateStart.setUTCMonth(monthStart);
    selectDateStart.setUTCDate(dayStart);
    selectDateStart.setUTCHours(hoursStart, minutesStart, secondsStart);

    const midnightInMillisStart = getMillisecondsForMidnightInKievFromGMT(0, 0, 0, selectDateStart);
    setSaveTimeForRequest(moment(midnightInMillisStart).format('YYYY-MM-DD'));
    getActivity2(moment(midnightInMillisStart).format('YYYY-MM-DD'), managers.value);
  };

  const calculateTotalDuration = (segments) => {
    let totalDurationMs = 0;

    segments.forEach((segment) => {
      const segmentDurationMs = segment.time_end * 1000 - segment.time_start * 1000;
      totalDurationMs += segmentDurationMs;
    });

    const totalDuration = moment.duration(totalDurationMs);
    const hours = Math.floor(totalDuration.asHours());
    const minutes = totalDuration.minutes();

    return (
      <span
        className={`badge badge-light-${hours < 6 ? 'danger' : 'success'} fw-bold px-4 py-3`}
      >{`${hours} ч ${minutes} мин`}</span>
    );
  };

  const calculateIntervalWidth = (
    startDayMs,
    endDayMs,
    intervalStartMs,
    intervalEndMs,
    blockWidth
  ) => {
    const dayDurationMs = endDayMs - startDayMs;
    const intervalDurationMs = intervalEndMs - intervalStartMs;
    const intervalPercentage = (intervalDurationMs / dayDurationMs) * 100;
    const intervalWidth = (intervalDurationMs / dayDurationMs) * blockWidth;

    return {
      intervalPercentage: intervalPercentage,
      intervalWidth: intervalWidth,
    };
  };

  const formatDuration = (durationMs) => {
    const duration = moment.duration(durationMs);
    const minutes = Math.floor(duration.asMinutes());
    return `${minutes} мин`;
  };

  const getTimeSegments = (time_start, time_end, arrSegments) => {
    const containerWidth = activityContainerWidth;

    const dayDurationMs = time_end - time_start;
    const arrDivs = [];

    let previousEnd = time_start;

    arrSegments.forEach((segment, index) => {
      const segment_start = segment.time_start * 1000;
      const segment_end = segment.time_end * 1000;
      if (segment_start > previousEnd) {
        const gapDurationMs = segment_start - previousEnd;
        const gapPercentage = (gapDurationMs / dayDurationMs) * 100;
        const gapWidth = (gapDurationMs / dayDurationMs) * containerWidth;
        const gapTime = `${moment(previousEnd).format('HH:mm')}-${moment(segment_start).format(
          'HH:mm'
        )}`;
        const gapDurationStr = formatDuration(gapDurationMs);

        arrDivs.push({
          type: 'gap',
          durationMs: gapDurationMs,
          percentage: gapPercentage,
          width: gapWidth,
          color: 'red',
          timePeriod: `${gapTime} (${gapDurationStr})`,
        });
      }

      const segmentWidth = calculateIntervalWidth(
        time_start,
        time_end,
        segment_start,
        segment_end,
        containerWidth
      );
      const segmentTime = `${moment(segment_start).format('HH:mm')}-${moment(segment_end).format(
        'HH:mm'
      )}`;
      const segmentDurationStr = formatDuration(segment_end - segment_start);

      arrDivs.push({
        type: 'segment',
        durationMs: segment_end - segment_start,
        percentage: segmentWidth.intervalPercentage,
        width: segmentWidth.intervalWidth,
        color: 'green',
        timePeriod: `${segmentTime} (${segmentDurationStr})`,
      });

      previousEnd = segment_end;
    });

    if (previousEnd < time_end) {
      const gapDurationMs = time_end - previousEnd;
      const gapPercentage = (gapDurationMs / dayDurationMs) * 100;
      const gapWidth = (gapDurationMs / dayDurationMs) * containerWidth;
      const gapTime = `${moment(previousEnd).format('HH:mm')}-${moment(time_end).format('HH:mm')}`;
      const gapDurationStr = formatDuration(gapDurationMs);

      arrDivs.push({
        type: 'gap',
        durationMs: gapDurationMs,
        percentage: gapPercentage,
        width: gapWidth,
        color: 'red',
        timePeriod: `${gapTime} (${gapDurationStr})`,
      });
    }

    if (!arrDivs.length) {
      return (
        <div className={styles.segmentContainer}>
          <div
            className={styles.gap}
            style={{
              width: `${containerWidth}px`,
            }}
          ></div>
          <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
            <span className={styles.marker} />
            00:00-23:59 (1440 мин)
          </span>
        </div>
      );
    }

    return arrDivs.map((item, index) => (
      <React.Fragment key={index}>
        {item.type === 'gap' && (
          <div className={styles.segmentContainer}>
            <div
              className={styles.gap}
              style={{
                width: `${item.width}px`,
              }}
            ></div>
            <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
              <span className={styles.marker} />
              {item.timePeriod}
            </span>
          </div>
        )}
        {item.type === 'segment' && (
          <div className={styles.segmentContainer}>
            <div
              className={styles.segment}
              style={{
                width: `${item.width}px`,
              }}
            ></div>
            <span className={classNames(styles.gapTime, 'fs-8 fw-600')}>
              <span className={styles.marker} />
              {item.timePeriod}
            </span>
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Активность других менеджеров</h3>
        </div>
        <div className='d-flex flex-wrap align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
          <div className='me-4 mt-2 min-w-20'>
            <div className={`col-12 fv-row px-2`}>
              <label
                htmlFor='kt_td_picker_linked_2_input'
                className='form-label d-flex align-items-center'
              >
                <SelectorWithSearch
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={[
                    {label: 'Заместители LTC', value: 33},
                    {label: 'Заместители RD', value: 34},
                    {label: 'Bot Manager', value: 28},
                    {label: 'Could Manager', value: 29},
                  ]}
                  value={managers}
                  setValue={setManagers}
                  actions={(managers) => {
                    getActivity2(saveTimeForRequest, managers.value);
                  }}
                />
              </label>
            </div>
          </div>
          <div className='mt-2 min-w-20'>
            <div className={`col-6 fv-row px-2`}>
              <label
                htmlFor='kt_td_picker_linked_2_input'
                className='form-label d-flex align-items-center'
              >
                <DatePicker
                  calendarClassName={classNames(
                    css.container,
                    css[`_${mode}`],
                    mode === 'dark' ? css.dark : css.light
                  )}
                  dateFormat='dd/MM/yyyy'
                  selected={Number(date)}
                  onChange={changeDate}
                  customInput={<CustomInput />}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table
            id='kt_profile_overview_table'
            className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead className='fs-7 text-gray-400 text-uppercase'>
              <tr id={`activity-line-0`}>
                <th className='w-100'>Менеджер</th>
                {/* <th className='min-w-600px max-w-800px text-center' id={`activity-line-0`}>
                  Активность
                </th> */}
                <th className='min-w-150px text-center text-nowrap'>Выходной</th>
                <th className='min-w-150px text-center text-nowrap'>Время работы</th>
                <th className='min-w-150px text-center'>Сообщения</th>
              </tr>
            </thead>
            <tbody className='fs-6'>
              {activity.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <tr className='border-0'>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='me-5 position-relative'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                src={
                                  !!item?.avatar
                                    ? `${process.env.REACT_APP_API_HOST}/${item?.avatar}`
                                    : imagePlug
                                }
                                alt=''
                              />
                            </div>
                          </div>

                          <div className='d-flex flex-column justify-content-center'>
                            {/* <Link to={`/sales/${type}/managers/${item.id}`}> */}
                            <span className='d-flex align-items-center fs-6 text-gray-800 text-nowrap'>
                              {item.first_name} {item.last_name}
                            </span>
                            {/* </Link> */}
                            <div className='fw-semibold text-gray-400'>#{item.id}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <ChangeWork
                            is_dayoff={item?.is_dayoff}
                            manager={`${item?.first_name} ${item?.last_name}`}
                            id={item?.id}
                          />
                        </div>
                      </td>
                      <td className='text-center'>{calculateTotalDuration(item.times)}</td>
                      <td className='text-center'>{formatNumber(item.count_messages)}</td>
                    </tr>
                    <tr className='border-bottom'>
                      <td colSpan='4'>
                        {' '}
                        <div
                          className={classNames(styles.activityContainer)}
                          style={
                            mode === 'dark'
                              ? {'--tooltipMode': 'rgb(31, 31, 90)', '--tooltipTextMode': 'white'}
                              : {'--tooltipMode': '#ebebeb', '--tooltipTextMode': 'black'}
                          }
                        >
                          {getTimeSegments(
                            item.time_start * 1000,
                            item.time_end * 1000,
                            item.times
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {prel && <Preloader style={{border: '0'}} />}
        </div>
      </div>
    </div>
  );
};

const ChangeWork = observer(({is_dayoff = false, manager, id}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [dayoff, setDayoff] = useState(false);

  useEffect(() => {
    if (typeof is_dayoff === 'undefined') return setDayoff(false);

    setDayoff(is_dayoff);
  }, [is_dayoff]);

  const save = async (new_dayoff) => {
    setShowCreateAppModal(false);
    const result = await store.usersUpdateDayoff(new_dayoff, id);

    if (!!result) {
      setDayoff(new_dayoff);
      return toast.success(`Успешно обновлено!`, {});
    }
    toast.error(`Произошла ошибка в обновлении!`, {
      theme: 'colored',
    });
  };

  return (
    <>
      <div className='form-check form-check-solid form-check-custom form-switch'>
        <input
          className='form-check-input w-45px h-30px'
          disabled={store.roles.includes(13) || store.roles.includes(32) ? true : false}
          style={{cursor: 'pointer'}}
          type='checkbox'
          name='is_payment'
          checked={dayoff}
          onChange={() => {
            setShowCreateAppModal(true);
          }}
        />
        <label className='form-check-label' htmlFor='githubswitch'></label>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <>
              {dayoff && (
                <span>
                  Вы уверены что хотите отключить статус "Выходной" для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
              {!dayoff && (
                <span>
                  Вы уверены что хотите включить статус "Выходной" для{' '}
                  <span className='text-primary'>{manager}</span>
                </span>
              )}
            </>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                save(!dayoff);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default observer(Activity);
