import React from 'react';
import Card from '../../shared/Card';
import numeral from 'numeral';
import moment from 'moment';
import {InputCopy} from '../../shared/Inputs';
import {formatNumberToCash} from '../../functions/formatNumber';

const Deposits = ({data, leadId}) => {
  return (
    <Card className='card'>
      <div className='card-header border-bottom border-gray-200'>
        <div className='card-title'>
          <h3 className='fw-bold m-0'>Депозиты</h3>
        </div>
        <div className='card-toolbar'>
          <InputCopy
            style={{maxWidth: '250px'}}
            text={`https://thlbots.com/?type=deposit&lead_id=${leadId}`}
          />
        </div>
      </div>

      <div className='tab-content'>
        <div id='kt_billing_months' className='card-body p-0 tab-pane fade show active'>
          <div className='table-responsive'>
            <table className='table table-row-bordered align-middle gy-4 gs-9'>
              <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold'>
                <tr>
                  <td className='min-w-150px'>Platform Id</td>
                  <td className='min-w-250px'>Сумма</td>
                  <td className='min-w-150px'>Страна</td>
                  <td className='min-w-150px'>Дата</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {data.map((item, index) => {
                  return (
                    <tr key={`${item?.platform_id}-${index}`}>
                      <td>#{item?.platform_id}</td>
                      <td>{formatNumberToCash(item?.amount)}</td>
                      <td>{item?.country_code?.toUpperCase()}</td>
                      <td>{moment(item?.created_at * 1000).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Deposits;
