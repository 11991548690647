/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */
import axios from 'axios';
import dashboardsAPI from './dashboards';
import employeesAPI from './employees';
import myAccountAPI from './myAccount';
import leadsAPI from './leads';
import devAPI from './dev';
import offersAPI from './offers';
import channelsAPI from './channels';
import botsAPI from './bots';
import pixelsAPI from './pixels';
import departmentsAPI from './departments';
import landingsAPI from './landings';
import salesAPI from './sales';
import countriesAPI from './counties';
import financeAPI from './finance';
import trafficAPI from './traffic';
import advertisersAPI from './advertisers';
import pwaAPI from './pwa';
import telegramAPI from './telegram';

const baseURL = `${process.env.REACT_APP_API_HOST}/api` || 'http://localhost:3011/';
export const api = axios.create({baseURL, maxRedirects: 1, timeout: 60000});

api.interceptors.response.use(
  (response) => {
    if (api?.interceptors?.request?.handlers) {
      try {
        api.interceptors.request.handlers = [];
      } catch (e) {
        console.log('Error ejecting handler');
        console.log(e);
      }
    }
    return response;
  },
  (error) => {
    if (api?.interceptors?.request?.handlers) {
      try {
        api.interceptors.request.handlers = [];
      } catch (e) {
        console.log('Error ejecting handler');
        console.log(e);
      }
    }

    let errorData = error;
    if (error.response && [401, 403].includes(error.response.status)) {
      // console.log("Session expired");
      // console.log(error.response.statusText);
      if (!isServer) {
        localStorage.removeItem('appState');
      }
    }

    return Promise.reject(errorData);
  }
);

async function getTemporaryToken({ip, locale, userAgent}) {
  try {
    const response = await api.post(`getTempToken?ip=${ip}&user_agent=${userAgent}&geo=${locale}`);

    if (response.data.success) {
      return response.data;
    } else {
      console.error('Основной сервер не отвечае', response?.data?.error);
      return null;
    }
  } catch (error) {
    return console.error('Error token:', error);
  }
}

async function verifyAuth({main_token, user_ip, user_locale, user_agent}) {
  try {
    const response = await api.post(
      `authVerify?ip=${user_ip}&user_agent=${user_agent}&geo=${user_locale}&main_token=${main_token}`
    );
    if (!!response?.data?.success) {
      return response.data.data;
    } else {
      console.error('Токен не прошел проверку:', response?.data?.message);
      return null;
    }
  } catch (error) {
    console.error('Error token:', error);
    if (error.response.status === 503) {
      return error.response.status;
    } else {
      return null;
    }
  }
}

async function revokeToken(token, revoke_token) {
  try {
    const response = await api.post(`revokeToken`, {token, revoke_token});
    if (!!response?.data?.success) {
      return true;
    } else {
      console.error('Произошла ошибка:', response?.data?.message);
      return false;
    }
  } catch (error) {
    console.error('Error token:', error);
    return null;
  }
}

async function revokeTempToken(token) {
  try {
    const response = await api.post(`revokeTempToken`, {temp_token: token});
    console.log(response);
    if (!!response?.data?.success) {
      return console.log('Веременный токен удален!');
    } else {
      console.error('Произошла ошибка:', response?.data?.message);
      return null;
    }
  } catch (error) {
    console.error('Error token:', error);
    return null;
  }
}

async function getIp() {
  try {
    const response = await api.get(`getUserIP`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export default {
  client: api,
  getTemporaryToken,
  verifyAuth,
  revokeToken,
  revokeTempToken,
  getIp,
  dashboardsAPI,
  employeesAPI,
  myAccountAPI,
  leadsAPI,
  devAPI,
  offersAPI,
  channelsAPI,
  botsAPI,
  landingsAPI,
  pixelsAPI,
  departmentsAPI,
  salesAPI,
  countriesAPI,
  financeAPI,
  trafficAPI,
  advertisersAPI,
  pwaAPI,
  telegramAPI
};
