import {api} from './api';

const leadsAPI = {
  async leadsGetLeads(token, page, perPage) {
    try {
      const response = await api.get(
        `Leads/getLeads?token=${token}&page=${page}&perPage=${perPage}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsSearchTelegramId(token, telegram_id, bot_id) {
    try {
      const response = await api.get(
        `Leads/searchTelegramId?token=${token}&telegram_id=${telegram_id}&bot_id=${bot_id}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsSearchLeadId(token, id) {
    try {
      const response = await api.get(`Leads/searchLeadId?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsSearchPlatformId(token, id) {
    try {
      const response = await api.get(`Leads/searchPlatformId?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetLead(token, id) {
    try {
      const response = await api.get(`Leads/getLead?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetStatuses(token) {
    try {
      const response = await api.get(`Leads/getStatuses?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetLeadRegistrations(token, id) {
    try {
      const response = await api.get(`Leads/getLeadRegistrations?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetLeadDeposits(token, id) {
    try {
      const response = await api.get(`Leads/getLeadDeposits?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsTranfersGetLead(token, id) {
    try {
      const response = await api.get(`Leads/Tranfers/getLead?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetChats(token, id) {
    try {
      const response = await api.get(`Leads/getChats?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsTranfersAddLtcTransfer(data) {
    try {
      const response = await api.post(`Leads/Tranfers/addLtcTransfer`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsTranfersAddRdTransfer(data) {
    try {
      const response = await api.post(`Leads/Tranfers/addRdTransfer`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async leadsGetAdvertisersUpdateLead({id, token}) {
    try {
      const response = await api.get(`Leads/getAdvertisersUpdateLead?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default leadsAPI;
