/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

const InputSearch = ({
  title = '',
  array = [],
  newArray = () => {},
  searchParameters,
  actions = () => {},
  style = {},
}) => {
  const [string, setString] = useState('');
  const [firstRendering, setFirstRendering] = useState(true);

  useEffect(() => {
    if (!!firstRendering) return setFirstRendering(false);
    if (!string) return newArray(array);
    const val = string.trim().toLowerCase();

    const sortedArray = array
      .filter((item) => {
        const matchCount = searchParameters.reduce((count, parameter) => {
          const value = String(item[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);
        return matchCount > 0;
      })
      .sort((a, b) => {
        const countA = searchParameters.reduce((count, parameter) => {
          const value = String(a[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);

        const countB = searchParameters.reduce((count, parameter) => {
          const value = String(b[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);

        return countB - countA;
      });

    newArray(sortedArray);
  }, [string]);

  const handleChange = (e) => {
    const {value} = e.target;
    setString(value);
    actions();
  };

  return (
    <div className='d-flex align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
      <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
        <span className='path1'></span>
        <span className='path2'></span>
      </i>
      <input
        type='text'
        className='form-control form-control-solid w-100 mw-250px ps-13'
        placeholder={title}
        value={string || ''}
        onChange={handleChange}
        style={style}
      />
    </div>
  );
};

export default InputSearch;
