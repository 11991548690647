/* eslint-disable react-hooks/exhaustive-deps */
import React, {forwardRef, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import styles from '../../../../../../styles/AnalysisRd.module.scss';
import classNames from 'classnames';
import {formatNumber, formatNumber2, formatNumberToCash} from '../../../../../functions/formatNumber';
import {useThemeMode} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import css from '../../../../../../styles/CustomPicker.module.scss';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';

const Analyze = ({id, tc}) => {
  const store = useStore();
  const [mode, setMode] = useState('');
  const theme = useThemeMode();
  const [data, setData] = useState({});
  const [timeConfigData, setTimeConfigData] = useState({});
  const [qualificationsStart, setQualificationsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [qualificationsEnd, setQualificationsEnd] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsStart, setDepositsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsEnd, setDepositsEnd] = useState(new Date().setUTCHours(0, 0, 0));

  const getData = async () => {
    const tcData = await store.getTimeConfig();
    const firstRequestTime = tcData.find((item) => item.time_config === tc.time_config);
    const getStats = await store.trafficsFacebookGetLinksAnalysisRd(id, {time_config: 'custom', time_start: firstRequestTime.time_start, time_end: firstRequestTime.time_end}, firstRequestTime.time_end, firstRequestTime.time_start);
    setData(getStats);
    setTimeConfigData(tcData);
    setQualificationsStart(new Date(firstRequestTime.time_start * 1000));
    setQualificationsEnd(new Date(firstRequestTime.time_end * 1000));
    setDepositsStart(new Date(firstRequestTime.time_start * 1000));
    setDepositsEnd(new Date(firstRequestTime.time_end * 1000));
  }

  useEffect(() => {
    getData()
  }, [])

  const updateDataByTimeConfig = async () => {
    const time = timeConfigData.find((item) => item.time_config === tc.time_config);
    const getStats = await store.trafficsFacebookGetLinksAnalysisRd(id, {time_config: 'custom', time_start: time.time_start, time_end: time.time_end}, time.time_end, time.time_start);
    setData(getStats);
    setQualificationsStart(new Date(time.time_start * 1000));
    setQualificationsEnd(new Date(time.time_end * 1000));
    setDepositsStart(new Date(time.time_start * 1000));
    setDepositsEnd(new Date(time.time_end * 1000));
  }

  useEffect(() => {
    if(timeConfigData) {
      updateDataByTimeConfig()
    }
  }, [tc])

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className='form-control form-control-solid mb-lg-0 w-100 h-100'
      onClick={onClick}
      ref={ref}
      value={value}
    >
      {value}
    </div>
  ));

  const search = async () => {
    if (depositsStart < qualificationsStart) {
      return toast.error(
        'Начальная дата депозитов не может быть меньше, начальной даты квалификаций!',
        {theme: 'colored'}
      );
    }
    if (qualificationsStart > depositsEnd) {
      return toast.error(
        'Начальная дата квалификаций не может быть больше, конечной даты депозитов!',
        {theme: 'colored'}
      );
    }
    const qStart = Math.floor(new Date(qualificationsStart).getTime() / 1000);
    const qEnd = Math.floor(new Date(qualificationsEnd).getTime() / 1000);
    const dStart = Math.floor(new Date(depositsStart).getTime() / 1000);
    const dEnd = Math.floor(new Date(depositsEnd).getTime() / 1000);
    const getStats = await store.trafficsFacebookGetLinksAnalysisRd(id, {time_config: 'custom', time_start: qStart, time_end: qEnd}, dEnd, dStart);
    setData(getStats);
  };

  return (
    <>
      <div className='card card-flush mt-6 mt-xl-9'>
        <div className='card-header mt-5'>
          <div className='card-title' style={{ width: '100%', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div className='d-flex' style={{ flexWrap: 'wrap' }}>
              <div className={`d-flex align-items-center`}>
                <span className='fw-bold fs-5' style={{marginBottom: '2px'}}>Квалификации</span>
                <div className={`d-flex`}>
                  <div className={`fv-row px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                      style={{marginBottom: '0'}}
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light,
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsStart}
                        onChange={(date) => setQualificationsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='fv-row px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                      style={{marginBottom: '0'}}
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light,
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsEnd}
                        onChange={(date) => setQualificationsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className={`d-flex align-items-center`}>
                <span className='fw-bold fs-5' style={{marginBottom: '2px'}}>Депозиты</span>
                <div className={`d-flex align-items-center`}>
                  <div className={`fv-row px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                      style={{marginBottom: '0'}}
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light,
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsStart}
                        onChange={(date) => setDepositsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='fv-row px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                      style={{marginBottom: '0'}}
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light,
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsEnd}
                        onChange={(date) => setDepositsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className='w-20 d-flex flex-nowrap align-items-center justify-content-center'
              action={() => {
                search();
              }}
            >
              <i className='bi bi-search'></i>
              Применить
            </Button>
          </div>
        </div>

        <div className='card-body pt-0'>
          <div className={styles.container}>
            <div className={classNames(styles.stats, styles.statsBlogger)}>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                <span className={classNames(styles.title, 'text-gray-500')}>Квалификации</span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='28'
                          height='20'
                          fill='none'
                          viewBox='0 0 28 20'
                        >
                          <path
                            fill='#9A71F7'
                            d='M3.462 4.922c0 2.806 2.083 4.922 4.846 4.922 2.762 0 4.846-2.116 4.846-4.922C13.154 2.117 11.07 0 8.308 0 5.545 0 3.462 2.117 3.462 4.922Zm20.076.224a1 1 0 0 0-1-1h-.769a1 1 0 0 0-1 1v3.219h-3.154a1 1 0 0 0-1 1v.813a1 1 0 0 0 1 1h3.154v3.218a1 1 0 0 0 1 1h.77a1 1 0 0 0 1-1v-3.219h3.153a1 1 0 0 0 1-1v-.812a1 1 0 0 0-1-1h-3.154v-3.22ZM2.77 19.616h12.846a1 1 0 0 0 1-1v-.407c0-3.877-3.105-7.031-6.923-7.031h-2.77C3.106 11.178 0 14.332 0 18.209v.406a1 1 0 0 0 1 1h1.77Z'
                          />
                        </svg>
                      </span>
                  <span className={classNames(styles.param)}>
                        {formatNumber(data?.count_qualifications)}
                      </span>
                </div>
              </div>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Уникальные депозиты
                    </span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='20'
                          fill='none'
                          viewBox='0 0 25 20'
                        >
                          <path
                            fill='#F5C51D'
                            d='M22.5 0h-20A2.503 2.503 0 0 0 0 2.5v15C0 18.879 1.121 20 2.5 20h20c1.379 0 2.5-1.121 2.5-2.5v-15C25 1.121 23.879 0 22.5 0ZM8.394 5c1.438 0 2.5 1.061 2.5 2.5S9.832 10 8.394 10c-1.439 0-2.5-1.061-2.5-2.5S6.954 5 8.394 5Zm4.643 10H3.75v-.581c0-1.716 2.095-3.482 4.644-3.482s4.643 1.766 4.643 3.482V15Zm8.213-1.25h-5v-2.5h5v2.5Zm0-5H15v-2.5h6.25v2.5Z'
                          />
                        </svg>
                      </span>
                  <span className={styles.param}>
                        {formatNumber(data?.count_deposits_unique)}
                      </span>
                </div>
              </div>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Сумма депозитов
                    </span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='20'
                          fill='none'
                          viewBox='0 0 26 20'
                        >
                          <path
                            fill='#63D48A'
                            d='M2.889 0C1.295 0 0 1.457 0 3.25v13c0 1.793 1.295 3.25 2.889 3.25H23.11c1.593 0 2.889-1.457 2.889-3.25v-13C26 1.457 24.704 0 23.111 0H2.89Zm2.889 16.25h-2.89V13c1.594 0 2.89 1.457 2.89 3.25ZM2.888 6.5V3.25h2.89c0 1.793-1.296 3.25-2.89 3.25Zm17.334 9.75c0-1.793 1.296-3.25 2.89-3.25v3.25h-2.89Zm2.89-9.75c-1.594 0-2.89-1.457-2.89-3.25h2.89V6.5ZM7.943 9.75c0-.747.131-1.486.385-2.177a5.78 5.78 0 0 1 1.096-1.845 5.062 5.062 0 0 1 1.64-1.233A4.573 4.573 0 0 1 13 4.062c.664 0 1.321.148 1.935.433.613.286 1.17.705 1.64 1.233.47.528.842 1.155 1.096 1.845.254.69.385 1.43.385 2.177 0 .747-.131 1.486-.385 2.177a5.78 5.78 0 0 1-1.096 1.845 5.063 5.063 0 0 1-1.64 1.233 4.573 4.573 0 0 1-1.935.432 4.573 4.573 0 0 1-1.935-.432 5.063 5.063 0 0 1-1.64-1.233 5.781 5.781 0 0 1-1.096-1.845 6.31 6.31 0 0 1-.385-2.177Zm3.431-2.438c0 .493.312.9.722.996v2.458h-.18c-.497 0-.903.457-.903 1.015 0 .559.406 1.016.903 1.016h2.166c.497 0 .903-.457.903-1.016 0-.558-.406-1.015-.903-1.015h-.18V7.312c0-.558-.407-1.015-.903-1.015h-.722c-.497 0-.903.457-.903 1.016Z'
                          />
                        </svg>
                      </span>
                  <span className={styles.param}>
                        {formatNumberToCash(data?.amounts_deposits)}
                      </span>
                </div>
              </div>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                    <span className={classNames(styles.title, 'text-gray-500')}>
                      Кол-во депозитов
                    </span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='18'
                          fill='none'
                          viewBox='0 0 25 18'
                        >
                          <path
                            fill='#58A0F5'
                            d='M16.25 7.5H25V10h-8.75V7.5Zm1.25 5H25V15h-7.5v-2.5ZM15 2.5h10V5H15V2.5Zm-12.5 15H15v-1.25A6.257 6.257 0 0 0 8.75 10h-2.5A6.257 6.257 0 0 0 0 16.25v1.25h2.5Zm5-8.75c2.494 0 4.375-1.881 4.375-4.375C11.875 1.881 9.994 0 7.5 0 5.006 0 3.125 1.881 3.125 4.375c0 2.494 1.881 4.375 4.375 4.375Z'
                          />
                        </svg>
                      </span>
                  <span className={styles.param}>{formatNumber(data?.count_deposits)}</span>
                </div>
              </div>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                <span className={classNames(styles.title, 'text-gray-500')}>Средний чек</span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='23'
                          fill='none'
                          viewBox='0 0 24 23'
                        >
                          <path
                            fill='#FF6D04'
                            d='M21 4.667h-3.5V2.333A2.336 2.336 0 0 0 15.167 0h-7a2.336 2.336 0 0 0-2.334 2.333v2.334h-3.5A2.336 2.336 0 0 0 0 7v3.5h23.333V7A2.336 2.336 0 0 0 21 4.667ZM8.167 2.333h7v2.334h-7V2.333ZM14 14H9.333v-2.333H0v8.166a2.336 2.336 0 0 0 2.333 2.334H21a2.336 2.336 0 0 0 2.333-2.334v-8.166H14V14Z'
                          />
                        </svg>
                      </span>
                  <span className={styles.param}>
                        {formatNumberToCash(data?.average_bill)}
                      </span>
                </div>
              </div>
              <div className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                <span className={classNames(styles.title, 'text-gray-500')}>High rollers</span>
                <div className={styles.paramContainer}>
                      <span className={styles.icon}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='26'
                          fill='none'
                          viewBox='0 0 26 26'
                        >
                          <g fill='#3CF9DB'>
                            <path
                              d='M13 2.167C7.027 2.167 2.167 7.027 2.167 13c0 5.974 4.86 10.833 10.833 10.833 5.974 0 10.833-4.86 10.833-10.833S18.973 2.167 13 2.167Zm0 18.416c-4.18 0-7.583-3.401-7.583-7.583S8.819 5.417 13 5.417c4.18 0 7.583 3.401 7.583 7.583S17.181 20.583 13 20.583Z' />
                            <path
                              d='M13 7.583A5.423 5.423 0 0 0 7.583 13 5.423 5.423 0 0 0 13 18.417 5.423 5.423 0 0 0 18.417 13 5.423 5.423 0 0 0 13 7.583Zm0 7.584A2.169 2.169 0 0 1 10.833 13 2.17 2.17 0 0 1 13 10.833 2.17 2.17 0 0 1 15.167 13 2.169 2.169 0 0 1 13 15.167Z' />
                          </g>
                        </svg>
                      </span>
                  <span className={styles.param}>{formatNumber(data?.high_rollers)}</span>
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.circleContainer, mode === 'dark' && styles._dark)}
            >
              <span className={styles.title}>Активность</span>
              <svg width='200' height='100' viewBox='-10 -10 220 120'>
                {/* Основний напівкруг */}
                <path
                  d='M 0,100 A 100,100 0 0,1 200,100'
                  stroke='#E7E7E7'
                  strokeWidth='20'
                  fill='none'
                  strokeLinecap='round'
                />

                {/* Заповнений напівкруг */}
                <path
                  d='M 0,100 A 100,100 0 0,1 200,100'
                  stroke='rgba(53, 202, 106, 1)'
                  strokeWidth='20'
                  fill='none'
                  strokeDasharray={(200 / 2) * 3.14}
                  strokeDashoffset={(200 / 2) * 3.14 * (1 - (data?.activity || 0) / 100)}
                  strokeLinecap='round'
                  transform='rotate(0, 150, 150)'
                />
              </svg>
              <div className={styles.statContainer}>
                    <span className={classNames(styles.stat, mode === 'dark' && styles._dark)}>
                      {formatNumber2(data?.activity)}%
                    </span>
              </div>
              <p className={styles.text}>
                Процент квалификаций, которые <br /> сделали депозит
              </p>
              <div className={styles.markers}>
                    <span>
                      <span className={styles.marker} style={{backgroundColor: '#E7E7E7'}} />
                      Квалификации
                    </span>
                <span>
                      <span
                        className={styles.marker}
                        style={{backgroundColor: 'rgba(53, 202, 106, 1)'}}
                      />
                      Уникальные депозиты
                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Analyze);
