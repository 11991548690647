/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {IMaskInput} from 'react-imask';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import Warning from '../../../../../modules/Warning';
import {Selector, SelectorIsMulti} from '../../../../../shared/Selectors';

const AddTarget = ({traffic_id, updateTrafficList, setPage}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
    watch,
  } = useForm();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  const [optionsBayers, setOptionsBayers] = useState([]);
  const [optionsBots, setOptionsBots] = useState([]);
  const [optionsChannels, setOptionsChannels] = useState([]);

  useEffect(() => {
    if (showCreateAppModal) {
      (async () => {
        const getBots = await store.botsGetListBots();
        const getBayers = await store.trafficsInfluenceGetBayersList();

        const newBots = getBots.map((el) => {
          return {label: el.name, value: el.id};
        });

        const newBayers = getBayers.map((el) => {
          return {label: `${el.first_name} ${el.last_name}`, value: el.id};
        });

        setOptionsBots(newBots);
        setOptionsBayers(newBayers);
        setValue('traffic_id', traffic_id);
      })();
    }
  }, [showCreateAppModal]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'bot_id') {
        setOptionsChannels([]);
        getChannels(value.bot_id);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getChannels = async (bot_id) => {
    const getChannels = await store.channelsGetChannelsList('main', bot_id);

    const newChannels = getChannels.map((el) => {
      return {label: el.name, value: el.id};
    });

    setOptionsChannels(newChannels);
  };

  const onSubmit = async (data) => {
    const result = await store.trafficsInfluenceLinksAddLink({
      ...data,
      cost: Number(data.cost.split(' ').join('')),
      channel_ids: data.channel_ids.map((item) => item.value),
    });
    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

    updateTrafficList({...result, romi: 0});
    setPage('advertisements');
    reset();
    setSave(true);
    setShowCreateAppModal(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`, {});
    }
  };

  return (
    <>
      <Button action={() => setShowCreateAppModal(true)}>Добавить рекламу</Button>
      <CreateAppModal
        customWidth='mw-550px'
        padding='scroll-y'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление Рекламной кампании'}
      >
        <>
          <form
            // className='form fv-plugins-bootstrap5 fv-plugins-framework'
            style={{display: 'flex', flexDirection: 'column'}}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Выберите Байера</label>

              <Controller
                name='bayer_id'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value, name, ref}}) => (
                  <Selector options={optionsBayers} value={value} onChange={onChange} />
                )}
              />

              {errors?.bayer_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Выберите Бота</label>

              <Controller
                name='bot_id'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value, name, ref}}) => (
                  <Selector options={optionsBots} value={value} onChange={onChange} />
                )}
              />

              {errors?.bot_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>
            {optionsChannels.length > 0 && (
              <div
                className='fv-row mb-7 fv-plugins-icon-container'
                // style={{display: 'block', height: '100px'}}
              >
                <label className='required fw-semibold fs-6 mb-2'>Выберите Канал</label>
                <Controller
                  name='channel_ids'
                  control={control}
                  rules={{required: true}}
                  render={({field: {onChange, value, name, ref}}) => (
                    <SelectorIsMulti options={optionsChannels} value={value} onChange={onChange} />
                  )}
                />

                {errors?.channel_ids?.type === 'required' && (
                  <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                    Обязательное поле
                  </div>
                )}
              </div>
            )}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Название</label>

              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder=''
                {...register('name', {required: true})}
              />

              {errors?.name?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='required fw-semibold fs-6 mb-2'>Расходы</label>
              <Controller
                control={control}
                rules={{required: true}}
                name='cost'
                render={({field: {value, ...fieldProps}}) => {
                  return (
                    <IMaskInput
                      {...fieldProps}
                      className='form-control form-control-lg form-control-solid'
                      mask={Number}
                      unmask={true}
                      radix={'.'}
                    />
                  );
                }}
              />

              {errors?.cost?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>
            <div className='d-flex flex-end pt-10'>
              <input
                type='submit'
                className={`btn fw-bold  btn-primary cursor-pointer `}
                value={'Сохранить'}
              />
            </div>
          </form>
          <CreateAppModal
            customWidth='mw-400px'
            padding='pb-lg-10 pt-lg-10 px-lg-10'
            show={warning}
            handleClose={() => setWarning(false)}
            showHeader={false}
            isMessage={true}
          >
            <Warning
              buttons={[
                {
                  action: () => {
                    setWarning(false);
                  },
                  title: <i className='bi bi-x p-0 fs-1'></i>,
                  class: 'btn-light',
                },
                {
                  action: () => {
                    setWarning(false);
                    setShowCreateAppModal(false);
                    reset();
                  },
                  title: <i className='bi bi-check p-0 fs-1'></i>,
                  class: 'btn-light-primary',
                },
              ]}
            />
          </CreateAppModal>
        </>
      </CreateAppModal>
    </>
  );
};

export default observer(AddTarget);
