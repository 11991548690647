/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import {useForm, Controller} from 'react-hook-form';
import {formateImage} from '../../../functions/formatImage';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';
import {Selector} from '../../../shared/Selectors';

const AddAChannel = ({bots, show, getChannels, warning, setWarning, isUpdate = false, id}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
  } = useForm();

  const [image, setImage] = useState('');
  const [optionsCountry, setOptionsCountry] = useState([]);

  useEffect(() => {
    (async () => {
      if (isUpdate) {
        const result = await store.channelsGetChannel(id);
        for (const [key, value] of Object.entries(result)) {
          setValue(key, value);
        }
        setValue('countries_id', result.country.id);
        formateImage(result.avatar, setImage);
      }

      const countryResult = await store.getCountries();
      const newOptions = countryResult.map((el) => {
        return {label: el.name, value: el.id};
      });
      setOptionsCountry(newOptions);
    })();
    formateImage('', setImage);
  }, []);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === 'avatar') {
        if (!!value.length && typeof value !== 'string') {
          formData.append(`${key}`, value[0]);
          continue;
        }
        continue;
      }
      formData.append(`${key}`, value);
    }

    // for (var value of formData.values()) {
    //   console.log(value);
    // }

    if (!isUpdate) {
      const result = await store.channelsAddChannel(formData);
      !!result &&
        toast.success(`Успешно сохранено!`, {
          onOpen: async () => {
            await getChannels();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка сохранения!`, {
          theme: 'colored',
        });
    } else {
      const result = await store.channelsUpdateChannel(formData, id);
      !!result &&
        toast.success(`Успешно обновлено!`, {
          onOpen: async () => {
            await getChannels();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка в обновлении!`, {
          theme: 'colored',
        });
    }
  };

  return (
    <>
      <form
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='fv-row mb-10 d-flex flex-column flex-start'>
          <label className='form-label'>Аватар</label>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              className='image-input-wrapper w-125px h-125px'
              style={{
                backgroundImage: image,
              }}
            ></div>

            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              aria-label='Change avatar'
              data-bs-original-title='Change avatar'
              data-kt-initialized='1'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' {...register('avatar')} />
              <input type='hidden' name='avatar_remove' />
            </label>
          </div>
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Название канала</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('name', {required: true})}
          />

          {errors?.name?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Telegram id</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('telegram_id', {required: true})}
          />

          {errors?.telegram_id?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Telegram link</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('telegram_link', {required: true})}
          />

          {errors?.telegram_link?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Публикация</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            {...register('publication', {required: true})}
          />

          {errors?.publication?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Страна</label>

          <Controller
            name='countries_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector options={optionsCountry} value={value} onChange={onChange} />
            )}
          />
          {errors?.countries_id?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Выберите Бота</label>

          <Controller
            name='bot_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector options={bots} value={value} onChange={onChange} />
            )}
          />

          {errors?.bot_id?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        {!isUpdate ? (
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='required fw-semibold fs-6 mb-2'>Выберите config</label>
            <select
              className='form-control form-control-lg form-control-solid form-select'
              {...register('config', {required: true})}
            >
              <option value=''></option>
              <option value='vip'>vip</option>
              <option value='main'>main</option>
            </select>
            {errors?.config?.type === 'required' && (
              <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
            )}
          </div>
        ) : null}
        <div className='d-flex flex-end pt-10'>
          <input
            type='submit'
            className={`btn fw-bold  btn-primary cursor-pointer `}
            value={'Сохранить'}
          />
        </div>

        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={warning}
          handleClose={() => setWarning(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            buttons={[
              {
                action: () => {
                  setWarning(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: () => {
                  setWarning(false);
                  show(false);
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </form>
    </>
  );
};

export default observer(AddAChannel);
