/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import TeamHeader from '../../../team/LTC/TeamHeader';
import Chart from '../../../../../widgets/Chart';
import Chart3 from '../../../../../widgets/Chart3';
import PageWrapper from '../../../../../modules/PageWrapper';
import {findKPILtc} from '../../../../../functions/findKPI';
import Traffic from './Traffic';
import Stages from "./Stages";

const Component = observer(({timeConfig, isVerify, setPreloader, page, setPage}) => {
  const {id} = useParams();
  const store = useStore();

  const [managerStats, setManagerStats] = useState({});
  const [geoQualifications, setGeoQualifications] = useState([]);
  const [qualificationSchedule, setQualificationSchedule] = useState({});

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const getManagerStats = await store.salesManagerLtcMainStats(id, tc);
    const LTCList = await store.salesGetKpiLtcList();
    const getGeoQualifications = await store.salesManagerLtcGeoQualifications(id, tc);
    const getQualificationSchedule = await store.salesManagerLtcQualificationSchedule(id);

    const appeal = +getManagerStats.statistics.count_appeal;
    const ltc = +getManagerStats.statistics.ltc;

    setManagerStats({
      ...getManagerStats,
      indicator: findKPILtc(LTCList, appeal, ltc),
    });
    setGeoQualifications(getGeoQualifications);
    setQualificationSchedule({graph: getQualificationSchedule});
    setPreloader(false);
  };

  return (
    <>
      <TeamHeader
        page={page}
        setPage={setPage}
        data={managerStats}
        isManager={true}
        links={[
          {link: 'statistics', title: 'Статистика'},
          {link: 'traffic', title: 'Трафик'},
          {link: 'stages', title: 'Этапы'},
        ]}
      />
      {page === 'statistics' && (
        <>
          <div className='row gx-5 gx-xl-10'>
            <div className='col-xl-7 mb-5 mb-xl-10 mt-0'>
              <Chart data={qualificationSchedule || {graph: []}} />
            </div>
            <div className='col-xl-5 mb-5 mb-xl-10'>
              <Chart3 countries={geoQualifications || []} title={'ГЕО квалификаций'} />
            </div>
          </div>
        </>
      )}
      {page === 'traffic' && <Traffic id={id} />}
      {page === 'stages' && <Stages id={id} workspace_id={managerStats.workspace_id} />}
    </>
  );
});

const ManagerLTC = () => {
  const [page, setPage] = useState('statistics');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const params = useParams();

  useEffect(() => {
    switch (page) {
      case 'statistics': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {
              title: `Менеджера LTC`,
              path: `/sales/ltc/managers`,
              isActive: true,
            },
            {isSeparator: true},
            {
              title: `Менеджер LTC #${params.id}`,
              path: `/sales/ltc/managers/${params.id}`,
              isActive: true,
            },
          ],
          title: `Менеджер LTC #${params.id}`,
        });
        break;
      }
    }
  }, [page]);

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs.br}
      title={breadcrumbs.title}
      firstTimeConfig='week_salles'
    >
      <Component page={page} setPage={setPage} />
    </PageWrapper>
  );
};

export default ManagerLTC;
