/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Button} from '../../shared/Buttons';
import {Input, Select} from '../../shared/Inputs';
import Lead from './Lead';
import Card from '../../shared/Card';
import PageWrapper from '../../modules/PageWrapper';
import {number} from 'yup';

const LeadsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Лиды', path: '/leads', isActive: true},
      ]}
      title={'Лиды'}
    >
      <Leads />
    </PageWrapper>
  );
};

const Leads = observer(({isVerify, setPreloader}) => {
  const store = useStore();
  const navigate = useNavigate();

  const [data, setData] = useState({type: 'platform_id'});
  const [bots, setBots] = useState([]);
  const [lead, setLead] = useState({});

  const handleChange = (e) => {
    const {name, value} = e.target;
    setData({...data, [name]: value});
  };

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const getBots = await store.botsGetListBots();
    const newBots = getBots.map((item) => {
      return <option value={`${item.id}`}>{item.name}</option>;
    });
    setData({...data, bot_id: !!getBots.length ? getBots[0]?.id : ''});
    setBots(newBots);
    setPreloader(false);
  };

  const search = async () => {
    let lead;

    if (data.type === 'telegram_id' && data.bot_id && data.id) {
      const searchTelegramId = await store.leadsSearchTelegramId(data.id, data.bot_id);
      lead = searchTelegramId;
    }
    if (data.type === 'lead_id' && data.id) {
      const newData = {...data};
      delete newData.bot_id;
      const searchLeadId = await store.leadsSearchLeadId(newData.id);
      lead = searchLeadId;
    }
    if (data.type === 'platform_id' && data.id) {
      const newData = {...data};
      delete newData.bot_id;
      const searchPlatformId = await store.leadsSearchPlatformId(newData.id);
      lead = searchPlatformId;
    }

    if (!lead || typeof lead.lead_id !== 'number') {
      notify2('Лида с таким id не существует!');
      return store.setPreloader();
    }
    navigate(`/leads/${lead.lead_id}`);
  };

  const notify2 = (message) => {
    return toast.error(message, {theme: 'colored'});
  };

  return (
    <>
      <Card className='card card-xl-stretch mb-xl-8'>
        <div className='card-header border-0 py-5 pb-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Поиск пользователя</span>
            <span className='text-muted fw-semibold fs-7'>
              Получите все данные лида по его Telegram ID, Platform ID или Lead ID, для проверки
              необходимых данных или действий с лидом.
            </span>
          </h3>
        </div>

        <div className='card-body d-flex flex-column'>
          <div className='col-lg-12'>
            <div className='row'>
              <div
                className={`${data.type === 'telegram_id' ? 'col-lg-3' : 'col-lg-5'} fv-row mb-7`}
              >
                <Input
                  text={'Введите id'}
                  name='id'
                  value={data?.id}
                  classText={'leads-input-1'}
                  handleChange={handleChange}
                />
              </div>
              {data.type === 'telegram_id' && (
                <div className='col-lg-4 fv-row mb-7'>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                    text={'Выберете бота'}
                    classText={'leads-select-1'}
                    name={'bot_id'}
                    value={data?.type}
                    handleChange={handleChange}
                    options={bots}
                  />
                </div>
              )}
              <div
                className={`${data.type === 'telegram_id' ? 'col-lg-3' : 'col-lg-5'} fv-row mb-7`}
              >
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '42px',
                    }),
                  }}
                  text={'Выберете тип поиска'}
                  classText={'leads-select-1'}
                  name={'type'}
                  value={data?.type}
                  handleChange={handleChange}
                  options={[
                    // <option></option>,
                    <option value='platform_id'>Platform Id</option>,
                    <option value='telegram_id'>Telegram Id</option>,
                    <option value='lead_id'>Lead Id</option>,
                  ]}
                />
              </div>
              <div className='col-lg-2 fv-row'>
                <Button
                  className='w-100 d-flex flex-nowrap align-items-center justify-content-center'
                  action={() => {
                    search();
                  }}
                >
                  <i className='bi bi-search'></i>
                  Поиск
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
});

export default observer(LeadsWrapper);
