/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {formateImage} from '../../../../../functions/formatImage';
import {Selector} from '../../../../../shared/Selectors';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {toast} from 'react-toastify';
import Warning from '../../../../../modules/Warning';

const AddBlogger = ({setWarning, warning, setSave, show, getBloggersList, type}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm();

  const [image, setImage] = useState('');

  const [optionsBayers, setOptionsBayers] = useState([]);
  const [valueBayer, setValueBayer] = useState({});
  const [optionsDepartments, setOptionsDepartments] = useState([]);
  const [valueDepartment, setValueDepartment] = useState({});

  useEffect(() => {
    (async () => {
      const getBayers = await store.trafficsInfluenceGetBayersList();
      const newBayers = getBayers.map((el) => {
        return {value: el.id, label: `${el.first_name} ${el.last_name}`};
      });
      setOptionsBayers(newBayers);

      // const getDepartments = await store.departamentsGetList(false);
      // const newDepartments = getDepartments.map((el) => {
      //   return {value: el.id, label: el.name};
      // });

      // setOptionsDepartments(newDepartments);
    })();
    formateImage('', setImage);
  }, []);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      departament_id: type === 'facebook' ? 3 : 4,
      bayer_id: Number(data.bayer_id),
    };
    const formData = new FormData();

    for (const [key, value] of Object.entries(newData)) {
      if (key === 'avatar') {
        formData.append(`${key}`, value[0]);
        continue;
      }
      formData.append(`${key}`, value);
    }

    // for (var value of formData.values()) {
    //   console.log(value);
    // }
    const result = await store.trafficsAddTraffic(formData, type);

    !!result &&
      toast.success(`Успешно сохранено!`, {
        onOpen: async () => {
          await getBloggersList();
          show(false);
        },
      });
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='fv-row mb-10 d-flex flex-column flex-start'>
        <label className='form-label'>Аватар</label>
        <div className='image-input image-input-outline' data-kt-image-input='true'>
          <div
            className='image-input-wrapper w-125px h-125px'
            style={{
              backgroundImage: image,
            }}
          ></div>

          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
            data-bs-toggle='tooltip'
            aria-label='Change avatar'
            data-bs-original-title='Change avatar'
            data-kt-initialized='1'
          >
            <i className='ki-duotone ki-pencil fs-7'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <input type='file' name='avatar' accept='.png, .jpg, .jpeg' {...register('avatar')} />
            <input type='hidden' name='avatar_remove' />
          </label>
        </div>
      </div>
      {/* <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Выберите департамент</label>

        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '42px',
            }),
          }}
          options={optionsDepartments}
          value={valueDepartment}
          setValue={setValueDepartment}
          actions={(e) => {
            handleChange({target: {name: 'departament_id', value: +e.value}});
          }}
        />

        {error.includes('departament_id') && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div> */}
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>{type === 'influence' ? 'Название блогера' : 'Название'}</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          placeholder=''
          {...register('name', {required: true})}
        />

        {errors?.name?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      {type === 'influence' && <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='fw-semibold fs-6 mb-2'>Ссылка на источник</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          placeholder=''
          {...register('source_link')}
        />
      </div>}
      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={'Сохранить'}
        />
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

export default observer(AddBlogger);
