/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Card from '../../../../shared/Card';
import Pagination from '../../../../shared/Pagination';
import InputSearch from '../../../../shared/InputSearch';
import { formatNumberToCash } from '../../../../functions/formatNumber';
import PaginationBe from '../../../../shared/PaginationBe';
import Preloader from '../../../../modules/Preloader';

const AttachmentBloggerList = ({
  data,
  searchData,
  title,
  limit,
  setLimit,
  offset,
  setOffset,
  setSearchData,
  setQ,
  timeConfig,
  setConfig,
  total,
  localPreloader
}) => {
  const store = useStore();
  const searchDebounceRef = useRef(null);
  useEffect(() => {
    setConfig(timeConfig);
    setOffset(1);
  }, [timeConfig])
  const onSearch = (e) => {
    const query = e.target.value;

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }

    setSearchData(query);

    searchDebounceRef.current = setTimeout(() => {
      if (query) {
        setQ(query);
      } else {
        setQ(undefined);
        setSearchData(undefined);
      }
      setOffset(1);
    }, 1000);
  };
  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5 justify-content-start' style={{minHeight: '50px'}}>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px p-4'
            placeholder='Поиск'
            value={searchData}
            onChange={onSearch}
          />
        </div>
        {!data.length && <div className='mb-4' />}

        {!!data.length && (<div className='card-body py-3'>
          <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>ID</th>
                    <th className='min-w-140px'>Название</th>
                    <th className='min-w-120px'>Крайняя публикация</th>
                    {!store.roles.includes(19) && <th className='min-w-120px'>Прибыль</th>}
                    {!store.roles.includes(19) && <th className='min-w-120px'>Доходы</th>}
                    {!store.roles.includes(19) && <th className='min-w-120px'>Расходы</th>}
                    <th className='min-w-120px'>ROMI</th>
                    <th className='text-end'></th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-semibold'>
                  {data.map((el, index) => {
                    return (
                      <tr key={el?.id}>
                        <td>
                          <span>#{el?.id}</span>
                        </td>
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {el?.name}
                          </span>
                        </td>

                        <td>
                          <span>{moment(el?.last_link_created * 1000).format('ll')}</span>
                        </td>
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.profit)}
                            </span>
                          </td>
                        )}
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.revenue)}
                            </span>
                          </td>
                        )}
                        {!store.roles.includes(19) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.cost)}
                            </span>
                          </td>
                        )}
                        <td>
                          <span className={`badge badge-light-${el?.indicator?.indicator}`}>
                            {el?.romi}%
                          </span>
                        </td>
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link to={`/traffics/influence/bloggers/${el.id}`}>
                              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <i className='ki-duotone ki-switch fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
          {data.length && (
            <PaginationBe
              arrLength={total}
              limit={limit}
              setPage={setOffset}
              page={offset}
              action={(currentPage) => {}}
            />
          )}
        </div>
        )}
        {localPreloader && <Preloader style={{border: '0'}} />}
      </Card>
    </>
  );
};

export default observer(AttachmentBloggerList);
