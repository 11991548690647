const findKPILtc = (kpiArray, countAppeal, ltc) => {
  for (const range of kpiArray) {
    if (countAppeal >= range.count_lead_min && countAppeal <= range.count_lead_max) {
      // Используем reverse итерацию, так как предполагаем, что массив KPI отсортирован по возрастанию LTC.
      // Таким образом, первый найденный KPI, который меньше или равен ltc, будет лучшим совпадением.
      const bestMatch = range.kpi
        .slice()
        .reverse()
        .find((kpi) => {
          return ltc >= kpi.LTC;
        });
      return bestMatch || kpiArray[0].kpi[0];
    }
  }
  return kpiArray[0].kpi[0]; // Значение по умолчанию, если совпадение не найдено
};

const findKPIRd = (kpiArray, count_qualifications, activity, rd, type) => {
  let result = {
    name: 'Плохой результат',
    indicator: 'danger',
  };
  kpiArray.forEach((item) => {
    let count_lead_min = item.count_lead_min;
    let count_lead_max = item.count_lead_max;
    if (!count_lead_min) count_lead_min = 0;
    if (!count_lead_max) count_lead_max = count_lead_max + count_qualifications + 10; //умовно створюємо більше значення (для порівняння)

    if (count_qualifications >= count_lead_min && count_qualifications <= count_lead_max) {
      item[type].forEach((el) => {
        if (activity >= el.activity_min && activity <= el.activity_max) {
          const bestMatch = el.kpi
            .slice()
            .reverse()
            .find((kpi) => {
              return rd >= kpi.RD;
            });

          result = bestMatch;
        }
      });
    }
  });

  return result;
};

export {findKPILtc, findKPIRd};
