/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {useLocation, useParams} from 'react-router-dom';
import BloggerHeader from '../../influence/bloggers/BloggersHeader/BloggerHeader';
import AddSource from './AddSource';
import {formatNumber2} from '../../../../functions/formatNumber';
import Statistics from '../../influence/bloggers/Board';
import Sources from './Sources';
import Settings from '../../influence/bloggers/Settings';
import Conversions from './Conversions';

import PageWrapper from '../../../../modules/PageWrapper';
import Analyze from './Analyze';

export const Company = observer(({timeConfig, isVerify, setPreloader, page, setPage, id}) => {
  const store = useStore();

  const [data, setData] = useState({});
  const [links, setLinks] = useState({});
  const [romiList, setRomiList] = useState([]);
  const [conversions, setConversions] = useState({});

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const [getTrafficStats, getConversions, getRomiList] = await Promise.all([
      store.trafficsFacebookGetTrafficStats(id, tc),
      store.trafficsFacebookGetTrafficConversions(id, tc),
      store.financeCategoryGetRomiList(),
    ]);
    const romi = Math.round(+getTrafficStats.romi);
    const indicator = getRomiList.find((el) => el.romi_min <= romi && el.romi_max >= romi);

    setConversions(getConversions);
    setRomiList(getRomiList);
    getInfluence(getRomiList, tc);
    setData({...getTrafficStats, indicator});
  };

  const getInfluence = async (getRomiList, timeConfig) => {
    const getLinks = await store.trafficsFacebookGetLinks({
      id,
      timeConfig,
      page: 1,
      perPage: 10,
      sort: '',
      q: '',
    });

    const rows = getLinks.rows.map((el) => {
      const romi = Math.round(+el.romi);
      const indicator = getRomiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);

      return {...el, indicator};
    });

    setLinks({...getLinks, rows});
    setPreloader(false);
  };

  const truncateString = (str) => {
    if (str.length > 19) {
      return str.slice(0, 19) + '...';
    } else {
      return str;
    }
  };

  const updateData = (newData) => {
    if(data) {
      setData({...data, name: newData.name, source_link: newData.source_link, avatar: newData.avatar});
    }
  }

  return (
    <>
      <BloggerHeader
        page={page}
        setPage={setPage}
        data={data}
        links={
          store.roles.includes(6) || store.roles.includes(13) || store.roles.includes(32)
            ? [
                {link: 'statistics', title: 'Общая статистика'},
                {link: 'advertisements', title: 'Сорсы'},
                {link: 'analyze', title: 'Окупаемость'},
              ]
            : [
                {link: 'statistics', title: 'Общая статистика'},
                {link: 'advertisements', title: 'Сорсы'},
                {link: 'settings', title: 'Настройки'},
                {link: 'analyze', title: 'Окупаемость'},
              ]
        }
        actionComponents={[
          store.roles.includes(13) || store.roles.includes(32) ? <></> : <AddSource />,
        ]}
      />
      {page === 'statistics' && (
        <>
          <Statistics data={data} />
          <Conversions conversions={conversions} />
        </>
      )}
      {page === 'advertisements' && (
        <Sources
          links={links}
          romiList={romiList}
          timeConfig={timeConfig}
          id={id}
          title={'Сорсы'}
          subTitle={'Сорсы не были еще созданы...'}
        />
      )}
      {page === 'settings' && <Settings data={data} type='company' updateData={updateData} />}
      {page === 'analyze' && <Analyze id={id} tc={timeConfig} />}
    </>
  );
});

const CompanyWrapper = () => {
  const [page, setPage] = useState('statistics');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const location = useLocation();
  const {id} = useParams();

  useEffect(() => {
    switch (page) {
      case 'statistics': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Фейсбук Трафик', path: `/traffics/facebook/companies`},
            {isSeparator: true},
            {
              title: 'Общая статистика',
              path: `/traffics/facebook/companies/${id}`,
              isActive: true,
            },
          ],
          title: `Кампания #${id}`,
        });
        break;
      }
      case 'advertisements': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Фейсбук Трафик', path: `/traffics/facebook/companies`},
            {isSeparator: true},
            {title: 'Сорсы', path: `/traffics/facebook/companies/${id}`, isActive: true},
          ],
          title: `Кампания #${id}`,
        });
        break;
      }
      case 'settings': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Фейсбук Трафик', path: `/traffics/facebook/companies`},
            {isSeparator: true},
            {title: 'Настройки', path: `/traffics/facebook/companies/${id}`, isActive: true},
          ],
          title: `Кампания #${id}`,
        });
        break;
      }
    }
  }, [page]);

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs.br}
      title={breadcrumbs.title}
      firstTimeConfig='current_month'
    >
      <Company page={page} setPage={setPage} id={id} />
    </PageWrapper>
  );
};

export default CompanyWrapper;
