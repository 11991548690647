/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {Link, useParams} from 'react-router-dom';
import PageWrapper from '../../../../modules/PageWrapper';
import Card from '../../../../shared/Card';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import moment from 'moment';
import { Button } from '../../../../shared/Buttons';
import { formatNumberToCash, formatNumber } from '../../../../functions/formatNumber';
import Navigation from '../../../../modules/NavigationBloggers';
import AttachmentBloggerList from './BloggerList';
import ButtonExelWithLoadOnClick from '../../../../modules/ButtonExelWithLoadOnClick';

const Bayer = observer(({timeConfig, isVerify, setPreloader, id, page, setPage, links}) => {
  const store = useStore();

  const [data, setData] = useState({});

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const getTrafficStats = await store.trafficsInfluenceBayerMainStats(id, timeConfig);
    const getRomiList = await store.financeCategoryGetRomiList();

    const indicator = getRomiList.find(
      (item) =>
        getTrafficStats.statistics.romi >= +item.romi_min &&
        getTrafficStats.statistics.romi <= +item.romi_max
    );

    setData({...getTrafficStats, indicator});
    setPreloader(false);
  };

  return (
    <>
      <Card className='card mb-8'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug
                  }
                  alt=''
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {`${data?.first_name} ${data?.last_name}`}
                    </span>
                    <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                      {data?.statistics?.romi}%
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                    #{data?.id}
                  </div>
                </div>

                <div className='d-flex mb-4'>
                  <Link to={`/users/list/user/${data.id}/info`}>
                    <Button>Карточка сотрудника</Button>
                  </Link>
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bold'>
                        {moment(new Date(data?.created_at * 1000)).format('ll')}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumberToCash(data?.statistics?.profit)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Прибыль</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumberToCash(data?.statistics?.revenue)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Доход</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumber(data?.statistics?.count_registrations)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Регистрации</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumber(data?.statistics?.count_qualifications)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Квалификации</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navigation page={page} setPage={setPage} links={links} />
        </div>
      </Card>
    </>
  );
});

const BayerWrapper = observer(({timeConfig, timeStart, timeEnd}) => {
  const {id} = useParams();
  const store = useStore();

  const [page, setPage] = useState('bloggers');
  const [data, setData] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [q, setQ] = useState()
  const [config, setConfig] = useState();
  const [total, setTotal] = useState(0)
  const [localPreloader, setLocalPreloader] = useState(false);

  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = async (config, q, offset) => {
    setLocalPreloader(true)
    const getTrafficStats = await store.trafficsInfluenceBayerGetTrafficsList({id, q, page: offset, timeConfig: config});
    const getRomiList = await store.financeCategoryGetRomiListNoPreloader();
    const newList = getTrafficStats.rows.map((item) => {
      const romi = Math.round(+item.romi);
      const indicator = getRomiList.find(
        (item) => romi >= +item.romi_min && romi <= +item.romi_max
      );
      return {
        ...item,
        indicator,
      };
    });

    setData(newList);
    setTotal(getTrafficStats.total)
    setLocalPreloader(false)
  };

  useEffect(() => {
    if(config?.time_config.length > 0){
      getData(config, q, offset)
    }
  }, [config, offset, q]);

  const loadData = async () => {
    const getListForExel = await store.trafficsInfluenceBayerGetTrafficsListForExel({id, timeConfig: config, q});
    const formattedForExel = getListForExel.data.map((item) => ({
      ...item,
      created_at: moment(item?.created_at * 1000).format('DD.MM.YYYY HH:mm '),
      last_link_created: moment(item?.last_link_created * 1000).format('DD.MM.YYYY HH:mm '),
      profit: `${item?.profit}$`,
      cost: `${item?.cost}$`,
      revenue: `${item?.revenue}$`,
      romi: `${item?.romi}%`,
    }))
    return {data:formattedForExel, time:getListForExel.time}
  }

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {title: 'Influence', path: `/traffics/influence/bayers`},
        {isSeparator: true},
        {title: `Байер #${id}`, path: `/traffics/influence/bayers/${id}`, isActive: true},
      ]}
      title={`Байер Influence #${id}`}
      firstTimeConfig='current_month'
      buttonKPI={
        <ButtonExelWithLoadOnClick
          fieldsToKeep={[
            'id',
            'bayer_id',
            'name',
            'created_at',
            'last_link_created',
            'cost',
            'profit',
            'revenue',
            'romi',
          ]}
          fileName={'get-bloggers-by-bayer'}
          loadData={loadData}
        />
      }
    >
      <Bayer
        timeConfig={timeConfig}
        timeStart={timeStart}
        timeEnd={timeEnd}
        id={id}
        page={page}
        setPage={setPage}
        links={[{link: 'bloggers', title: 'Блогеры'}]}
      />
      {page === 'bloggers' && (
        <AttachmentBloggerList
          data={data}
          searchData={searchData}
          title={'Блогеры'}
          limit={limit}
          setLimit={setLimit}
          offset={offset}
          setOffset={setOffset}
          setSearchData={setSearchData}
          setQ={setQ}
          timeConfig={timeConfig}
          setConfig={setConfig}
          total={total}
          localPreloader={localPreloader}
        />
      )}
    </PageWrapper>
  );
});

export default BayerWrapper;
