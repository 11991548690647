/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../store/store';

const Preloader = ({style}) => {
  const store = useStore();

  const [active, setActive] = useState(false);

  return (
    <div
      className={'card'}
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '20',
        ...style,
      }}
    >
      <div
        className='app-page-loader'
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.625rem',
          zIndex: '10',
        }}
      >
        <span className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </span>
      </div>
    </div>
  );
};

export default observer(Preloader);
