/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import PageWrapper from '../../../../modules/PageWrapper';
import CompaniesList from '../../influence/bloggers/list/CompaniesList';
import moment from 'moment/moment';

const ListWrapper = () => {
  const store = useStore();
  const [selectedBayer, setSelectedBayer] = useState();
  const [queryText, setQueryText] = useState();
  const [localPreloader, setLocalPreloader] = useState(false);
  const [bloggersList, setBloggersList] = useState([]);
  const [romiList, setRomiList] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [timeConfig, setTimeConfig] = useState({});

  const updateList = async (page, selectedBayer, q, timeConfig) => {
    if(selectedBayer || q || page) {
      setLocalPreloader(true)
      setBloggersList([])
      const getBloggers = await store.trafficsFacebookGetTrafficsList({page, selectedBayer, queryText: q, timeConfig});
      const getRomiList = await store.financeCategoryGetRomiListNoPreloader();
      const newBloggers = getBloggers.rows.
      map((el) => {
        const romi = Math.round(+el.romi);
        const indicator = getRomiList.find(
          (item) => romi >= +item.romi_min && romi <= +item.romi_max
        );
        return {...el, indicator};
      });
      setBloggersList(newBloggers);
      setRomiList(getRomiList)
      setLocalPreloader(false)
      setTotalCount(getBloggers.total)
    }
  };

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Фейсбук Трафик', path: '/traffics/facebook/companies', isActive: true},
      ]}
      title={'Фейсбук Трафик'}
    >
      <List
        updateList={updateList}
        selectedBayer={selectedBayer}
        setSelectedBayer={setSelectedBayer}
        romiList={romiList}
        setRomiList={setRomiList}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
        setQueryText={setQueryText}
        queryText={queryText}
        bloggersList={bloggersList}
        setBloggersList={setBloggersList}
        localPreloader={localPreloader}
        setLocalPreloader={setLocalPreloader}
        setTimeConfig={setTimeConfig}
      />
    </PageWrapper>
  );
};

const List = observer(({
   timeConfig,
   isVerify,
   setPreloader,
   updateList,
   selectedBayer,
   setSelectedBayer,
   romiList,
   setRomiList,
   setTotalCount,
   totalCount,
   setQueryText,
   queryText,
   bloggersList,
   setBloggersList,
   localPreloader,
   setLocalPreloader,
   setTimeConfig
}) => {
  const store = useStore();

  useEffect(() => {
    isVerify && setPreloader(false);
  }, [isVerify]);

  useEffect(() => {
    setTimeConfig(timeConfig)
  }, [timeConfig]);

  // useEffect(() => {
  //   setTimeConfig(timeConfig)
  // }, [timeConfig]);
  //
  // const [bloggers, setBloggers] = useState([]);
  //
  // useEffect(() => {
  //   isVerify && !!timeConfig.time_config && getBloggersList();
  // }, [isVerify, timeConfig]);
  //
  // const getBloggersList = async () => {
  //   const [getBloggers, getRomiList] = await Promise.all([
  //     store.trafficsFacebookGetTrafficsList(timeConfig),
  //     store.financeCategoryGetRomiList(),
  //   ]);
  //
  //   const newBloggers = getBloggers.map((el) => {
  //     const romi = Math.round(+el.romi);
  //     const indicator = getRomiList.find(
  //       (item) => romi >= +item.romi_min && romi <= +item.romi_max
  //     );
  //     return {...el, indicator};
  //   });
  //
  //   setBloggers(newBloggers);
  //   setPreloader(false);
  // };

  return (
    <>
      <CompaniesList
        type={'facebook'}
        title={'Кампании'}
        timeConfig={timeConfig}
        updateList={updateList}
        selectedBayer={selectedBayer}
        setSelectedBayer={setSelectedBayer}
        romiList={romiList}
        setRomiList={setRomiList}
        queryText={queryText}
        setQueryText={setQueryText}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
        bloggersList={bloggersList}
        setBloggersList={setBloggersList}
        localPreloader={localPreloader}
        setLocalPreloader={setLocalPreloader}
      />
    </>
  );
});

export default ListWrapper;
