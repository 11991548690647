/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import BlockTitles from './templates/BlockTitles';
import BlockLine from './templates/BlockLine';
import {SelectorWithSearch, SelectorIsMulti} from '../../shared/Selectors';

const NameForm = ({
  data,
  setData,
  templatesNames,
  advertisersOptions,
  offersOptions,
  countries,
  errors,
}) => {
  return (
    <div className={css.nameForm}>
      <BlockTitles
        title={'Настройки'}
        description={'Настройте основные системные настройки для своего PWA'}
      />
      <BlockLine />
      <label htmlFor='' className={css.inputField} style={{marginTop: '30px'}}>
        <span>Название</span>
        <input
          type='text'
          value={data.name || ''}
          onChange={(e) =>
            setData({
              ...data,
              name: e.target.value,
            })
          }
          style={errors.includes('name') ? {border: '2px solid red', outline: 'none'} : {}}
        />
      </label>
      <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
        <span style={{display: 'inline-block', marginBottom: '8px'}}>Рекламодатель</span>
        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '45px',
              border: errors.includes('advertiser_id') && '2px solid red !important',
              boxShadow: errors.includes('advertiser_id') && 'none',
            }),
          }}
          placeholder=''
          options={advertisersOptions}
          value={advertisersOptions.find((item) => item.value === data.advertiser_id)}
          setValue={(e) =>
            setData({
              ...data,
              advertiser_id: e.value,
            })
          }
          actions={() => {}}
        />
      </label>
      {data.advertiser_id !== undefined && (
        <>
          <>
            <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>Основной оффер</span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: 'fit-content',
                    minHeight: '45px',
                    border: errors.includes('offer_id') && '2px solid red !important',
                    boxShadow: errors.includes('offer_id') && 'none',
                  }),
                }}
                placeholder=''
                options={offersOptions}
                value={offersOptions?.find((item) => item.value === data.offer_id)}
                setValue={(e) => {
                  setData({
                    ...data,
                    offer_id: e.value,
                  });
                }}
              />
            </label>

            <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>Дополнительные офферы</span>
              <SelectorIsMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: 'fit-content',
                    minHeight: '45px',
                    border: errors.includes('offers') && '2px solid red !important',
                    boxShadow: errors.includes('offers') && 'none',
                  }),
                }}
                placeholder=''
                options={offersOptions}
                value={data?.offers?.map((item) => {
                  return {value: item.value, label: item.label};
                })}
                onChange={(e) => {
                  const newOffers = [];
                  e.forEach((item) => {
                    const find = data?.offers?.find((el) => el.value === item.value);
                    if (!!find) {
                      newOffers.push(find);
                    } else {
                      newOffers.push({...item, country_id: null});
                    }
                  });
                  setData({...data, offers: newOffers});
                }}
              />
            </label>
            {!!data.offers &&
              data?.offers.map((item, index) => {
                return (
                  <label htmlFor='' style={{width: '100%', marginBottom: '16px'}} key={item.value}>
                    <span style={{display: 'inline-block', marginBottom: '8px'}}>
                      Страна для {item?.label}
                    </span>
                    <SelectorWithSearch
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: 'fit-content',
                          minHeight: '45px',
                          border: errors.includes(item.value) && '2px solid red !important',
                          boxShadow: errors.includes(item.value) && 'none',
                        }),
                      }}
                      placeholder='Поиск...'
                      options={countries}
                      value={countries.find((country) => country.value === item.country_id)}
                      setValue={(e) => {
                        const newData = {...data};
                        newData.offers[index].country_id = e.value;
                        setData(newData);
                      }}
                    />
                  </label>
                );
              })}
          </>
        </>
      )}
      <label htmlFor='' style={{width: '100%'}}>
        <span style={{display: 'inline-block', marginBottom: '8px'}}>Шаблон</span>
        <div className={css.doubleFields}>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors.includes('template_id') && '2px solid red !important',
                boxShadow: errors.includes('template_id') && 'none',
              }),
              container: (baseStyles, state) => ({
                ...baseStyles,
                width: '100%',
              }),
            }}
            placeholder=''
            options={templatesNames}
            value={data.template}
            setValue={(e) => setData({...data, template: e})}
          />
        </div>
      </label>
    </div>
  );
};

export default NameForm;
