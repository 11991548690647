/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import numeral from 'numeral';
import {toast} from 'react-toastify';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import Statistics, {Item} from '../../widgets/Statistics';
import Chart from '../../widgets/Chart';
import Chart3 from '../../widgets/Chart3';
import Departments from '../../widgets/Departments';
import PageWrapper from '../../modules/PageWrapper';
import { formatNumber } from '../../functions/formatNumber';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();

  const [mainStats, setMainStats] = useState({});
  const [qualificationSchedule, setQualificationSchedule] = useState({});
  const [geoQualifications, setGeoQualifications] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const tc = timeConfig;
    const [getMainStats, getDepartments,getGeoQualifications] =
      await Promise.all([
        store.getMainStats(tc),
        store.departamentsGetList(),
        store.getGeoQualifications(tc),
      ]);

    setMainStats(getMainStats);

    if (!Object.keys(qualificationSchedule).length) {
      const getQualificationSchedule = await store.getQualificationSchedule({
        time_config: null,
      });

      if (!getQualificationSchedule) {
        return notify('Ошибка токена!');
      }

      setQualificationSchedule({
        graph: getQualificationSchedule?.graph,
        count: formatNumber(getQualificationSchedule?.total_count),
      });
    }

    setGeoQualifications(getGeoQualifications);
    setDepartments(getDepartments);
    setPreloader(false);
  };

  const notify = (message) => {
    return toast.error(message, {onClose: () => store.signOut(), theme: 'colored'});
  };

  return (
    <>
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-5 mb-xl-10 mt-0'>
          <Statistics
            title={'Общая статистика'}
            subTitle={'Выручка'}
            state={`$${formatNumber(mainStats?.revenue)}` || `$${0}`}
          >
            <Item
              title='Лиды'
              subTitle='Кол-во лидов'
              icon={
                <i className='ki-duotone ki-fingerprint-scanning fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                  <span className='path5'></span>
                </i>
              }
              stats={formatNumber(mainStats?.leads?.count) || 0}
              indicator={mainStats?.leads?.indicator || 'up'}
            />
            <Item
              title='Регистрации'
              subTitle='Кол-во регистраций'
              icon={
                <i className='ki-duotone ki-user-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              }
              stats={formatNumber(mainStats?.registration?.count) || 0}
              indicator={mainStats?.registration?.indicator || 'up'}
            />
            <Item
              title='Депозиты'
              subTitle='Кол-во депозитов'
              icon={
                <i className='ki-duotone ki-wallet fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
              }
              stats={formatNumber(mainStats?.deposits?.count) || 0}
              indicator={mainStats?.deposits?.indicator || 'up'}
            />
            <Item
              title='Сумма депозитов'
              subTitle='Сумма депозитов'
              icon={
                <i className='ki-duotone ki-dollar fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              }
              stats={`${formatNumber(mainStats?.amount_deposits?.amount)}$` || `$${0}`}
              indicator={mainStats?.amount_deposits?.indicator || 'up'}
            />
            <Item
              title='Квалификации'
              subTitle='Кол-во квалификаций'
              icon={
                <i className='ki-duotone ki-user-tick fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              }
              stats={formatNumber(mainStats?.qualifications?.count) || 0}
              indicator={mainStats?.qualifications?.indicator || 'up'}
            />
          </Statistics>
        </div>
        <div className='col-xl-7 mb-5 mb-xl-10 mt-xl-0'>
          <Chart data={qualificationSchedule} />
        </div>
      </div>
      <div className='row gx-5 gx-xl-10'>
        <div className='col-xl-5 mb-5 mb-xl-10'>
          <Chart3 countries={geoQualifications || []} title={'ГЕО квалификаций'} />
        </div>
        <div className='col-xl-7 mb-5 mb-xl-10 mt-0'>
          <Departments data={departments} />
        </div>
      </div>
    </>
  );
});

const MainPanel = () => {
  return (
    <>
      <PageWrapper
        firstTimeConfig='current_month'
        breadcrumbs={[
          {title: 'PanelHunt', path: ''},
          {isSeparator: true},
          {title: 'Панель', path: '/dashboard/main', isActive: true},
        ]}
        title={'Основная панель'}
      >
        <DashboardPage />
      </PageWrapper>
    </>
  );
};

export default MainPanel;
