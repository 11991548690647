/* eslint-disable no-duplicate-case */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {useForm, Controller} from 'react-hook-form';
import {IMaskInput} from 'react-imask';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Stepper from '../../../modules/Stepper';
import {formateImage} from '../../../functions/formatImage';
import {Selector} from '../../../shared/Selectors';
import PageWrapper from '../../../modules/PageWrapper';

const AddUser = () => {
  const {id} = useParams();

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Сотрудники', path: '/users/list', isActive: true},
        {isSeparator: true},
        {
          title: 'Добавление Сотрудника',
          path: 'users/list/addUser',
          isActive: true,
        },
      ]}
      title={'Добавление Сотрудника'}
    >
      <Component />
    </PageWrapper>
  );
};

const links = [
  {title: 'Общие данные', link: 'common'},
  {title: 'Должность', link: 'position'},
];

const Component = ({isVerify, setPreloader}) => {
  const store = useStore();
  const [page, setPage] = useState('common');

  const [data, setData] = useState({});
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (!isVerify) return;
    (async () => {
      const getCountries = await store.getCountries();
      const getDepartments = await store.departamentsGetList();
      const getRoles = await store.usersRolesGetList();

      setCountries(
        getCountries.map((el) => {
          return {label: el.name, value: el.id};
        })
      );
      setDepartments(
        getDepartments.map((el) => {
          return {label: el.name, value: el.id};
        })
      );
      setRoles(
        getRoles.map((el) => {
          return {label: el.name, value: el.id};
        })
      );
      setPreloader(false);
    })();
  }, [isVerify]);

  return (
    <div className='card'>
      <div className='card-body'>
        <Stepper links={links} page={page} />
        <div className='mx-auto mw-600px w-100 pt-15 pb-10'>
          {page === 'common' && (
            <Common setData={setData} data={data} setPage={setPage} countries={countries} />
          )}
          {page === 'position' && (
            <Position
              setData={setData}
              data={data}
              setPage={setPage}
              departments={departments}
              roles={roles}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Common = ({setData, data, setPage, countries}) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm();

  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    formateImage('', setImage);
  }, []);

  useEffect(() => {
    if (!Object.keys(data).length) return;
    formateImage(data?.avatar[0], setImage);
    !!data.first_name && setValue('first_name', data.first_name);
    !!data.last_name && setValue('last_name', data.last_name);
    !!data.email && setValue('email', data.email);
    !!data.phone && setValue('phone', data.phone);
    !!data.telegram_id && setValue('telegram_id', data.telegram_id);
    !!data.telegram_username && setValue('telegram_username', data.telegram_username);
    !!data.date_birth && setValue('date_birth', data.date_birth);
    !!data.countries_id && setValue('countries_id', data.countries_id);
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
      if (name === 'phone' && type === 'change') {
        setValue(name, value[name].replace(/\D/g, ''));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (params) => {
    if (!data.avatar) return setImageError(true);
    // console.log({
    //   ...data,
    //   ...params,
    // });
    setData({
      ...data,
      ...params,
    });
    setPage('position');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title title={'Общие данные'} subTitle={'Вводите данные на кирилице и внимательно!'} />
      <div className='fv-row mb-10 d-flex flex-column flex-start'>
        <label className='form-label'>Аватар</label>
        <div className='image-input image-input-outline' data-kt-image-input='true'>
          <div
            className='image-input-wrapper w-125px h-125px'
            style={{
              backgroundImage: image,
            }}
          ></div>

          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
            data-bs-toggle='tooltip'
            aria-label='Change avatar'
            data-bs-original-title='Change avatar'
            data-kt-initialized='1'
          >
            <i className='ki-duotone ki-pencil fs-7'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>

            <input
              type='file'
              accept='.png, .jpg, .jpeg'
              // {...register('avatar', {required: true})}
              onChange={(e) => {
                setImageError(false);
                setData({...data, avatar: e.target.files});
              }}
            />
            <input type='hidden' name='avatar_remove' />
          </label>
        </div>
        {imageError && <div className='text-danger mt-2'>Вставте аватарку</div>}
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Имя</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='Иван'
            {...register('first_name', {required: true})}
          />
          {errors.first_name?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Фамилия</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='Иванов'
            {...register('last_name', {required: true})}
          />
          {errors.last_name?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Email</span>
          </label>

          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='email@example.com'
            {...register('email', {pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, required: true})}
          />
          {errors.email?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
          {errors.email?.type === 'pattern' && (
            <div className='text-danger mt-2'>Не верный формат email</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Номер телефона</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='380960000000'
            {...register('phone', {required: true})}
          />
          {errors.phone?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Telegram ID</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='123456789'
            {...register('telegram_id', {required: true})}
          />
          {errors.telegram_id?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Telegram UserName</span>
          </label>
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder='username'
            {...register('telegram_username', {required: true})}
          />
          {errors.telegram_username?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Дата рождения</span>
          </label>
          <Controller
            control={control}
            rules={{required: true}}
            name='date_birth'
            render={({field: {value, onChange, ...fieldProps}}) => {
              return (
                <IMaskInput
                  // {...fieldProps}
                  onAccept={onChange}
                  value={value}
                  className='form-control form-control-lg form-control-solid'
                  mask={Date}
                  pattern={'d.m.Y'}
                  unmask={true}
                  placeholder='01.02.1999'
                />
              );
            }}
          />
          {errors.date_birth?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Страна проживания</span>
          </label>
          <Controller
            name='countries_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                  }),
                }}
                options={countries}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.countries_id?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      <div className='d-flex flex-stack justify-content-end pt-10'>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Далее
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>
      </div>
    </form>
  );
};

const Position = observer(({setData, data, setPage, departments, roles}) => {
  const navigate = useNavigate();
  const store = useStore();
  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    resetField,
    formState: {errors},
    handleSubmit,
  } = useForm();

  const [teamsLtc, setTeamsLtc] = useState([]);
  const [teamsRd, setTeamsRd] = useState([]);
  const [teamsInfluence, setTeamsInfluence] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [manager, setManger] = useState('');
  const [bayer, setBayer] = useState('');

  useEffect(() => {
    !!data?.departament_id && setValue('departament_id', data?.departament_id);
    !!data?.role_id && setValue('role_id', data?.role_id);
    (data?.role_id === 15 || data?.role_id === 9) && setValue('sales_team_id', data?.sales_team_id);
    (data?.role_id === 15 || data?.role_id === 9) && setValue('workspace_id', data?.workspace_id);
    data?.role_id === 15 && getTeamsRd('rd');
    data?.role_id === 9 && getTeamsLtc('ltc');
    data?.role_id === 19 && getTeamsInfluence();
    data?.role_id === 15 && setManger('rd');
    data?.role_id === 9 && setManger('ltc');
    data?.role_id === 19 && setBayer('cost');
    data?.role_id === 19 && setValue('influence_team_id', data?.influence_team_id);
  }, [data]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'role_id' && type === 'change') {
        if (value[name] === 15 || value[name] === 9 || value[name] === 19) {
          resetField('sales_team_id');
          resetField('workspace_id');
          resetField('influence_team_id');

          value[name] === 15 && setManger('rd');
          value[name] === 15 && getTeamsRd();
          value[name] === 9 && setManger('ltc');
          value[name] === 9 && getTeamsLtc();
          value[name] === 19 && getTeamsInfluence();
          value[name] === 19 && setBayer('cost');
          getWorkspaces();
        } else {
          setManger('');
          setBayer('');
          resetField('sales_team_id');
          resetField('workspace_id');
          resetField('influence_team_id');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getTeamsRd = async () => {
    if (!!teamsRd.length) return;

    const result = await store.salesTeamsGetList('rd');

    const optionsTeams = result.map((el) => {
      return {label: `${el.teamleader.first_name} ${el.teamleader.last_name}`, value: el.id};
    });
    setTeamsRd(optionsTeams);
  };

  const getTeamsLtc = async () => {
    if (!!teamsLtc.length) return;

    const result = await store.salesTeamsGetList('ltc');
    const optionsTeams = result.map((el) => {
      return {label: `${el.teamleader.first_name} ${el.teamleader.last_name}`, value: el.id};
    });
    setTeamsLtc(optionsTeams);
  };

  const getTeamsInfluence = async () => {
    if (!!teamsInfluence.length) return;

    const result = await store.usersGetInfluenceTeamLeadList();
    const optionsTeams = result.map((el) => {
      return {label: `${el.first_name} ${el.last_name}`, value: el.id};
    });
    setTeamsInfluence(optionsTeams);
  }

  const getWorkspaces = async () => {
    const worksp = await store.CRMWorkspaces("sales");
    const optionsWorkspaces = worksp.map((el) => {
      return {label: `#${el.id} ${el.name}`, value: el.id};
    });
    setWorkspaces(optionsWorkspaces);
  };

  const formatDateToISO = (inputDate) => {
    const parts = inputDate.split('');
    const day = parts[0] + parts[1];
    const month = parts[2] + parts[3];
    const year = parts[4] + parts[5] + parts[6] + parts[7];

    return `${year}-${month}-${day}`;
  };

  const onSubmit = async (params) => {
    // console.log({...params, ...data})
    const formData = new FormData();

    for (const [key, value] of Object.entries({...params, ...data})) {
      if (!value) continue;

      if (key === 'date_birth') {
        formData.append(`${key}`, formatDateToISO(value));
        continue;
      }
      if (key === 'avatar') {
        formData.append(`${key}`, value[0]);
        continue;
      }
      formData.append(`${key}`, value);
    }

    // for (var value of formData.values()) {
    //   console.log(value);
    // }

    const result = await store.usersAddUsers(formData);
    !!result && toast.success(`Успешно сохранено!`, {});
    !!result && navigate(`/users/list/user/${result?.id}/info`);
    !result &&
      toast.error(`Произошла ошибка сохранения!`, {
        theme: 'colored',
      });
  };

  const prevPage = () => {
    const params = getValues();
    setData({
      ...data,
      ...params,
    });
    setPage('common');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title title={'Должность'} subTitle={''} />
      <div className='row'>
        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Департамент</span>
          </label>
          <Controller
            name='departament_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                  }),
                }}
                options={departments}
                value={value}
                onChange={onChange}
                placeholder={'Выберете департамент'}
              />
            )}
          />
          {errors.departament_id?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>

        <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='form-label'>
            <span className='required'>Роль</span>
          </label>
          <Controller
            name='role_id'
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value, name, ref}}) => (
              <Selector
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                  }),
                }}
                options={roles}
                value={value}
                onChange={onChange}
                placeholder={'Выберете роль'}
              />
            )}
          />
          {errors.role_id?.type === 'required' && (
            <div className='text-danger mt-2'>Поле обязательно для заполнения</div>
          )}
        </div>
      </div>
      {manager === 'ltc' && (
        <div className='row'>
          <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
            <label className='form-label'>
              <span>Команда</span>
            </label>
            <Controller
              name='sales_team_id'
              control={control}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={teamsLtc}
                  value={value}
                  onChange={onChange}
                  placeholder={'Выберете команду'}
                />
              )}
            />
          </div>

          <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
            <label className='form-label'>
              <span>Workspace</span>
            </label>
            <Controller
              name='workspace_id'
              control={control}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={workspaces}
                  value={value}
                  onChange={onChange}
                  placeholder={'Выберете workspace'}
                />
              )}
            />
          </div>
        </div>
      )}
      {bayer === 'cost' && (
        <div className='row'>
          <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
            <label className='form-label'>
              <span>Команда</span>
            </label>
            <Controller
              name='influence_team_id'
              control={control}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={teamsInfluence}
                  value={value}
                  onChange={onChange}
                  placeholder={'Выберете команду'}
                />
              )}
            />
          </div>
        </div>
      )}
      {manager === 'rd' && (
        <div className='row'>
          <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
            <label className='form-label'>
              <span>Команда</span>
            </label>
            <Controller
              name='sales_team_id'
              control={control}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={teamsRd}
                  value={value}
                  onChange={onChange}
                  placeholder={'Выберете команду'}
                />
              )}
            />
          </div>
          <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
            <label className='form-label'>
              <span>Workspace</span>
            </label>
            <Controller
              name='workspace_id'
              control={control}
              render={({field: {onChange, value, name, ref}}) => (
                <Selector
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  options={workspaces}
                  value={value}
                  onChange={onChange}
                  placeholder={'Выберете workspace'}
                />
              )}
            />
          </div>
        </div>
      )}
      <div className='d-flex flex-stack justify-content-between pt-10'>
        <Button
          action={() => {
            prevPage();
          }}
          color='btn-light-primary'
        >
          <i className='ki-duotone ki-arrow-left fs-3 me-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
          Назад
        </Button>
        <div>
          <button className={`btn fw-bold  btn-primary cursor-pointer `} type='submit'>
            Сохранить
          </button>
        </div>
      </div>
    </form>
  );
});

const Title = ({title, subTitle}) => {
  return (
    <div className='pb-10 pb-lg-15'>
      <h2 className='fw-bolder d-flex align-items-center text-gray-900'>{title}</h2>
      {!!subTitle && <div className='text-gray-500 fw-bold fs-6'>{subTitle}</div>}
    </div>
  );
};

export default AddUser;
