/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import {Button} from '../../../shared/Buttons';
import css from '../../../../styles/Accounts.module.scss';
import classNames from 'classnames';
import {KTIcon} from '../../../../_metronic/helpers';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import numeral from 'numeral';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../_metronic/partials';
import UpdateAccount from './updateAccount';
import {formatNumber} from '../../../functions/formatNumber';

const Accounts = observer(({isVerify, setPreloader}) => {
  const store = useStore();
  const [data, setData] = useState();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);
  const [currentId, setCurrentId] = useState();

  useEffect(() => {
    if (isVerify) {
      getData();
    }
  }, [isVerify]);

  const getData = async () => {
    const accounts = await store.financeAccountsGetAccountsList();
    setData(accounts);
    setPreloader(false);
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  const str = (number) => {
    const arr = number.split('');
    let newString = '';

    for (let i = 1; i < arr.length + 1; i++) {
      if (i % 4) {
        newString += arr[i - 1];
      } else {
        newString = newString + `${arr[i - 1]}` + ' ';
      }
    }

    return newString;
  };

  return (
    <Card>
      <div className='row gx-9 gy-6'>
        <div className='col-xl-4'>
          <div
            className={classNames(
              'notice d-flex bg-light-primary rounded h-lg-100 p-6 border-primary border border-dashed'
            )}
          >
            <div className='d-flex align-items-center justify-content-evenly flex-grow-1 flex-wrap flex-md-nowrap m-0'>
              <div className='mb-md-0 fw-semibold m-0'>
                <h5 className='text-gray-900 fw-bold m-0'>Добавить новый счет</h5>
              </div>
              <Link to={'/finances/accounts/add'}>
                <Button>Добавить</Button>
              </Link>
            </div>
          </div>
        </div>
        {data &&
          data?.map((item) => {
            return (
              <div className='col-xl-4' key={item.id}>
                <div className='card p-6'>
                  <div className='d-flex justify-content-between align-items-center flex-row col-xl-12'>
                    <div className='d-flex flex-nowrap gap-3 align-items-center'>
                      <h5 className='m-0'>{item.name}</h5>
                      {item.type === 'bank' ? (
                        <div className={classNames('rounded fs-8', css.bank)}>Банк</div>
                      ) : (
                        <div className={classNames('rounded fs-8', css.crypto)}>Криптовалюта</div>
                      )}
                    </div>
                    <div className='d-flex flex-nowrap gap-3'>
                      <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <i className='ki-duotone ki-switch fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                      <span
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => {
                          setCurrentId(item.id);
                          setShowCreateAppModal(true);
                        }}
                      >
                        <i className='ki-duotone ki-pencil fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center flex-row col-xl-12 mt-4'>
                    <div className={classNames(css.copyContainer, 'gap-3')}>
                      <h6 className={classNames(css.overflowText, 'm-0')}>
                        {item.type === 'bank' ? str(item.details) : item.details}
                      </h6>
                      <Button
                        className='py-1 px-4'
                        color='btn-dark'
                        action={() => copyText(item.details)}
                      >
                        <KTIcon iconName='copy' className='fs-8 p-0  cursor-pointer rounded' />
                      </Button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center flex-row col-xl-12 mt-4'>
                    <div className='d-flex flex-nowrap gap-3 align-items-center'>
                      <p className='m-0 text-muted fs-8'>Стартовый баланс:</p>
                      <span className='fw-bold fs-8'>${formatNumber(item.start_balance)}</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center flex-row col-xl-12 mt-4'>
                    <div className='d-flex flex-nowrap'>
                      <p className='m-0 text-muted fs-8'>
                        {item.update_at
                          ? ` Обновлено: ${moment(item.update_at).format('HH:mm DD/MM/YYYY')}`
                          : ''}
                      </p>
                    </div>
                    <div className='d-flex flex-nowrap'>
                      <p className='m-0 text-muted fs-8'>
                        {item.created_at
                          ? ` Создано: ${moment(item.created_at).format('HH:mm DD/MM/YYYY')}`
                          : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Редактирование счета'}
      >
        <UpdateAccount
          show={setShowCreateAppModal}
          getAdvertisersList={getData}
          warning={warning}
          setWarning={setWarning}
          isUpdate
          id={currentId}
        />
      </CreateAppModal>
    </Card>
  );
});

const AccountsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Финансы', path: '/finances', isActive: true},
        {isSeparator: true},
        {title: 'Счета', path: '/finances/accounts', isActive: true},
      ]}
      title={'Счета'}
    >
      <Accounts />
    </PageWrapper>
  );
};

export default AccountsWrapper;
