/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../../_metronic/partials';
import { formatNumberToCash } from '../../../../functions/formatNumber';
import moment from 'moment';
import Card from '../../../../shared/Card';
import Warning from '../../../../modules/Warning';
import userPlug from '../../../../../_metronic/assets/media/user.png';

const Advertisements = ({ title, subTitle, data}) => {

  const store = useStore();

  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-50px'>ID</th>
                    <th className='min-w-140px'>Название</th>
                    <th className='min-w-120px'>Лиды</th>
                    <th className='min-w-120px'>Регистрации</th>
                    <th className='min-w-120px'>Квалификации</th>
                    {!store.roles.includes(6) && <th className='min-w-120px'>Доход</th>}
                    <th className='min-w-100px text-end'></th>
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-semibold'>
                  {data?.map((el) => {
                    return (
                      <tr key={el?.id}>
                        <td>
                          <span>#{el?.id}</span>
                        </td>
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              <img
                                src={
                                  !!el?.avatar
                                    ? `${process.env.REACT_APP_API_HOST}/${el.avatar}`
                                    : userPlug
                                }
                                alt='#'
                                className='w-50px'
                                style={{borderRadius: '50%'}}
                              />
                            <span style={{marginLeft: '12px'}}>
                              {el?.name}
                            </span>
                          </span>
                        </td>
                        <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                               {el?.count_leads}
                            </span>
                        </td>
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {el?.count_registrations}
                          </span>
                        </td>
                        <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {el?.count_qualifications}
                          </span>
                        </td>
                        {!store.roles.includes(6) && (
                          <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {formatNumberToCash(el?.revenue)}
                            </span>
                          </td>
                        )}
                        <td className='text-end'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <Link
                              to={`/traffics/facebook/companies/${el?.id}`}
                            >
                              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <i className='ki-duotone ki-switch fs-2'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </div>
        </div>
      </Card>
    </>
  );
};

const ButtonDelete = observer(({data, setData, index, link}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  const deleteLink = async () => {
    const result = store.trafficsInfluenceLinksDeleteLink(link.id);

    if (!result) {
      return toast.error(`Произошла ошибка в удалении!`, {
        theme: 'colored',
      });
    }

    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);

    toast.success(`Успешно удалено!`);
  };

  return (
    <>
      <span
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        onClick={() => setShowCreateAppModal(true)}
      >
        <i className='ki-duotone ki-trash fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>
      </span>
      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          text={
            <span>
              Вы уверены что хотите удалить рекламную кампанию{' '}
              <span className='text-primary'>{link.name}</span>
            </span>
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-question-circle fs-5x text-warning'></i>
            </span>
          }
          buttons={[
            {
              action: () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                deleteLink();
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </>
  );
});

export default observer(Advertisements);
