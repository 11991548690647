import React, {useEffect, useState} from 'react';
import {useThemeMode} from '../../../../../../_metronic/partials';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../../../../styles/TOPManagers.module.scss';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';

const Header = ({top_1, top_2, top_3}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <div>
      <div className='card-body pt-9 pb-0'>
        <div className={css.headerContainer}>
          <div
            className={classNames(css.cardContainer, css._1)}
            style={{
              backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
              border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
            }}
          >
            <div className={css.number}>1st</div>
            <div
              className={css.image}
              style={{
                backgroundImage: !!top_1?.avatar
                  ? `url(${process.env.REACT_APP_API_HOST}/${top_1?.avatar})`
                  : `url(${imagePlug})`,
              }}
            >
              <span className={css.crown} />
            </div>
            <span className={classNames(css.id, 'fw-semibold text-gray-400 fs-4')}>
              #{top_1?.id}
            </span>
            <Link
              to={
                store.roles.includes(15) || store.roles.includes(34)
                  ? ''
                  : `/sales/rd/managers/${top_1?.id}`
              }
            >
              <span className={classNames(css.name, 'text-gray-800 fs-2 fw-bold')}>
                {top_1?.first_name} {top_1?.last_name}
              </span>
            </Link>
          </div>
          <div
            className={classNames(css.cardContainer, css._2)}
            style={{
              backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
              border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
            }}
          >
            <div className={css.number}>2st</div>
            <div
              className={css.image}
              style={{
                backgroundImage: !!top_2?.avatar
                  ? `url(${process.env.REACT_APP_API_HOST}/${top_2?.avatar})`
                  : `url(${imagePlug})`,
              }}
            >
              <span className={css.crown}></span>
            </div>
            <span className={classNames(css.id, 'fw-semibold text-gray-400 fs-4')}>
              #{top_2?.id}
            </span>
            <Link
              to={
                store.roles.includes(15) || store.roles.includes(34)
                  ? ''
                  : `/sales/rd/managers/${top_2?.id}`
              }
            >
              <span className={classNames(css.name, 'text-gray-800 fs-2 fw-bold')}>
                {top_2?.first_name} {top_2?.last_name}
              </span>
            </Link>
          </div>
          <div
            className={classNames(css.cardContainer, css._3)}
            style={{
              backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
              border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
            }}
          >
            <div className={css.number}>3st</div>
            <div
              className={css.image}
              style={{
                backgroundImage: !!top_3?.avatar
                  ? `url(${process.env.REACT_APP_API_HOST}/${top_3?.avatar})`
                  : `url(${imagePlug})`,
              }}
            >
              <span className={css.crown}></span>
            </div>
            <span className={classNames(css.id, 'fw-semibold text-gray-400 fs-4')}>
              #{top_3?.id}
            </span>
            <Link to={store.roles.includes(15) || store.roles.includes(34) ? '' : `/sales/rd/managers/${top_3?.id}`}>
              <span className={classNames(css.name, 'text-gray-800 fs-2 fw-bold')}>
                {top_3?.first_name} {top_3?.last_name}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
