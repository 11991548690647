import React, {useState, useEffect, useCallback} from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import css from '../../../../../../../styles/Treker.module.scss';

const Export = ({fieldsToKeep, data, columnFormats = {}, time, fileName}) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!Array.isArray(data)) return setLoader(true);
    setLoader(false);
  }, [data]);

  const createExcelFile = useCallback(() => {
    if (!Array.isArray(data)) return;

    // Фільтруємо дані, залишаючи тільки потрібні поля
    const filteredData = data.map((item) => {
      if (typeof item !== 'object') return {};
      return Object.keys(item).reduce((acc, key) => {
        if (fieldsToKeep.includes(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {});
    });

    // Створюємо новий робочий лист з відфільтрованими даними
    const ws = XLSX.utils.json_to_sheet(filteredData, {header: fieldsToKeep});

    // Застосовуємо форматування до вказаних колонок

    const range = XLSX.utils.decode_range(ws['!ref']);
    Object.entries(columnFormats).forEach(([columnName, format]) => {
      const columnIndex = fieldsToKeep.indexOf(columnName);

      if (columnIndex === -1) {
        console.error(`Column "${columnName}" not found`);
        return;
      }

      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cellAddress = XLSX.utils.encode_cell({c: columnIndex, r: R});
        if (ws[cellAddress]) {
          ws[cellAddress].z = format;
        }
      }
    });

    // Створюємо нову робочу книгу
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // Створюємо файл Excel
    XLSX.writeFile(
      wb,
      time.config === 'all_time'
        ? `${fileName} all_time.xlsx`
        : `${fileName} ${moment
            .tz(time.start * 1000, 'Europe/Kiev')
            .format('YYYY-MM-DD')} - ${moment
            .tz(new Date(time.end * 1000).setUTCHours(0, 0, 0), 'Europe/Kiev')
            .format('YYYY-MM-DD')}.xlsx`
    );
  }, [data, fieldsToKeep, columnFormats]);

  return (
    // <button
    //   className='btn btn-sm shadow-xs d-flex align-items-center me-4 btn-active-light-success'
    //   onClick={() => !loader && createExcelFile()}
    // >
    //   {!loader && (
    //     <i className='ki-duotone ki-file-down fs-1 pe-0'>
    //       <span className='path1'></span>
    //       <span className='path2'></span>
    //     </i>
    //   )}
    //   {!!loader && <span className='exelLoader'></span>}
    // </button>
    <button className={css.button} onClick={() => createExcelFile()}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity='0.4'
          d='M15.375 7.6425H13.2075C11.43 7.6425 9.9825 6.195 9.9825 4.4175V2.25C9.9825 1.8375 9.645 1.5 9.2325 1.5H6.0525C3.7425 1.5 1.875 3 1.875 5.6775V12.3225C1.875 15 3.7425 16.5 6.0525 16.5H11.9475C14.2575 16.5 16.125 15 16.125 12.3225V8.3925C16.125 7.98 15.7875 7.6425 15.375 7.6425Z'
        />
        <path d='M11.8501 1.65749C11.5426 1.34999 11.0101 1.55999 11.0101 1.98749V4.60499C11.0101 5.69999 11.9401 6.60749 13.0726 6.60749C13.7851 6.615 14.7751 6.61499 15.6226 6.61499C16.0501 6.61499 16.2751 6.11249 15.9751 5.81249C14.8951 4.72499 12.9601 2.76749 11.8501 1.65749Z' />
        <path d='M8.64746 9.3525L7.14746 7.8525C7.13996 7.845 7.13246 7.845 7.13246 7.8375C7.08746 7.7925 7.02746 7.755 6.96746 7.725C6.95996 7.725 6.95996 7.725 6.95246 7.725C6.89246 7.7025 6.83246 7.695 6.77246 7.6875C6.74996 7.6875 6.73496 7.6875 6.71246 7.6875C6.66746 7.6875 6.61496 7.7025 6.56996 7.7175C6.54746 7.725 6.53246 7.7325 6.51746 7.74C6.45746 7.77 6.39746 7.8 6.35246 7.8525L4.85246 9.3525C4.63496 9.57 4.63496 9.93 4.85246 10.1475C5.06996 10.365 5.42996 10.365 5.64746 10.1475L6.18746 9.6075V12.75C6.18746 13.0575 6.44246 13.3125 6.74996 13.3125C7.05746 13.3125 7.31246 13.0575 7.31246 12.75V9.6075L7.85246 10.1475C7.96496 10.26 8.10746 10.3125 8.24996 10.3125C8.39246 10.3125 8.53496 10.26 8.64746 10.1475C8.86496 9.93 8.86496 9.57 8.64746 9.3525Z' />
      </svg>
      <span>Экспорт</span>
    </button>
  );
};

export default Export;
