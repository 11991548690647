/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import Card from '../../../../shared/Card';
import { formatNumber } from '../../../../functions/formatNumber';

const Board = ({data}) => {

  return (
    <Card className='card card-flush mt-6 mt-xl-9'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Общая статистика</h3>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='row p-0 mb-5'>
          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-info d-block'>Лиды</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber(data?.count_leads)}
              </span>
            </div>
          </div>
          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-warning d-block'>Регистрации</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber(data?.count_registrations)}
              </span>
            </div>
          </div>

          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-success d-block'>Квалификации</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber(data?.count_qualifications)}
              </span>
            </div>
          </div>

          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-primary d-block'>Обращения</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber(data?.count_appeals)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Board;
