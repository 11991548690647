import React from 'react';
import {toast} from 'react-toastify';
import Card from '../../../../../shared/Card';
import {KTIcon} from '../../../../../../_metronic/helpers';
import {Button} from '../../../../../shared/Buttons';

const Info = ({link = {}, channel = {}, traffic = {}, bot = {}, bayer = {}, id}) => {
  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(`Скопировано!`, {});
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div className='col-xl-12 mb-5 mb-xl-10'>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-header py-7'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-gray-800'>Общая информация</span>
          </h3>
        </div>
        <div className='card-body p-9'>
          <div>
            <div className='row mb-7'>
              <label className='col-4 fw-semibold text-muted'>Название</label>

              <div className='col-8 d-flex align-items-center'>
                <span className='fw-bold fs-6 me-2'>
                  {link?.name} {!!link?.id && `#${link?.id}`}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-4 fw-semibold text-muted'>Блогер</label>

              <div className='col-8 d-flex align-items-center'>
                <span className='fw-bold fs-6 me-2'>
                  {traffic?.name} {!!traffic?.id && `#${traffic?.id}`}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-4 fw-semibold text-muted'>Статус</label>

              <div className='col-8 d-flex align-items-center'>
                {!!link?.status && (
                  <span className={`badge badge-light-${link?.status?.indicator}`}>
                    {link?.status?.name}
                  </span>
                )}
                {!link?.status && <span className='badge badge-light'>Неизвестно</span>}
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-4 fw-semibold text-muted'>Бот</label>

              <div className='col-8 d-flex align-items-center'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {bot?.name} {!!bot?.id && `#${bot?.id}`}
                </span>
              </div>
            </div>
            {bayer?.first_name &&
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Байер</label>

                <div className='col-8 d-flex align-items-center'>
                <span className='fw-bold fs-6 text-gray-800 me-2'>
                  {bayer?.first_name} {bayer?.last_name} {!!bot?.id && `#${bayer?.id}`}
                </span>
                </div>
              </div>
            }
            <div className='row mb-7 d-flex align-items-center w-100'>
              <label className='col-4 fw-semibold text-muted'>Ссылка</label>

              <div
                className='col-7 d-flex align-items-center border border-end-0 border-2 rounded-1'
                style={{padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)', maxWidth: '300px'}}
              >
                <span
                  className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    minHeight: '20px',
                  }}
                  id=''
                >
                  {`https://thl.ac/inf/${id}`}
                </span>
              </div>
              <div className='col-1 d-flex p-0'>
                <Button
                  className=''
                  style={{borderRadius: '0px 6px 6px 0px'}}
                  color='btn-dark'
                  action={() => copyText(`https://thl.ac/inf/${id}`)}
                >
                  <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                </Button>
              </div>
            </div>
            <div className='row mb-7 d-flex align-items-center w-100'>
              <label className='col-4 fw-semibold text-muted'>Ссылка на бота</label>

              <div
                className='col-7 d-flex align-items-center border border-end-0 border-2 rounded-1'
                style={{padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)', maxWidth: '300px'}}
              >
                <span
                  className='fw-bold fs-6 text-gray-800 me-2 cursor-pointer'
                  style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    minHeight: '20px',
                  }}
                  id=''
                >
                  {`https://t.me/${bot?.username}?start=link${link?.id}`}
                </span>
              </div>
              <div className='col-1 d-flex p-0'>
                <Button
                  className=''
                  style={{borderRadius: '0px 6px 6px 0px'}}
                  color='btn-dark'
                  action={() => copyText(`https://t.me/${bot?.username}?start=link${link?.id}`)}
                >
                  <KTIcon iconName='copy' className='fs-2 p-0 w-100 cursor-pointer' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Info;
