/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import Navigation from '../../modules/NavigationProfiles';
import iconPlug from '../../../_metronic/assets/media/user.png';
import {CreateAppModal} from '../../../_metronic/partials';
import {Button} from '../../shared/Buttons';
import Warning from '../../modules/Warning';
import Card from '../../shared/Card';

const ProfileHeader = ({children, data, getData, navigation, isSettings = false}) => {
  const store = useStore();
  return (
    <>
      <Card className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    !!data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : iconPlug
                  }
                  alt='#'
                />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span
                      className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'
                      style={{cursor: 'pointer'}}
                    >
                      {data?.first_name} {data?.last_name}
                    </span>
                    {!!data?.is_verified && (
                      <span>
                        <i className='ki-duotone ki-verify fs-1 text-primary'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    )}
                    {!data?.is_verified && (
                      <span>
                        <i className='ki-duotone ki-verify fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    )}
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                    <span
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      style={{cursor: 'pointer'}}
                    >
                      <i className='ki-duotone ki-profile-circle fs-4 me-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      {data?.role_name}
                    </span>
                  </div>
                </div>

                {isSettings && (
                  <div className='d-flex my-4'>
                    <Connection telegramName={data?.telegram_username} />
                    {store.roles.includes(13)|| store.roles.includes(32) ? (
                      <></>
                    ) : (
                      <div className='me-0'>
                        <Button
                          className='btn-icon'
                          color={'btn-bg-light btn-active-color-primary'}
                          ktMenuTrigger='click'
                          ktMenuPlacement='bottom-end'
                        >
                          <i className='ki-solid ki-dots-horizontal fs-2x me-1'></i>
                        </Button>

                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                          data-kt-menu='true'
                        >
                          <div className='menu-item px-3'>
                            <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                              Управление
                            </div>
                          </div>

                          <div className='menu-item px-3'>
                            <span className='menu-link px-3'>Выплатить</span>
                          </div>
                          <Blocking id={data?.id} is_ban={data?.is_ban} getData={getData} />
                          <Veryfication
                            id={data?.id}
                            is_verified={data?.is_verified}
                            getData={getData}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8 pe-md-0'>
                  <div className='d-flex'>{children}</div>
                </div>
              </div>
            </div>
          </div>

          {!!navigation && <Navigation links={navigation} />}
        </div>
      </Card>
    </>
  );
};

const Blocking = observer(({id, is_ban, getData}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <div className='menu-item px-3' onClick={() => setShowCreateAppModal(true)}>
        <span className='menu-link px-3'>{is_ban ? 'Разблокировать' : 'Заблокировать'}</span>
      </div>
      <CreateAppModal
        customWidth='mw-400px'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: async () => {
                setShowCreateAppModal(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: async () => {
                const result = await store.usersBanUser(id, !is_ban);
                !!result && getData();
                setShowCreateAppModal(false);
                !!result && toast.success(`Успешно изменено!`, {});
                !result &&
                  toast.error(`Произошла ошибка в изменеиях!`, {
                    theme: 'colored',
                  });
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
          text={
            is_ban
              ? 'Вы уверены что хотите разблокировать пользователя!'
              : 'Вы уверены что хотите заблокировать пользователя!'
          }
          icon={
            <span className='symbol-label'>
              <i className='bi bi-exclamation-circle fs-5x text-warning'></i>
            </span>
          }
        />
      </CreateAppModal>
    </>
  );
});

const Veryfication = observer(({id, is_verified, getData}) => {
  const store = useStore();
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      {!is_verified && (
        <>
          <div className='menu-item px-3' onClick={() => setShowCreateAppModal(true)}>
            <span className='menu-link px-3'>Верифицировать</span>
          </div>
          <CreateAppModal
            customWidth='mw-400px'
            show={showCreateAppModal}
            handleClose={() => setShowCreateAppModal(false)}
            showHeader={false}
            isMessage={true}
          >
            <Warning
              buttons={[
                {
                  action: () => {
                    setShowCreateAppModal(false);
                  },
                  title: <i className='bi bi-x p-0 fs-1'></i>,
                  class: 'btn-light',
                },
                {
                  action: async () => {
                    const result = await store.usersVerifyUser(id, true);
                    !!result && getData();
                    setShowCreateAppModal(false);
                    !!result && toast.success(`Успешно изменено!`, {});
                    !result &&
                      toast.error(`Произошла ошибка в изменеиях!`, {
                        theme: 'colored',
                      });
                  },
                  title: <i className='bi bi-check p-0 fs-1'></i>,
                  class: 'btn-light-primary',
                },
              ]}
              text='Вы уверены что хотите верифицировать пользователя!'
              icon={
                <span className='symbol-label'>
                  <i className='bi bi-exclamation-circle fs-5x text-warning'></i>
                </span>
              }
            />
          </CreateAppModal>
        </>
      )}
    </>
  );
});

const Connection = ({telegramName}) => {
  return (
    <>
      <Button
        className='me-3'
        action={() => {
          window.open(`https://t.me/${telegramName}`, '_blank');
        }}
      >
        Связатся
      </Button>
    </>
  );
};

export default observer(ProfileHeader);
