/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {CreateAppModal} from '../../../../../_metronic/partials';
import { formatNumberToCash } from '../../../../functions/formatNumber';
import moment from 'moment';
import Card from '../../../../shared/Card';
import Warning from '../../../../modules/Warning';
import userPlug from '../../../../../_metronic/assets/media/user.png';

const Pwa = ({ title, subTitle, data}) => {

  const store = useStore();

  return (
    <>
      <Card className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3'>
              <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>Дата</th>
                <th className='min-w-120px text-center'>Клики</th>
                <th className='min-w-120px text-center'>Установки(Лиды)</th>
                <th className='min-w-120px text-center'>Регистрации</th>
                <th className='min-w-120px text-center'>Первые депозиты</th>
                <th className='min-w-120px text-center'>Квалификации</th>
                {!store.roles.includes(6) && <th className='min-w-120px text-center'>Доход</th>}
              </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
              {data?.map((el) => {
                return (
                  <tr key={el?.day}>
                    <td>
                      <span>{moment(el?.day * 1000).format('ll')}</span>
                    </td>
                    <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                            {el?.count_click}
                          </span>
                    </td>
                    <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                            {el?.count_leads}
                          </span>
                    </td>
                    <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                               {el?.count_registrations}
                            </span>
                    </td>
                    <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                            {el?.count_first_deposit}
                          </span>
                    </td>
                    <td>
                          <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {el?.count_qualifications}
                          </span>
                    </td>
                    {!store.roles.includes(6) && (
                      <td>
                            <span className='text-gray-900 fw-bold text-hover-primary d-block text-center mb-1 fs-6'>
                              {formatNumberToCash(el?.revenue)}
                            </span>
                      </td>
                    )}
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};


export default observer(Pwa);
