import {api} from './api';

const employeesAPI = {
  async usersGetUsers(token, filters) {
    try {
      const response = await api.get(`Users/getUsersNew?token=${token}&${filters}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersAddUsers(data) {
    try {
      const response = await api.post(`Users/addUser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersUpdateUser(data) {
    try {
      const response = await api.post(`Users/updateUser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersVerifyUser(token, id, is_verified) {
    try {
      const response = await api.post(`Users/verifyUser`, {token, id, is_verified});

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersGetMainData(token, id) {
    try {
      const response = await api.get(`/Users/getMainData?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async getData(token, id) {
    try {
      const response = await api.get(`Users/getData?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async usersGetAllAccess(token) {
    try {
      const response = await api.get(`Users/getAllAccess?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersRolesGetList(token) {
    try {
      const response = await api.get(`Users/Roles/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async usersRolesGetUserList(token, id) {
    try {
      const response = await api.get(`Users/Roles/getUserList?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersBanUser(token, id, status) {
    try {
      const response = await api.post(`Users/banUser`, {token, id, is_ban: status});

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersGetSettings(token, id) {
    try {
      const response = await api.get(`Users/getSettings?token=${token}&user_id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async usersGetWallets(token, id) {
    try {
      const response = await api.get(`Users/getWallets?token=${token}&user_id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersGetTransactions(token, id) {
    try {
      const response = await api.get(`Users/getTransactions?token=${token}&user_id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersGetAuthData(token, id) {
    try {
      const response = await api.get(`Users/getAuthData?token=${token}&user_id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async CRMWorkspaces(token, type) {
    try {
      const response = await api.get(`CRM/Workspaces?token=${token}&type=${type}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async usersUpdateDayoff(data) {
    try {
      const response = await api.post(`Users/updateDayoff`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async usersGetInfluenceTeamLeadList(token) {
    try {
      const response = await api.get(`Users/getUsersTeamLeadInfluenceList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default employeesAPI;
