export const base_config_app = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttonColor: '#0b57cf',
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
  },
  texts: {
    dataSafety:
      'To control safety, you need to know how developers collect and share your data. Security and privacy methods may vary depending on how you use the app, your region, and your age. The information below is provided by developers and may change in the future.',
    ratings:
      'Ratings and reviews have been verified. They were made by users with the same type of device as yours.',
  },
  titles: {
    aboutThisApp: 'About this App',
    dataSafety: 'Data safety',
    ratings: 'Rating and reviews',
    appSupport: 'App support',
    games: 'Similar games',
  },
  inscriptions: {
    contains_1: 'Contains ads',
    contains_2: 'In-app purchases',
    reviews: 'reviews',
    ageRating: 'Rated for 18+',
    downloads: 'Downloads',
    countDownloads: '1000',
    phone: 'Phone',
    dataSafety_title_1: 'Data is not shared with third parties',
    dataSafety_text_1: 'Learn more about how developers declare sharing data',
    dataSafety_title_2: 'Data is not collected',
    dataSafety_text_2: 'Learn more about how developers declare collecting data',
    seeDetails: 'See details',
    seeAllReviews: 'See all reviews',
    helpFull: 'Was this review helpful?',
    peopleHelpFull: 'people found this review helpful',
    yes: 'Yes',
    no: 'No',
  },
};

export const googleplay_app = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttonColor: '#0b57cf',
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
  },
  texts: {
    dataSafety: '',
    ratings: '',
  },
  titles: {
    aboutThisApp: '',
    dataSafety: '',
    ratings: '',
    appSupport: '',
    games: '',
  },
  inscriptions: {
    contains_1: '',
    contains_2: '',
    reviews: '',
    ageRating: '',
    downloads: '',
    countDownloads: '',
    phone: '',
    dataSafety_title_1: '',
    dataSafety_text_1: '',
    dataSafety_title_2: '',
    dataSafety_text_2: '',
    seeDetails: '',
    seeAllReviews: '',
    helpFull: '',
    peopleHelpFull: '',
    yes: '',
    no: '',
  },
};


export const base_config_site = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  video: '',
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
    trailer: 'Trailer',
  },
  texts: {
    dataSafety:
      'To control safety, you need to know how developers collect and share your data. Security and privacy methods may vary depending on how you use the app, your region, and your age. The information below is provided by developers and may change in the future.',
    ratings:
      'Ratings and reviews have been verified. They were made by users with the same type of device as yours.',
  },
  titles: {
    aboutThisApp: 'About this App',
    dataSafety: 'Data safety',
    ratings: 'Rating and reviews',
    appSupport: 'App support',
    games: 'Similar games',
  },
  inscriptions: {
    contains_1: 'Contains ads',
    contains_2: 'In-app purchases',
    reviews: 'reviews',
    ageRating: 'Rated for 18+',
    downloads: 'Downloads',
    countDownloads: '1000',
    phone: 'Phone',
    dataSafety_title_1: 'Data is not shared with third parties',
    dataSafety_text_1: 'Learn more about how developers declare sharing data',
    dataSafety_title_2: 'Data is not collected',
    dataSafety_text_2: 'Learn more about how developers declare collecting data',
    dataSafety_title_3: 'Learn more about how developers declare collecting data',
    dataSafety_title_4: 'Learn more about how developers declare collecting data',
    seeDetails: 'See details',
    seeAllReviews: 'See all reviews',
    helpFull: 'Did you find this helpful?',
    yes: 'Yes',
    no: 'No',
    share: 'Share',
    addList: 'Add to wishlist',
  },
};
export const googleplay_site = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  video: '',
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttons: {
    install: '',
    installing: '',
    download: '',
    cancel: '',
    open: '',
    delete: '',
    trailer: '',
  },
  texts: {
    dataSafety: '',
    ratings: '',
  },
  titles: {
    aboutThisApp: '',
    dataSafety: '',
    ratings: '',
    appSupport: '',
    games: '',
  },
  inscriptions: {
    contains_1: '',
    contains_2: '',
    reviews: '',
    ageRating: '',
    downloads: '',
    countDownloads: '',
    phone: '',
    dataSafety_title_1: '',
    dataSafety_text_1: '',
    dataSafety_title_2: '',
    dataSafety_text_2: '',
    dataSafety_title_3: '',
    dataSafety_title_4: '',
    seeDetails: '',
    seeAllReviews: '',
    helpFull: '',
    yes: '',
    no: '',
    share: '',
    addList: '',
  },
};
