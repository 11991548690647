import {api} from './api';

const advertisersAPI = {
  async salesTeamsGetList(token) {
    try {
      const response = await api.get(`Advertisers/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersGetAdvertiser(token, id) {
    try {
      const response = await api.get(`Advertisers/getAdvertiser?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersAddAdvertiser(data) {
    try {
      const response = await api.post(`Advertisers/addAdvertiser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersUpdateAdvertiser(data) {
    try {
      const response = await api.post(`Advertisers/updateAdvertiser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersGetOffers(token, id) {
    try {
      const response = await api.get(`Advertisers/getOffers?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default advertisersAPI;
