import React, {useEffect} from 'react';
import css from '../../../../styles/BannerTopManagers.module.scss';
import classNames from 'classnames';
import bg from '../../../../_metronic/assets/media/bannerTopManager.png';
import imagePlug from '../../../../_metronic/assets/media/user.png';

const BannerTopManager = ({managers}) => {
  return (
    <div
      className={classNames('card', css.card)}
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className='card-body'>
        <div className={css.banner}>
          <div className={css.titleContainer}>
            <div className={css.title}>
              <span>ЛУЧШИЕ</span> <span>МЕНЕДЖЕРА</span>
            </div>
            <div className={css.image} />
          </div>
          <div className={css.managers}>
            <div className={css.manager}>
              <div className={css.type}>LTC</div>
              <div
                className={css.image}
                style={{
                  backgroundImage: !!managers?.manager_ltc?.avatar
                    ? `url(${process.env.REACT_APP_API_HOST}/${managers?.manager_ltc?.avatar})`
                    : `url(${imagePlug})`,
                }}
              />
              <div className={css.nameContainer}>
                <span className={css.name}>
                  {managers?.manager_ltc?.first_name} {managers?.manager_ltc?.last_name}
                </span>
              </div>
              <span className={css.pr}>{managers?.manager_ltc?.ltc.toFixed(2)}%</span>
            </div>
            <div className={css.manager}>
              <div className={css.type}>RD</div>
              <div
                className={css.image}
                style={{
                  backgroundImage: !!managers?.manager_rd?.avatar
                    ? `url(${process.env.REACT_APP_API_HOST}/${managers?.manager_rd?.avatar})`
                    : `url(${imagePlug})`,
                }}
              />
              <div className={css.nameContainer}>
                <span className={css.name}>
                  {managers?.manager_rd?.first_name} {managers?.manager_rd?.last_name}
                </span>
              </div>
              <span className={css.pr}>{managers?.manager_rd?.rd.toFixed(2)}$</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerTopManager;
