import React from 'react';
import { formatNumber, formatNumber2 } from "../../../functions/formatNumber";

const container = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '20px',
};

const AI = ({data}) => {
  return (
    <div style={container}>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        style={{flex: '1', minWidth: '165px'}}
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.4'
              d='M2 12.97V6.99C2 4.23 4.24 2 7 2H17C19.76 2 22 4.23 22 6.99V13.97C22 16.72 19.76 18.95 17 18.95H15.5C15.19 18.95 14.89 19.1 14.7 19.35L13.2 21.34C12.54 22.22 11.46 22.22 10.8 21.34L9.3 19.35C9.14 19.13 8.78 18.95 8.5 18.95H7C4.24 18.95 2 16.72 2 13.97V12.97Z'
              fill='#1B84FF'
            />
            <path
              d='M17 8.75H7C6.59 8.75 6.25 8.41 6.25 8C6.25 7.59 6.59 7.25 7 7.25H17C17.41 7.25 17.75 7.59 17.75 8C17.75 8.41 17.41 8.75 17 8.75Z'
              fill='#1B84FF'
            />
            <path
              d='M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z'
              fill='#1B84FF'
            />
          </svg>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{formatNumber(data?.count_appeals)}</span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Чаты кол-во</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        style={{flex: '1', minWidth: '165px'}}
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M17.7391 6.78262C17.7391 7.43233 17.5997 8.03775 17.3208 8.59886C17.0418 9.17474 16.6686 9.6731 16.201 10.0939C15.7334 10.5148 15.1797 10.8507 14.5398 11.1017C13.9163 11.3528 13.2436 11.4783 12.5217 11.4783C11.7998 11.4783 11.1272 11.3528 10.5037 11.1017C9.86383 10.8507 9.31009 10.5148 8.8425 10.0939C8.3749 9.6731 8.00164 9.17474 7.72273 8.59886C7.44381 8.03775 7.30435 7.43233 7.30435 6.78262C7.30435 6.13291 7.44381 5.52749 7.72273 4.96638C8.00164 4.39049 8.3749 3.89214 8.8425 3.4713C9.31009 3.05046 9.86383 2.71453 10.5037 2.46351C11.1272 2.21248 11.7998 2.08697 12.5217 2.08697C13.2436 2.08697 13.9163 2.21248 14.5398 2.46351C15.1797 2.71453 15.7334 3.05046 16.201 3.4713C16.6686 3.89214 17.0418 4.39049 17.3208 4.96638C17.5997 5.52749 17.7391 6.13291 17.7391 6.78262Z'
              fill='#1B84FF'
            />
            <g opacity='0.3'>
              <path
                d='M19.6558 21.913C19.6714 21.913 19.6831 21.913 19.6908 21.913C19.6986 21.913 19.7103 21.913 19.7259 21.913C20.0371 21.913 20.3055 21.8083 20.5311 21.5987C20.7567 21.3892 20.8696 21.14 20.8696 20.851C20.8696 20.8365 20.8696 20.8221 20.8696 20.8076C20.8696 20.7932 20.8696 20.7787 20.8696 20.7643C20.6984 19.8973 20.3561 19.0881 19.8426 18.3367C19.3135 17.5853 18.6561 16.9351 17.8702 16.386C17.0844 15.8369 16.1936 15.4034 15.1977 15.0855C14.1863 14.7676 13.1204 14.6087 12 14.6087C10.8796 14.6087 9.81373 14.7676 8.80229 15.0855C7.8064 15.4034 6.91167 15.8369 6.11808 16.386C5.32448 16.9351 4.67094 17.5853 4.15743 18.3367C3.62837 19.0881 3.28604 19.8973 3.13043 20.7643C3.13043 20.7787 3.13043 20.7932 3.13043 20.8076C3.13043 20.8221 3.13043 20.8437 3.13043 20.8726C3.13043 21.1616 3.23936 21.4073 3.45721 21.6096C3.67505 21.8119 3.93959 21.913 4.2508 21.913C4.26636 21.913 4.28192 21.913 4.29748 21.913C4.31304 21.913 4.3286 21.913 4.34416 21.913H4.32082H19.6558Z'
                fill='#1B84FF'
              />
            </g>
            <path
              d='M16.7405 19.6159L15.3717 19.8027C15.3567 19.8027 15.3418 19.8066 15.3268 19.8144C15.3118 19.8222 15.2969 19.8261 15.2819 19.8261C15.0874 19.8261 14.9266 19.756 14.7994 19.6159C14.6723 19.4757 14.6087 19.3122 14.6087 19.1253C14.6087 19.0941 14.6087 19.0669 14.6087 19.0435C14.6087 19.0201 14.6162 19.0007 14.6311 18.9851L14.9004 17.56C14.9303 17.451 14.9677 17.3498 15.0126 17.2564C15.0575 17.1629 15.1174 17.085 15.1922 17.0227L18.4685 13.8456C18.5582 13.7521 18.6629 13.682 18.7826 13.6353C18.9023 13.5886 19.0295 13.5652 19.1641 13.5652C19.3137 13.5652 19.4558 13.5964 19.5905 13.6587C19.7251 13.721 19.8373 13.8144 19.9271 13.939L20.5554 14.6165C20.6452 14.7255 20.72 14.8462 20.7798 14.9786C20.8397 15.111 20.8696 15.2473 20.8696 15.3874C20.8696 15.5432 20.8397 15.6872 20.7798 15.8196C20.72 15.952 20.6452 16.0649 20.5554 16.1584L17.3016 19.3355C17.2267 19.4134 17.1407 19.4757 17.0435 19.5224C16.9462 19.5691 16.8453 19.6003 16.7405 19.6159Z'
              fill='#1B84FF'
            />
          </svg>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{formatNumber(data?.count_registrations)}</span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Регистрации</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        style={{flex: '1', minWidth: '165px'}}
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.4'
              d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z'
              fill='#1B84FF'
            />
            <path
              d='M14.26 12L12.75 11.47V8.08H13.11C13.92 8.08 14.58 8.79 14.58 9.66C14.58 10.07 14.92 10.41 15.33 10.41C15.74 10.41 16.08 10.07 16.08 9.66C16.08 7.96 14.75 6.58 13.11 6.58H12.75V6C12.75 5.59 12.41 5.25 12 5.25C11.59 5.25 11.25 5.59 11.25 6V6.58H10.6C9.12 6.58 7.91 7.83 7.91 9.36C7.91 11.15 8.95 11.72 9.74 12L11.25 12.53V15.91H10.89C10.08 15.91 9.42 15.2 9.42 14.33C9.42 13.92 9.08 13.58 8.67 13.58C8.26 13.58 7.92 13.92 7.92 14.33C7.92 16.03 9.25 17.41 10.89 17.41H11.25V18C11.25 18.41 11.59 18.75 12 18.75C12.41 18.75 12.75 18.41 12.75 18V17.42H13.4C14.88 17.42 16.09 16.17 16.09 14.64C16.08 12.84 15.04 12.27 14.26 12ZM10.24 10.59C9.73 10.41 9.42 10.24 9.42 9.37C9.42 8.66 9.95 8.09 10.61 8.09H11.26V10.95L10.24 10.59ZM13.4 15.92H12.75V13.06L13.76 13.41C14.27 13.59 14.58 13.76 14.58 14.63C14.58 15.34 14.05 15.92 13.4 15.92Z'
              fill='#1B84FF'
            />
          </svg>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{formatNumber(data?.count_deposits)}</span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Первые депозиты</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        style={{flex: '1', minWidth: '165px'}}
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15.6522 6.78262C15.6522 7.43541 15.5335 8.04369 15.2961 8.60747C15.0439 9.18608 14.7064 9.6868 14.2835 10.1096C13.8607 10.5325 13.36 10.87 12.7814 11.1222C12.2176 11.3596 11.6093 11.4783 10.9565 11.4783C10.3037 11.4783 9.69544 11.3596 9.13167 11.1222C8.56789 10.87 8.07088 10.5325 7.64063 10.1096C7.21038 9.6868 6.87657 9.18608 6.63919 8.60747C6.38697 8.04369 6.26086 7.43541 6.26086 6.78262C6.26086 6.12983 6.38697 5.52154 6.63919 4.95777C6.87657 4.37916 7.21038 3.87844 7.64063 3.4556C8.07088 3.03277 8.56789 2.69525 9.13167 2.44304C9.69544 2.20566 10.3037 2.08697 10.9565 2.08697C11.6093 2.08697 12.2176 2.20566 12.7814 2.44304C13.36 2.69525 13.8607 3.03277 14.2835 3.4556C14.7064 3.87844 15.0439 4.37916 15.2961 4.95777C15.5335 5.52154 15.6522 6.12983 15.6522 6.78262Z'
              fill='#1B84FF'
            />
            <path
              d='M17.7514 12.5217C16.5926 12.5217 15.6053 12.9297 14.7892 13.7458C13.9732 14.5618 13.5652 15.541 13.5652 16.6834C13.5652 17.8422 13.9732 18.8295 14.7892 19.6455C15.6053 20.4616 16.5926 20.8696 17.7514 20.8696C18.8938 20.8696 19.873 20.4616 20.689 19.6455C21.505 18.8295 21.913 17.8422 21.913 16.6834C21.913 16.6834 21.913 16.6793 21.913 16.6712C21.913 16.663 21.913 16.6508 21.913 16.6345C21.8967 15.492 21.4846 14.521 20.6768 13.7213C19.8689 12.9216 18.8938 12.5217 17.7514 12.5217ZM19.9546 16.0959L18.192 18.3726C18.1267 18.4542 18.0411 18.5194 17.935 18.5684C17.8289 18.6174 17.7106 18.65 17.58 18.6663C17.4658 18.6663 17.3515 18.6378 17.2373 18.5806C17.123 18.5235 17.0333 18.446 16.968 18.3481L15.695 16.6834C15.6624 16.6345 15.6338 16.5692 15.6093 16.4876C15.5849 16.406 15.5726 16.3244 15.5726 16.2428C15.5726 16.1285 15.5971 16.0184 15.6461 15.9123C15.695 15.8062 15.7603 15.7205 15.8419 15.6552C15.9072 15.6063 15.9765 15.5696 16.05 15.5451C16.1234 15.5206 16.2009 15.5084 16.2825 15.5084C16.4131 15.5084 16.5273 15.5328 16.6253 15.5818C16.7232 15.6308 16.813 15.696 16.8946 15.7776L17.5555 16.61L18.7061 15.1656C18.7714 15.0677 18.8571 14.9902 18.9632 14.9331C19.0692 14.8759 19.1794 14.8474 19.2936 14.8474C19.5221 14.8474 19.7098 14.9249 19.8567 15.0799C20.0036 15.235 20.077 15.4186 20.077 15.6308C20.077 15.7287 20.0648 15.8144 20.0403 15.8878C20.0158 15.9612 19.9709 16.0388 19.9056 16.1204V16.0959H19.9546Z'
              fill='#1B84FF'
            />
            <g opacity='0.3'>
              <path
                d='M20.8696 20.7574C20.7446 20.1469 20.5454 19.58 20.272 19.0567C19.9986 18.5334 19.6588 18.0537 19.2527 17.6176H19.2761L18.3622 18.686C18.2997 18.7732 18.2177 18.8387 18.1161 18.8823C18.0146 18.9259 17.9013 18.9477 17.7763 18.9477C17.667 18.9477 17.5576 18.9259 17.4483 18.8823C17.3389 18.8387 17.253 18.7732 17.1905 18.686L15.972 17.247C15.9251 17.1888 15.89 17.127 15.8665 17.0616C15.8431 16.9962 15.8314 16.9272 15.8314 16.8545C15.8314 16.7963 15.8353 16.7455 15.8431 16.7019C15.8509 16.6583 15.8704 16.6146 15.9017 16.571C15.8704 16.542 15.8509 16.502 15.8431 16.4511C15.8353 16.4002 15.8314 16.353 15.8314 16.3094C15.8314 16.1204 15.9017 15.9605 16.0423 15.8297C16.1829 15.6989 16.3547 15.6335 16.5578 15.6335C16.5734 15.6335 16.5851 15.6335 16.593 15.6335C16.6008 15.6335 16.6125 15.6407 16.6281 15.6553C15.9563 15.3209 15.2338 15.0629 14.4605 14.8812C13.6872 14.6995 12.8788 14.6087 12.0351 14.6087C12.0351 14.6087 12.0312 14.6087 12.0234 14.6087C12.0156 14.6087 12.0039 14.6087 11.9883 14.6087C10.8635 14.6087 9.80116 14.7613 8.80133 15.0666C7.8015 15.3864 6.90712 15.8188 6.1182 16.3639C5.32927 16.909 4.67704 17.5595 4.1615 18.3154C3.63035 19.0712 3.28666 19.8853 3.13043 20.7574C3.13043 20.7719 3.13043 20.7865 3.13043 20.801C3.13043 20.8156 3.13043 20.8374 3.13043 20.8664C3.13043 21.1572 3.23979 21.4043 3.4585 21.6078C3.67721 21.8113 3.94279 21.913 4.25524 21.913C4.27086 21.913 4.28258 21.913 4.29039 21.913C4.2982 21.913 4.30992 21.913 4.32554 21.913H19.6745C19.6901 21.913 19.7018 21.913 19.7096 21.913C19.7174 21.913 19.7291 21.913 19.7448 21.913C20.0572 21.913 20.3228 21.8113 20.5415 21.6078C20.7602 21.4043 20.8696 21.1572 20.8696 20.8664C20.8696 20.8374 20.8696 20.8156 20.8696 20.801C20.8696 20.7865 20.8696 20.7719 20.8696 20.7574Z'
                fill='#1B84FF'
              />
            </g>
          </svg>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{formatNumber(data?.count_qualifications)}</span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Квалификации</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        style={{flex: '1', minWidth: '165px'}}
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <svg
            width='24'
            height='23'
            viewBox='0 0 24 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.4'
              d='M3.98999 14.0491L2.47 12.5925C1.85 11.9983 1.85 11.0208 2.47 10.4267L3.98999 8.96998C4.24999 8.72082 4.46 8.23206 4.46 7.88706V5.82668C4.46 4.98335 5.18 4.29331 6.06 4.29331H8.21C8.57 4.29331 9.08 4.09209 9.34 3.84292L10.86 2.38624C11.48 1.79207 12.5 1.79207 13.12 2.38624L14.64 3.84292C14.9 4.09209 15.41 4.29331 15.77 4.29331H17.92C18.8 4.29331 19.52 4.98335 19.52 5.82668V7.88706C19.52 8.23206 19.73 8.72082 19.99 8.96998L21.51 10.4267C22.13 11.0208 22.13 11.9983 21.51 12.5925L19.99 14.0491C19.73 14.2983 19.52 14.7871 19.52 15.1321V17.1925C19.52 18.0358 18.8 18.7258 17.92 18.7258H15.77C15.41 18.7258 14.9 18.9271 14.64 19.1763L13.12 20.6329C12.5 21.2271 11.48 21.2271 10.86 20.6329L9.34 19.1763C9.08 18.9271 8.57 18.7258 8.21 18.7258H6.06C5.18 18.7258 4.46 18.0358 4.46 17.1925V15.1321C4.46 14.7775 4.24999 14.2887 3.98999 14.0491Z'
              fill='#1B84FF'
            />
            <path
              d='M15 15.3334C14.44 15.3334 13.99 14.9021 13.99 14.375C13.99 13.8479 14.44 13.4167 14.99 13.4167C15.54 13.4167 15.99 13.8479 15.99 14.375C15.99 14.9021 15.55 15.3334 15 15.3334Z'
              fill='#1B84FF'
            />
            <path
              d='M9.01001 9.58335C8.45001 9.58335 8 9.1521 8 8.62502C8 8.09794 8.45 7.66669 9 7.66669C9.55 7.66669 10 8.09794 10 8.62502C10 9.1521 9.56001 9.58335 9.01001 9.58335Z'
              fill='#1B84FF'
            />
            <path
              d='M9 15.0937C8.81 15.0937 8.62 15.0266 8.47 14.8829C8.18 14.605 8.18 14.1449 8.47 13.867L14.47 8.11701C14.76 7.83909 15.24 7.83909 15.53 8.11701C15.82 8.39492 15.82 8.85498 15.53 9.1329L9.53 14.8829C9.38 15.0266 9.19 15.0937 9 15.0937Z'
              fill='#1B84FF'
            />
          </svg>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{formatNumber2(data?.ltc)}%</span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>LTC</span>
        </div>
      </div>
    </div>
  );
};

export default AI;
