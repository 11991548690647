import React from 'react';
import {formatNumber2} from '../../../../functions/formatNumber';

const Conversions = ({conversions}) => {
  return (
    <div className='col-xxl-5 col-xl-5 mt-6 mt-xl-9'>
      <div className='card card-flush h-md-100'>
        <div className='card-header py-10'>
          <div className='m-0'>
            <div className='d-flex align-items-center mb-2'>
              <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>
                {formatNumber2(conversions?.ltc)}%
              </span>

              {conversions.ltc_indicator === 'down' ? (
                <span className='badge badge-light-danger fs-base'>
                  <i className='ki-duotone ki-arrow-up fs-5 text-danger '>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </span>
              ) : (
                <span className='badge badge-light-success fs-base'>
                  <i className='ki-duotone ki-arrow-up fs-5 text-success '>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </span>
              )}
            </div>

            <span className='fs-6 fw-semibold text-gray-500'>LTC</span>
          </div>
        </div>

        <div className='card-body pt-0'>
          <div className='mb-0'>
            <div className='d-flex flex-stack'>
              <div className='d-flex align-items-center me-5'>
                <div className='symbol symbol-30px me-5'>
                  <span className='symbol-label'>
                    <i className='ki-duotone ki-abstract-25 fs-3 text-gray-600'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </span>
                </div>
                <div className='me-5'>
                  <span className='text-gray-800 fw-bold text-hover-primary fs-6'>L2M</span>

                  <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                    {'Lead to Message'}
                  </span>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <span className='text-gray-800 fw-bold fs-6 me-3'>
                  {formatNumber2(conversions?.l2m)}%
                </span>

                <div className='d-flex flex-center'>
                  {conversions.l2m_indicator === 'down' ? (
                    <span className='badge badge-light-danger fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-danger '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  ) : (
                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-3'></div>

            <div className='d-flex flex-stack'>
              <div className='d-flex align-items-center me-5'>
                <div className='symbol symbol-30px me-5'>
                  <span className='symbol-label'>
                    <i className='ki-duotone ki-sms fs-3 text-gray-600'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </span>
                </div>

                <div className='me-5'>
                  <span className='text-gray-800 fw-bold text-hover-primary fs-6'>M2R</span>

                  <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                    {'Message to Registration'}
                  </span>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <span className='text-gray-800 fw-bold fs-6 me-3'>
                  {formatNumber2(conversions?.m2r)}%
                </span>

                <div className='d-flex flex-center'>
                  {conversions.m2r_indicator === 'down' ? (
                    <span className='badge badge-light-danger fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-danger '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  ) : (
                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-3'></div>

            <div className='d-flex flex-stack'>
              <div className='d-flex align-items-center me-5'>
                <div className='symbol symbol-30px me-5'>
                  <span className='symbol-label'>
                    <i className='ki-duotone ki-icon fs-3 text-gray-600'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </div>

                <div className='me-5'>
                  <span className='text-gray-800 fw-bold text-hover-primary fs-6'>R2Q</span>

                  <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                    {'Registration to Qualification'}
                  </span>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <span className='text-gray-800 fw-bold fs-6 me-3'>
                  {formatNumber2(conversions?.r2q)}%
                </span>

                <div className='d-flex flex-center'>
                  {conversions.r2q_indicator === 'down' ? (
                    <span className='badge badge-light-danger fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-danger '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  ) : (
                    <span className='badge badge-light-success fs-base'>
                      <i className='ki-duotone ki-arrow-up fs-5 text-success '>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversions;
