import React, {useState, useEffect} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import Board, {moveCard} from '@asseinfo/react-kanban';
import classNames from 'classnames';
// import '@asseinfo/react-kanban/dist/styles.css';

const Kanban = ({controlledBoard, setBoard, changeQ, changeActive, tab}) => {
  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
    changeQ(_card, source, destination);
  }

  return (
    <Board
      onCardDragEnd={handleCardMove}
      disableColumnDrag
      // disableCardDrag
      renderCard={(card) => (
        <div className={css.card} key={`${card.id}-${tab}-${card.title}`}>
          <div>
            {card.id !== 0 && (
              <svg
                width='10'
                height='16'
                viewBox='0 0 10 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M1.66667 3.2C2.58711 3.2 3.33333 2.48366 3.33333 1.6C3.33333 0.716341 2.58711 0 1.66667 0C0.746189 0 0 0.716341 0 1.6C0 2.48366 0.746189 3.2 1.66667 3.2ZM1.66667 9.6C2.58711 9.6 3.33333 8.88363 3.33333 8C3.33333 7.11637 2.58711 6.4 1.66667 6.4C0.746189 6.4 0 7.11637 0 8C0 8.88363 0.746189 9.6 1.66667 9.6ZM3.33333 14.4C3.33333 15.2836 2.58711 16 1.66667 16C0.746189 16 0 15.2836 0 14.4C0 13.5164 0.746189 12.8 1.66667 12.8C2.58711 12.8 3.33333 13.5164 3.33333 14.4ZM8.33333 3.2C9.25378 3.2 10 2.48366 10 1.6C10 0.716341 9.25378 0 8.33333 0C7.41289 0 6.66667 0.716341 6.66667 1.6C6.66667 2.48366 7.41289 3.2 8.33333 3.2ZM10 8C10 8.88363 9.25378 9.6 8.33333 9.6C7.41289 9.6 6.66667 8.88363 6.66667 8C6.66667 7.11637 7.41289 6.4 8.33333 6.4C9.25378 6.4 10 7.11637 10 8ZM8.33333 16C9.25378 16 10 15.2836 10 14.4C10 13.5164 9.25378 12.8 8.33333 12.8C7.41289 12.8 6.66667 13.5164 6.66667 14.4C6.66667 15.2836 7.41289 16 8.33333 16Z'
                  fill='#121923'
                />
              </svg>
            )}
            <span>{card.title}</span>
          </div>
          {card.id !== 0 && (
            <div
              className={classNames(css.add, !!card.show && css._remove)}
              onClick={() => changeActive(card.id, card.show)}
            />
          )}
        </div>
      )}
    >
      {controlledBoard}
    </Board>
  );
};

export default Kanban;
