/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import PageWrapper from '../../../modules/PageWrapper';
import {formatNumberToCash} from '../../../functions/formatNumber';

import ProfileHeader from '../../profile/ProfileHeader';
import ProfileHeaderStats from '../../profile/ProfileHeaderStats';
import Details from '../../profile/pages/Details';
import Settings from '../../profile/pages/Settings';
import Billing from '../../profile/pages/Billing';
import Security from '../../profile/pages/Security';

import UpdateUser from '../users/UpdateUser';

const User = observer(({isVerify, setPreloader, id}) => {
  const location = useLocation();
  const store = useStore();

  const [data, setData] = useState();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [result, getCountries, roles] =
      await Promise.all([
        store.getEmployeeData(id),
        store.getCountries(),
        store.usersGetAllAccess(),
      ]);

    const country = getCountries.find((el) => el.id === result.countries_id);
    const role = roles.roles.find((el) => el.id === result.role_id);

    setCountries(getCountries);
    setData({...result, geo: country?.name, role_name: role?.name});
    setPreloader(false);
  };

  const links = () => {
    if (store.roles.includes(13) || store.roles.includes(32)) {
      return [
        {link: `/users/list/user/${id}/info`, title: 'Данные'},
        {link: `/users/list/user/${id}/billing`, title: 'Платежная информация'},
        {link: `/users/list/user/${id}/security`, title: 'Безопасность'},
      ];
    }

    if(store.roles.includes(11)){
      return [
        {link: `/users/list/user/${id}/info`, title: 'Данные'},
        {link: `/users/list/user/${id}/settings`, title: 'Настройки'},
      ];
    }

    return [
      {link: `/users/list/user/${id}/info`, title: 'Данные'},
      {link: `/users/list/user/${id}/settings`, title: 'Настройки'},
      {link: `/users/list/user/${id}/billing`, title: 'Платежная информация'},
      {link: `/users/list/user/${id}/security`, title: 'Безопасность'},
    ];
  };

  return (
    <>
      <ProfileHeader
        isSettings
        getData={getData}
        data={data}
        navigation={links()}
      >
        <ProfileHeaderStats
          title={'Выплачено'}
          StatsUpDown={false}
          stats={formatNumberToCash(data?.account_payments)}
        />
        <ProfileHeaderStats
          title={'Текущий баланс'}
          StatsUpDown={true}
          stats={formatNumberToCash(data?.balance)}
        />
      </ProfileHeader>
      {location.pathname === `/users/list/user/${id}/info` && <Details data={data} countries={countries} />}
      {location.pathname === `/users/list/user/${id}/settings` &&
        (!store.roles.includes(13) || !store.roles.includes(32)) && (
          <>
            <UpdateUser updateData={data} newCountries={countries} getNewData={getData} />
            <Settings
              isUser={id}
              getSettings={store.usersGetSettings}
              // updateSettings={store.updateSettings}
            />
          </>
        )}
      {location.pathname === `/users/list/user/${id}/billing` && (
        <Billing
          isUser={id}
          getWallets={store.usersGetWallets}
          getTransactions={store.usersGetTransactions}
          // deleteWallet={store.deleteWallet}
        />
      )}
      {location.pathname === `/users/list/user/${id}/security` && (
        <Security
          isUser={id}
          getAuthData={store.usersGetAuthData}
          // revokeToken={store.revokeToken}
        />
      )}
    </>
  );
});

const UserWrapper = () => {
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});
  const location = useLocation();
  const params = useParams();
  const store = useStore();

  useEffect(() => {
    store.setToolbarType('');

    switch (location.pathname) {
      case `/users/list/user/${params?.id}`: {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Сотрудники', path: '/users/list'},
            {isSeparator: true},
            {title: 'Сотрудник', path: `/users/list/user/${params?.id}`, isActive: true},
          ],
          title: 'Профиль сотрудника',
        });
        break;
      }
      case `/users/list/user/${params?.id}/info`: {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Сотрудники', path: '/users/list'},
            {isSeparator: true},
            {title: 'Сотрудник', path: `/users/list/user/${params?.id}/info`, isActive: true},
          ],
          title: 'Профиль сотрудника',
        });
        break;
      }
      case `/users/list/user/${params?.id}/settings`: {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Сотрудники', path: '/users/list'},
            {isSeparator: true},
            {title: 'Сотрудник', path: `/users/list/user/${params?.id}/settings`, isActive: true},
          ],
          title: 'Профиль сотрудника',
        });
        break;
      }
      case `/users/list/user/${params?.id}/billing`: {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Сотрудники', path: '/users/list'},
            {isSeparator: true},
            {title: 'Сотрудник', path: `/users/list/user/${params?.id}/billing`, isActive: true},
          ],
          title: 'Профиль сотрудника',
        });
        break;
      }
      case `/users/list/user/${params?.id}/security`: {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: '/dashboard'},
            {isSeparator: true},
            {title: 'Сотрудники', path: '/users/list'},
            {isSeparator: true},
            {title: 'Сотрудник', path: `/users/user/${params?.id}/security`, isActive: true},
          ],
          title: 'Профиль сотрудника',
        });
        break;
      }
    }
  }, [location]);

  return (
    <PageWrapper breadcrumbs={breadcrumbs.br} title={breadcrumbs.title}>
      <User id={params?.id} />
    </PageWrapper>
  );
};

export default observer(UserWrapper);
