import {api} from './api';

const channelsAPI = {
  async channelsGetChannels(token) {
    try {
      const response = await api.get(`Channels/getChannels?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async channelsGetChannelsNew(token, filters) {
    try {
      const response = await api.get(`Channels/getChannels?token=${token}&${filters}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async channelsGetChannelsList(token, type = '', bot_id = '') {
    try {
      const response = await api.get(
        `Channels/getChannelsList?token=${token}&config=${type}&bot_id=${bot_id}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async channelsGetChannel(token, id) {
    try {
      const response = await api.get(`Channels/getChannel?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async channelsAddChannel(data) {
    try {
      const response = await api.post(`Channels/addChannel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async channelsUpdateChannel(data) {
    try {
      const response = await api.post(`Channels/updateChannel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default channelsAPI;
