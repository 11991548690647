/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {KTIcon, toAbsoluteUrl} from '../../../helpers';
import {useLayout} from '../../core';
import {Header} from './Header';
import {Navbar} from './Navbar';
import {useThemeMode} from '../../../partials';

export function HeaderWrapper() {
  const {config, classes} = useLayout();
  const {mode} = useThemeMode();

  const [theme, setTheme] = useState('');
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  useEffect(() => {
    if (mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    } else {
      setTheme(mode);
    }
  }, [mode]);

  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div
      id='kt_app_header'
      className='app-header'
      style={
        theme === 'light' && scroll
          ? {backgroundColor: 'rgba(255, 255, 255, 0.9)', boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, .05)', backdropFilter: "blur(5px)"}
          : {}
      }
    >
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1 w-100',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
        style={{position: 'relative'}}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTIcon iconName='abstract-14' className=' fs-1' />
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <div className='d-lg-none'>
                  {mode === 'dark' ? (
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                      className='h-30px'
                    />
                  ) : (
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                      className='h-30px'
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            {/* <Link to=''> */}
            {config.layoutType !== 'dark-header' ? (
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/default.svg')}
                className='h-30px h-lg-30px app-sidebar-logo-default'
                style={{position: 'absolute', left: 'calc(50% - 70px)', top: 'calc(50% - 15px)'}}
              />
            ) : (
              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                  className='h-30px h-lg-30px app-sidebar-logo-default theme-light-show'
                  style={{
                    position: 'absolute',
                    left: 'calc(50% - 70px)',
                    top: 'calc(50% - 10px)',
                  }}
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-small-dark.svg')}
                  className='h-30px h-lg-30px app-sidebar-logo-default theme-dark-show'
                />
              </>
            )}
            {/* </Link> */}
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  );
}
