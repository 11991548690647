/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import {toast} from 'react-toastify';
import {formateImage} from '../../../functions/formatImage';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import Warning from '../../../modules/Warning';

import {AdvertisersContext} from './Advertisers';

const AddAdvertiser = ({show, warning, setWarning, isUpdate = false, id}) => {
  const store = useStore();
  const {getData} = useContext(AdvertisersContext);
  const {
    register,
    setValue,
    watch,
    formState: {errors},
    handleSubmit,
  } = useForm();

  const [image, setImage] = useState('');

  useEffect(() => {
    isUpdate && getDataForUpdating();
    !isUpdate && formateImage('', setImage);
  }, []);

  const getDataForUpdating = async () => {
    const result = await store.advertisersGetAdvertiser(id);
    setValue('name', result?.name);
    formateImage(result?.avatar, setImage);
  };

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'avatar' && type === 'change') {
        formateImage(value[name][0], setImage);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === 'avatar') {
        if (!!value.length) {
          formData.append(`${key}`, value[0]);
          continue;
        }
        continue;
      }
      formData.append(`${key}`, value);
    }

    if (isUpdate) {
      const result = await store.advertisersUpdateAdvertiser(formData, id);
      !!result &&
        toast.success(`Успешно обновлено!`, {
          onOpen: async () => {
            await getData();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка в обновлении!`, {
          theme: 'colored',
        });
    } else {
      const result = await store.advertisersAddAdvertiser(formData);
      !!result &&
        toast.success(`Успешно сохранено!`, {
          onOpen: async () => {
            await getData();
            show(false);
          },
        });
      !result &&
        toast.error(`Произошла ошибка сохранения!`, {
          theme: 'colored',
        });
    }
  };

  return (
    <>
      <form
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='fv-row mb-10 d-flex flex-column flex-start'>
          <label className='form-label'>Аватар</label>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              className='image-input-wrapper w-125px h-125px'
              style={{
                backgroundImage: image,
              }}
            ></div>

            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              aria-label='Change avatar'
              data-bs-original-title='Change avatar'
              data-kt-initialized='1'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' {...register('avatar')} />
              <input type='hidden' name='avatar_remove' />
            </label>
          </div>
        </div>
        <div className='fv-row mb-7 fv-plugins-icon-container'>
          <label className='required fw-semibold fs-6 mb-2'>Название рекламодателя</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            {...register('name', {required: true})}
          />

          {errors.name?.type === 'required' && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
        <div className='d-flex flex-end pt-10'>
          <input
            type='submit'
            className={`btn fw-bold  btn-primary cursor-pointer `}
            value={isUpdate ? 'Сохранить изменения' : 'Сохранить'}
          />
        </div>

        <CreateAppModal
          customWidth='mw-400px'
          padding='pb-lg-10 pt-lg-10 px-lg-10'
          show={warning}
          handleClose={() => setWarning(false)}
          showHeader={false}
          isMessage={true}
        >
          <Warning
            buttons={[
              {
                action: () => {
                  setWarning(false);
                },
                title: <i className='bi bi-x p-0 fs-1'></i>,
                class: 'btn-light',
              },
              {
                action: () => {
                  setWarning(false);
                  show(false);
                },
                title: <i className='bi bi-check p-0 fs-1'></i>,
                class: 'btn-light-primary',
              },
            ]}
          />
        </CreateAppModal>
      </form>
    </>
  );
};

export default observer(AddAdvertiser);
