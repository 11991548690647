/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../_metronic/partials';
import css from '../../../../styles/CustomPicker.module.scss';
import classNames from 'classnames';
import PageWrapper from '../../../modules/PageWrapper';
import {Button} from '../../../shared/Buttons';
import {Select} from '../../../shared/Inputs';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import {formatNumber, formatNumber2, formatNumberToCash} from '../../../functions/formatNumber';

const AnalysisSoft = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'Анализ Soft RD', path: '/sales/rd/soft', isActive: true},
      ]}
      title={'Анализ Soft RD'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = ({isVerify, setPreloader}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [qualificationsStart, setQualificationsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [qualificationsEnd, setQualificationsEnd] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsStart, setDepositsStart] = useState(new Date().setUTCHours(0, 0, 0));
  const [depositsEnd, setDepositsEnd] = useState(new Date().setUTCHours(0, 0, 0));

  const [offersOptions, setOffersOptions] = useState([]);
  const [offer, setOffer] = useState('');

  const [teamsOptions, setTeamsOptions] = useState([]);
  const [team, setTeam] = useState('');

  const [managersOptions, setManagersOptions] = useState([]);
  const [manager, setManager] = useState('');

  const [stats, setStats] = useState(null);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const advertisersList = await store.getAdvertisersList();
    const teamsList = await store.salesTeamsGetList('rd');
    const managersList = await store.getRdManagersList();

    setOffersOptions(
      advertisersList.map((item) => {
        return <option value={item.id}>{item?.name}</option>;
      })
    );

    setTeamsOptions(
      teamsList.map((item) => {
        return (
          <option value={item.id}>
            {item?.teamleader?.first_name} {item?.teamleader?.last_name}
          </option>
        );
      })
    );

    setManagersOptions(
      managersList.map((item) => {
        return (
          <option value={item.id}>
            {item?.first_name} {item?.last_name}
          </option>
        );
      })
    );

    setPreloader(false);
  };

  const search = async () => {
    if (depositsStart < qualificationsStart) {
      return toast.error(
        'Начальная дата депозитов не может быть меньше, начальной даты квалификаций!',
        {theme: 'colored'}
      );
    }
    if (qualificationsStart > depositsEnd) {
      return toast.error(
        'Начальная дата квалификаций не может быть больше, конечной даты депозитов!',
        {theme: 'colored'}
      );
    }

    const res = await store.salesRdAnalysisGetMainStatsSoft({
      qualificationsStart: Math.floor(new Date(qualificationsStart).setUTCHours(0, 0, 0) / 1000),
      qualificationsEnd: Math.floor(new Date(qualificationsEnd).setUTCHours(23, 59, 59) / 1000),
      depositsStart: Math.floor(new Date(depositsStart).setUTCHours(0, 0, 0) / 1000),
      depositsEnd: Math.floor(new Date(depositsEnd).setUTCHours(23, 59, 59) / 1000),
      advertiser: offer,
      team,
      manager,
    });

    setStats(res);
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const CustomInput = forwardRef(({value, onClick}, ref) => (
    <div
      className='form-control form-control-solid mb-lg-0 w-100 h-100'
      onClick={onClick}
      ref={ref}
      value={value}
    >
      {value}
    </div>
  ));

  return (
    <>
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header border-0 py-5 pb-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Анализ Soft RD</span>
            <span className='text-muted fw-semibold fs-7'>
              Анализируйте средний чек о периодам кагорта
            </span>
          </h3>
        </div>

        <div className='card-body d-flex flex-column'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className={`col-lg-6 d-flex flex-column`}>
                <span className='fw-bold fs-5 mb-1'>Активации</span>
                <div className={`col-lg-12 d-flex`}>
                  <div className={`col-6 fv-row mb-7 px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsStart}
                        onChange={(date) => setQualificationsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='col-6 fv-row mb-7 px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={qualificationsEnd}
                        onChange={(date) => setQualificationsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className={`col-lg-6 d-flex flex-column`}>
                <span className='fw-bold fs-5 mb-1'>Депозиты</span>
                <div className={`col-lg-12 d-flex`}>
                  <div className={`col-6 fv-row mb-7 px-2`}>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>От</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsStart}
                        onChange={(date) => setDepositsStart(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                  <div className='col-6 fv-row mb-7 px-2'>
                    <label
                      htmlFor='kt_td_picker_linked_2_input'
                      className='form-label d-flex align-items-center'
                    >
                      <span className='me-2'>До</span>
                      <DatePicker
                        calendarClassName={classNames(
                          css.container,
                          css[`_${mode}`],
                          mode === 'dark' ? css.dark : css.light
                        )}
                        dateFormat='dd/MM/yyyy'
                        selected={depositsEnd}
                        onChange={(date) => setDepositsEnd(date)}
                        customInput={<CustomInput />}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className={`col-lg-3 fv-row mb-7`}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '42px',
                    }),
                  }}
                  text={'Рекламодатель'}
                  classText={'analysis-select-1'}
                  name={'type'}
                  value={offer}
                  handleChange={(e) => setOffer(e.target.value)}
                  options={[<option value=''>Не выбрано</option>, ...offersOptions]}
                />
              </div>

              <div className='col-lg-4 fv-row mb-7'>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '42px',
                    }),
                  }}
                  text={'Команда RD'}
                  classText={'analysis-select-2'}
                  name={'type'}
                  value={team}
                  handleChange={(e) => setTeam(e.target.value)}
                  options={[<option value=''>Не выбрано</option>, ...teamsOptions]}
                />
              </div>

              <div className={`col-lg-3 fv-row mb-7`}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '42px',
                    }),
                  }}
                  text={'Менеджер RD'}
                  classText={'analysis-select-3'}
                  name={'type'}
                  value={manager}
                  handleChange={(e) => setManager(e.target.value)}
                  options={[<option value=''>Не выбрано</option>, ...managersOptions]}
                />
              </div>
              <div className='col-lg-2 fv-row'>
                <Button
                  className='w-100 d-flex flex-nowrap align-items-center justify-content-center'
                  action={() => {
                    search();
                  }}
                >
                  <i className='bi bi-search'></i>
                  Поиск
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!stats && (
        <div className='card card-flush mt-6 mt-xl-9'>
          <div className='card-header mt-5'>
            <div className='card-title flex-column'>
              <h3 className='fw-bold mb-1'>Общая статистика</h3>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='row p-0 mb-5'>
              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-info d-block'>Активации</span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumber(stats?.count_activations)}
                  </span>
                </div>
              </div>
              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-warning d-block'>Сигналы</span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumber(stats?.count_activations_signals)}
                  </span>
                </div>
              </div>

              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-success d-block text-nowrap'>Лиды</span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumber(stats?.count_leads)}
                  </span>
                </div>
              </div>

              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-primary d-block text-nowrap'>
                    Кол-во депозитов
                  </span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumber(stats?.count_deposits)}
                  </span>
                </div>
              </div>
              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-info-emphasis d-block text-nowrap'>
                    Сумма депозитов
                  </span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumberToCash(stats?.amounts_deposits)}
                  </span>
                </div>
              </div>
              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-warning d-block text-nowrap'>
                    Средний чек
                  </span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumberToCash(stats?.average_bill)}
                  </span>
                </div>
              </div>
              <div className='col'>
                <div className='border border-dashed border-gray-300 text-center min-w-125px rounded px-4 pt-4 pb-2 my-3'>
                  <span className='fs-4 fw-semibold text-danger d-block text-nowrap'>
                    Активность
                  </span>
                  <span className='fs-2hx fw-bold text-gray-900 counted'>
                    {formatNumber2(stats?.activity)}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(AnalysisSoft);
