import {api} from './api';

const trafficAPI = {
  async trafficsInfluenceLinksDeleteLink(data) {
    try {
      const response = await api.post(`Traffics/Influence/Links/deleteLink`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetTrafficsList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'selectedBayer') {
        if (!value) continue;
        queryStr += `&bayer_id=${value}`;
        continue;
      }
      if (key === 'queryText') {
        if (!value) continue;
        queryStr += `&q=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (data.timeConfig.time_start !== '0') {
          if (!value) continue;

          if (data.timeConfig.time_config) {
            queryStr += `&time_config=${data.timeConfig.time_config}`;
          }

          if (data.timeConfig.time_start) {
            queryStr += `&time_start=${data.timeConfig.time_start}`;
          }
          if (data.timeConfig.time_end) {
            queryStr += `&time_end=${data.timeConfig.time_end}`;
          }
        }
        continue;
      }
      if (key === 'page') {
        if (!value) continue;
        queryStr += `&page=${value}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Influence/getTrafficsList${queryStr}&per_page=6`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetTrafficsListForExel(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'selectedBayer') {
        if (!value) continue;
        queryStr += `&bayer_id=${value}`;
        continue;
      }
      if (key === 'queryText') {
        if (!value) continue;
        queryStr += `&q=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (data.timeConfig.time_start !== '0') {
          if (!value) continue;

          if (data.timeConfig.time_config) {
            queryStr += `&time_config=${data.timeConfig.time_config}`;
          }

          if (data.timeConfig.time_start) {
            queryStr += `&time_start=${data.timeConfig.time_start}`;
          }
          if (data.timeConfig.time_end) {
            queryStr += `&time_end=${data.timeConfig.time_end}`;
          }
        }
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Influence/getTrafficsListUpload${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceBayerGetTrafficsList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'q') {
        if (!value) continue;
        queryStr += `&q=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (data.timeConfig.time_start !== '0') {
          if (!value) continue;

          if (data.timeConfig.time_config) {
            queryStr += `&time_config=${data.timeConfig.time_config}`;
          }

          if (data.timeConfig.time_start) {
            queryStr += `&time_start=${data.timeConfig.time_start}`;
          }
          if (data.timeConfig.time_end) {
            queryStr += `&time_end=${data.timeConfig.time_end}`;
          }
        }
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Influence/Bayer/getTrafficsList${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceBayerGetTrafficsListForExel(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'q') {
        if (!value) continue;
        queryStr += `&q=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (data.timeConfig.time_start !== '0') {
          if (!value) continue;

          if (data.timeConfig.time_config) {
            queryStr += `&time_config=${data.timeConfig.time_config}`;
          }

          if (data.timeConfig.time_start) {
            queryStr += `&time_start=${data.timeConfig.time_start}`;
          }
          if (data.timeConfig.time_end) {
            queryStr += `&time_end=${data.timeConfig.time_end}`;
          }
        }
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Influence/Bayer/getTrafficsListExport${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsGetInfo(token, id) {
    try {
      const response = await api.get(`Traffics/getInfo?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async trafficsFacebookGetTrafficsList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'selectedBayer') {
        if (!value) continue;
        queryStr += `&bayer_id=${value}`;
        continue;
      }
      if (key === 'queryText') {
        if (!value) continue;
        queryStr += `&q=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (data.timeConfig.time_start !== '0') {
          if (!value) continue;

          if (data.timeConfig.time_config) {
            queryStr += `&time_config=${data.timeConfig.time_config}`;
          }

          if (data.timeConfig.time_start) {
            queryStr += `&time_start=${data.timeConfig.time_start}`;
          }
          if (data.timeConfig.time_end) {
            queryStr += `&time_end=${data.timeConfig.time_end}`;
          }
        }
        continue;
      }
      if (key === 'page') {
        if (!value) continue;
        queryStr += `&page=${value}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/getTrafficsListNew${queryStr}&per_page=6`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsAddTraffic(data) {
    try {
      const response = await api.post(`Traffics/addTraffic`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsLinksUpdateStatus(data) {
    try {
      const response = await api.post(`Traffics/Influence/Links/updateStatus`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsUpdateTraffic(data) {
    try {
      const response = await api.post(`Traffics/updateTraffic`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsUpdateSource(data) {
    try {
      const response = await api.post(`Traffics/Facebook/Links/updateSource`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluencelinksUpdateLink(data) {
    try {
      const response = await api.post(`Traffics/Influence/Links/updateLink`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksGetStatuses(token, id) {
    try {
      const response = await api.get(`Traffics/Influence/Links/getStatuses?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetLinks(token, id) {
    try {
      const response = await api.get(`Traffics/Influence/getLinks?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetLinks(params) {
    let queryStr = '';

    for (let [key, value] of Object.entries(params)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      if (!!value) {
        queryStr += `&${key}=${value}`;
      }
    }

    try {
      const response = await api.get(`Traffics/Facebook/getLinks${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetTrafficStats(token, id, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Influence/getTrafficStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetAnalysisRd(
    token,
    id,
    timeConfig,
    time_end_deposits,
    time_start_deposits
  ) {
    try {
      const response = await api.get(
        `Traffics/Influence/getAnalysisRd?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}&time_start_deposits=${time_start_deposits}&time_end_deposits=${time_end_deposits}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetAnalysisRd(
    token,
    id,
    timeConfig,
    time_end_deposits,
    time_start_deposits
  ) {
    try {
      const response = await api.get(
        `Traffics/Facebook/getAnalysisRd?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}&time_start_deposits=${time_start_deposits}&time_end_deposits=${time_end_deposits}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetLinksAnalysisRd(
    token,
    id,
    timeConfig,
    time_end_deposits,
    time_start_deposits
  ) {
    try {
      const response = await api.get(
        `Traffics/Facebook/Links/getAnalysisRd?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}&time_start_deposits=${time_start_deposits}&time_end_deposits=${time_end_deposits}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksGetAnalysisRd(
    token,
    id,
    timeConfig,
    time_end_deposits,
    time_start_deposits
  ) {
    try {
      const response = await api.get(
        `Traffics/Influence/Links/getAnalysisRd?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}&time_start_deposits=${time_start_deposits}&time_end_deposits=${time_end_deposits}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async getTimeConfig() {
    try {
      const response = await api.get(`getTimeConfig`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetTrafficStats(token, id, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Facebook/getTrafficStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetTrafficConversions(token, id, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Facebook/getTrafficConversions?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookLinksGetConversions(token, id, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Facebook/Links/getConversions?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetBayersList(token) {
    try {
      const response = await api.get(`Traffics/Influence/getBayersList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetBayersList(token) {
    try {
      const response = await api.get(`Traffics/Facebook/getBayersList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsBayerGetInfo(token, id) {
    try {
      const response = await api.get(`Traffics/Bayer/getInfo?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookGetBayersStats(token, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Facebook/getBayersStats?token=${token}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookBayerMainStats(token, timeConfig, id) {
    try {
      const response = await api.get(
        `Traffics/Facebook/Bayer/mainStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceGetBayersStats(token, timeConfig) {
    try {
      const response = await api.get(
        `Traffics/Influence/getBayersStats?token=${token}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookLinksGetMainStats(token, timeConfig, id) {
    try {
      const response = await api.get(
        `Traffics/Facebook/Links/getMainStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksGetMainStats(token, timeConfig, id) {
    try {
      const response = await api.get(
        `Traffics/Influence/Links/getMainStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}&time_start=${timeConfig.time_start}&time_end=${timeConfig.time_end}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksGetInfo(token, id) {
    try {
      const response = await api.get(`Traffics/Influence/Links/getInfo?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookLinksGetInfo(token, id) {
    try {
      const response = await api.get(`Traffics/Facebook/Links/getInfo?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookLinksTrackerGetCompanies(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Links/Tracker/getCompanies${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async trafficsFacebookLinksTrackerGetAdsets(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Links/Tracker/getAdsets${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async trafficsFacebookLinksTrackerGetAds(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Links/Tracker/getPromotions${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksAddLink(data) {
    try {
      const response = await api.post(`Traffics/Influence/Links/addLink`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceBayerMainStats(token, timeConfig, id) {
    try {
      const response = await api.get(
        `Traffics/Influence/Bayer/mainStats?token=${token}&id=${id}&time_config=${timeConfig.time_config}`
      );

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsGetLogs(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }
      if (key === 'is_traffic' && value === 2) {
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/getLogs${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsGetActives(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/getActives${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookBayerInfo(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Bayer/info${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookBayerGetTrafficList(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Bayer/getTrafficsList${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookBayerGetAppSchedule(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Bayer/getAppsSchedule${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsFacebookBayerGetScheme(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }
    try {
      const response = await api.get(`Traffics/Facebook/Bayer/getSchemeSchedule${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksChannelsAddChannel(data) {
    try {
      const response = await api.post(`Traffics/Influence/LinksChannels/addChannel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async trafficsInfluenceLinksChannelsDeleteChannel(data) {
    try {
      const response = await api.post(`Traffics/Influence/LinksChannels/deleteChannel`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async trafficsInfluenceLinksChannelsGetChannels(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Traffics/Influence/LinksChannels/getChannels${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default trafficAPI;
