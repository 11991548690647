/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {CreateAppModal} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Warning from '../../../modules/Warning';
import {Selector} from '../../../shared/Selectors';

const UpdatePixel = ({setWarning, warning, setSave, save, show, getPixels, updateData}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm();

  const [selectOptions, setSelectOptions] = useState([
    {value: true, label: 'Активный'},
    {value: false, label: 'Не активный'},
  ]);

  useEffect(() => {
    for (const [key, value] of Object.entries(updateData)) {
      setValue(key, value);
    }
  }, []);

  const onSubmit = async (data) => {
    const result = await store.updatePixel(data);
    notify(!!result, !!result ? 'Успешно обновлено!' : 'Произошла ошибка!');

    setSave(true);
    show(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      getPixels();
      toast.success(`${message}`, {});
    }
  };

  return (
    <form
      className='form fv-plugins-bootstrap5 fv-plugins-framework'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Pixel ID</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('pixel_id', {required: true})}
        />

        {errors?.pixel_id?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Маркер доступа</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('pixel_token', {required: true})}
        />

        {errors?.pixel_token?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Название</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          {...register('name', {required: true})}
        />

        {errors?.name?.type === 'required' && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Статаус</label>
        <Controller
          name='status'
          control={control}
          rules={{required: true}}
          render={({field: {onChange, value, name, ref}}) => (
            <Selector options={selectOptions} value={value} onChange={onChange} />
          )}
        />
      </div>

      <div className='d-flex flex-end pt-10'>
        <input
          type='submit'
          className={`btn fw-bold  btn-primary cursor-pointer `}
          value={'Сохранить'}
        />
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

export default observer(UpdatePixel);
