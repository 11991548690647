/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PageWrapper from '../../../modules/PageWrapper';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {Chart6} from '../../../widgets/Chart6';

const AnalysisLtc = () => {
  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'Анализ LTC', path: '/sales/ltc/analysis', isActive: true},
      ]}
      title={'Анализ LTC'}
    >
      <Component />
    </PageWrapper>
  );
};

const Component = observer(({isVerify, timeConfig, setPreloader}) => {
  const store = useStore();

  const [data, setData] = useState({
    time: [],
    appeals: [],
    qualifications: [],
    registrations: [],
  });

  useEffect(() => {
    isVerify && getData();
  }, [isVerify, timeConfig]);

  const getData = async () => {
    const getLeadActivity = await store.salesLtcGetLeadActivity(timeConfig);

    const time = [];
    const appeals = [];
    const qualifications = [];
    const registrations = [];

    getLeadActivity.forEach((el) => {
      time.push(el.hour);
      appeals.push(+el.avg_appeals);
      qualifications.push(+el.avg_qualifications);
      registrations.push(+el.avg_registrations);
    });

    setData({time, appeals, qualifications, registrations});
    setPreloader(false);
  };

  return (
    <>
      <Chart6
        title={'Активность лидов'}
        subTitle={'Изучите когда лиды найболее активные'}
        data={data || {}}
      />
    </>
  );
});

export default AnalysisLtc;
