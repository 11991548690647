import {api} from './api';

const financeAPI = {
  async financeCategoryGetLtcList(token) {
    try {
      const response = await api.get(`Finance/Category/getLtcList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeCategoryGetRdList(token) {
    try {
      const response = await api.get(`Finance/Category/getRdList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeCategoryGetRomiList(token) {
    try {
      const response = await api.get(`Finance/Category/getRomiList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeAccountsGetAccountsList(token) {
    try {
      const response = await api.get(`Finance/Accounts/getAccountsList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeAccountsAdd(formData) {
    try {
      const response = await api.post(`/Finance/Accounts/add`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeAccountsGetAccount(token, id) {
    try {
      const response = await api.get(`/Finance/Accounts/getAccount?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async financeAccountsUpdate(formData) {
    try {
      const response = await api.post(`/Finance/Accounts/update`, formData);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default financeAPI;
