/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import moment from 'moment';
import CardLTC from './Card';
import PageWrapper from '../../../../modules/PageWrapper';
import {findKPILtc} from '../../../../functions/findKPI';
import ButtonExel from '../../../../modules/ButtonExel';

const ManagersListLTCWrapper = () => {
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [time, setTime] = useState({});

  return (
    <PageWrapper
      firstTimeConfig='week_salles'
      buttonKPI={
        roles.includes(4) ||
        roles.includes(8) ||
        roles.includes(30) ||
        roles.includes(13) ||
        roles.includes(32) ? (
          <ButtonExel
            data={data}
            fieldsToKeep={[
              'id',
              'first_name',
              'last_name',
              'count_appeal',
              'count_qualifications',
              'count_registrations',
              'ltc',
              'is_ban',
              'ban_date',
              'is_verified',
              'verify_date',
              'created_at',
            ]}
            fileName={'lts_managers'}
            time={time}
          />
        ) : (
          <></>
        )
      }
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Отдел продаж', path: '/dashboard/sales'},
        {isSeparator: true},
        {title: 'Менеджера LTC', path: '/sales/ltc/managers', isActive: true},
      ]}
      title={'Менеджера LTC'}
    >
      <ManagersListLTC setObjForExel={setData} setRoles={setRoles} setTimeForExel={setTime} />
    </PageWrapper>
  );
};

const ManagersListLTC = observer(
  ({timeConfig, isVerify, setPreloader, setObjForExel, setTimeForExel, setRoles}) => {
    const store = useStore();
    const [data, setData] = useState([]);

    useEffect(() => {
      setObjForExel(null);
      setRoles(store.roles);
      isVerify && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const tc = timeConfig;
      const [getManagersStats, LTCList] = await Promise.all([
        store.salesLtcGetManagersStats(tc),
        store.salesGetKpiLtcList(),
      ]);

      const managersStats = getManagersStats.data.managers.map((item) => {
        return {...item, indicator: findKPILtc(LTCList, item?.count_appeal, item?.ltc)};
      });

      const dataForExel = managersStats.map((item) => {
        return {
          ...item,
          ...item.manager,
          ban_date: !!item?.manager?.ban_date ? item?.manager?.ban_date : '',
          verify_date: !!item?.manager?.verify_date ? item?.manager?.verify_date : '',
          is_verified: `${item?.manager?.is_verified}`,
          is_ban: `${item?.manager?.is_ban}`,
          created_at: moment(item?.manager?.created_at * 1000).format('DD.MM.YYYY HH:mm '),
        };
      });
      setTimeForExel(getManagersStats.time);
      setObjForExel(dataForExel);

      setData(managersStats);
      setPreloader(false);
    };

    return (
      <>
        <div className='row g-6 g-xl-9'>
          {data.map((el) => {
            return <CardLTC data={el} key={el.manager.id} />;
          })}
        </div>
      </>
    );
  }
);

export default ManagersListLTCWrapper;
